import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Card,
  CardContent,
  TextField,
  Divider,
  IconButton,
  Button,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import Sidebar from "../Includes/Sidebar";

export default function ChangePassword() {
  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [OldPasswordError, setOldPasswordError] = useState("");

  const [loading, setLoading] = useState(false);

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
    setOldPasswordError("");
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    setNewPasswordError("");
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    setNewPasswordError("");
  };
  const openSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  //   const handleSubmit = () => {
  //     // Implement your logic for handling password change
  //     console.log('Old Password:', oldPassword);
  //     console.log('New Password:', newPassword);
  //     console.log('Confirm Password:', confirmPassword);
  //   };
  const handleSubmit = async () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    }
    if (!oldPassword && !newPassword && !confirmPassword) {
      setOldPasswordError("Old Password is required.");
      setNewPasswordError("New Password is required.");
      setConfirmPasswordError("confirm Password is required.");
      return;
    }
    if (
      !oldPassword ||
      !newPassword ||
      !confirmPassword ||
      newPassword !== confirmPassword
    ) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    let responsedata = {
      user_id: user_id,
      org_id: userorg_id,
      old_password: oldPassword,
      new_password: newPassword,
    };
    console.log(responsedata);
    const headers = {
      token: userToken,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        `${window.constants.api_url}/web/changepassword`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(responsedata),
        }
      );

      const res = await response.json();
      // console.log(res)
      if (res.code === "200") {
        console.log(res.data);
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
        setNewPasswordError("");
        setConfirmPasswordError("");
        openSnackbar(res.message);
        setLoading(false);
        // setUserdetails(res.data)
      } else {
        // Handle the error
        console.error("Error:");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };
  return (
    <Box sx={{ display: "flex", backgroundColor: "#EFEDF1" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3, minHeight: "100vh" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "150px",
            justifyContent: "center",
          }}
        >
          {/* Password Change Card */}
          <Card sx={{ width: "800px", height: "auto" }}>
            <CardContent
              style={{
                padding: "16px",
                display: "flex",
                alignItems: "flex-start",
                justifyContent: "space-betwwen",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginRight: "16px",
                  width: "550px",
                }}
              >
                <Typography> CHANGE PASSWORD</Typography>
                <TextField
                  label="Old Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  error={Boolean(OldPasswordError)}
                  helperText={OldPasswordError}
                />
                <TextField
                  label="New Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  error={Boolean(newPasswordError)}
                  helperText={newPasswordError}
                />
                <TextField
                  label="Confirm Password"
                  type="password"
                  fullWidth
                  margin="normal"
                  variant="outlined"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  error={Boolean(confirmPasswordError)}
                  helperText={confirmPasswordError}
                />
                <Button
                  variant="contained"
                  disabled={loading}
                  onClick={handleSubmit}
                  fullWidth
                  sx={{
                    fontWeight: "600",
                    textTransform: "none",
                    marginTop: "20px",
                  }}
                >
                  {loading ? <CircularProgress size={24} /> : "Update"}
                </Button>
              </Box>
              <Divider
                orientation="vertical"
                sx={{ height: "100%", mx: "16px" }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "90px",
                }}
              >
                <img
                  src={window.constants.asset_path + "/wrong-password.png"}
                  alt=""
                  srcset=""
                />

                <Typography variant="caption">
                  Strong password required. Enter 8-256 characters. Do not
                  include common words or names. Combine uppercase letters,
                  lowercase letters, numbers, and symbols.
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </div>
      </Box>
      <Snackbar
        mode="outlined"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center", // Center bottom
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={[
          <IconButton
            key="close"
            color="inherit"
            onClick={() => setSnackbarOpen(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Box>
  );
}
