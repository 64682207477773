// Sidebar.js
import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, Navigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { NavLink } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import ViewListIcon from "@mui/icons-material/ViewList";
import PersonIcon from "@mui/icons-material/Person";
import styles from "./include.module.css";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import { useAppContext } from "./AppContext";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { usePath } from "./PathContext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import BallotIcon from '@mui/icons-material/Ballot';



const drawerWidth = 120;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "white", // Set the background color to white
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const Sidebar = () => {
  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  if (userDataObj && userDataObj.role_access != null) {
    var role = userDataObj.role_access;
  }

  // console.log('role_access', role_access)
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [usertoken, setusertoken] = useState("");
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  const [rolename, setrolename] = useState("");
  const [pic, setPic] = useState("");
  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };
  const updateUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("userdata");
    console.log(storedUserData);
    if (storedUserData) {
      const userDataObj = JSON.parse(storedUserData);
      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
        full_name: user_full_name,
        picture: picture,
        role_name: role_name,
      } = userDataObj;

      setPic(picture);
      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setFullName(user_full_name);
      setrolename(role_name);
    }
  };
  useEffect(() => {
    updateUserDataFromLocalStorage();
  }, []);
  const confirmLogout = () => {
    // Implement your logout logic here
    // For example, redirect to the login page or clear user session
    console.log("Logout confirmed");
    handleClose();

    localStorage.removeItem("userdata");
    window.location.replace(`${window.constants.base_path}`);
  };

  const cancelLogout = () => {
    setLogoutDialogOpen(false);
  };
  const navigate = useNavigate(); // U
  const { searchText } = useAppContext();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const { currentPath, setCurrentPath } = usePath();
  useEffect(() => {
    // Set the current path when the component mounts

    // const currentPath = window.location.hash.substr(1);
    // setCurrentPath(currentPath);
    setCurrentPath(window.location.pathname);
    console.log("setCurrentPath: " + currentPath);
  }, [currentPath]);

  const reloadText = (currentPath) => {
    if (currentPath === "/dashboard") return "Dashboard";
    else if (currentPath === "/repository")
      return "Repository";
    else if (currentPath === "/repositoryconnected")
      return "Repository Connected";
    else if (currentPath === "/searchinvoices")
      return "Search Invoices";
    else if (currentPath === "/favourite-list")
      return "Favourites";
    else if (currentPath === "/change-password")
      return "Change password";
    else if (currentPath === "/onedrive-account")
      return "Onedrive Account";
    else if (currentPath === "/admin-console/document-category")
      return "Document Category";
    else if (currentPath === "/admin-console/invoice-extraction")
      return "Manage Extraction";
    else if (currentPath === "/admin-console/user")
      return "Manage User";
    else return "Share";
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = () => {
    // Introduce a 1-second delay using setTimeout
    setTimeout(() => {
      setIsAdmin((prevIsAdmin) => !prevIsAdmin);
      navigate("/admin-console/user");
    }, 300); // 1000 milliseconds = 1 second
  };
  const Navigation = () => {
    navigate("/change-password");
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const location = useLocation();

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {/* Replace the following image with your account icon */}
          <img
            alt=""
            src={window.constants.asset_path + "/image.png"}
            style={{ width: "160px" }}
          />

          <Typography
            variant="h6"
            color="black"
            noWrap
            component="div"
            sx={{ marginLeft: "30px" }}
          >
            {searchText ? searchText : reloadText(currentPath)}

            {/* Add more conditions for additional tabs */}
          </Typography>
          <div style={{ flexGrow: 1 }} />
          {role == "all" && (
            <Box>
              <NavLink
                to={`/admin-console/user`}
                style={{
                  color: "#2F80ED",
                  display: "flex",
                  textDecoration: "none",
                }}
              >
                {/* <Typography>Go to Admin</Typography> */}
                {/* <ArrowForwardIcon /> */}
              </NavLink>
            </Box>
          )}
          <IconButton onClick={handleClick} color="inherit">
            {/* Replace the following line with your account icon */}
            {/* <img src={pic} alt="" /> */}
            <AccountCircleIcon style={{ color: "grey" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {/* <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            Update Profile
          </MenuItem> */}
            {/* <MenuItem onClick={handleSwitchChange}>
            <ListItemIcon>
            <ViewQuiltIcon />
            </ListItemIcon>
            Switch to Admin
          </MenuItem> */}
            <Box sx={{ marginLeft: "20px" }}>
              <List>{fullName}</List>
              <List sx={{ fontSize: "14px", color: "grey" }}>{rolename}</List>
            </Box>
            <Divider />
            <MenuItem onClick={Navigation}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <div style={{ marginRight: "20px", marginTop: "17px" }}>
            <ListItem
              component={Link}
              to="/dashboard"
              className={`${styles.menuitem1} ${
                location.pathname === "/dashboard" ? styles.active : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/dashboard"
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px", // Adjust the padding as needed
                marginLeft: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <DashboardIcon
                  style={{ alignItems: "center", marginLeft: "10px" }}
                  color={
                    location.pathname === "/dashboard" ? "primary" : "inherit"
                  }
                />
              </ListItemIcon>
              <span
                primary="Dashboard"
                style={{
                  color:
                    location.pathname === "/dashboard" ? "blue" : "inherit",
                    fontSize: "0.8rem" 
                }}
              > Dashboard</span>
            </ListItem>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/repository"
              className={`${styles.menuitem1} ${
                location.pathname === "/repository" ||
                location.pathname.startsWith("/repositoryconnected") ||
                location.pathname.startsWith("/onedrive-account") ||
                location.pathname.startsWith("/default-repository")
                  ? styles.active
                  : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/repository" ||
                  location.pathname.startsWith("/repositoryconnected") ||
                  location.pathname.startsWith("/onedrive-account") ||
                  location.pathname.startsWith("/default-repository")
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px",
                marginLeft: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <FolderOpenIcon
                  style={{ alignItems: "center", marginLeft: "10px" }}
                  color={
                    location.pathname === "/repository" ||
                    location.pathname.startsWith("/repositoryconnected") ||
                    location.pathname.startsWith("/onedrive-account") ||
                    location.pathname.startsWith("/default-repository")
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <span
                primary="Repository"
                style={{
                  color:
                    location.pathname === "/repository" ||
                    location.pathname.startsWith("/repositoryconnected") ||
                    location.pathname.startsWith("/onedrive-account") ||
                    location.pathname.startsWith("/default-repository")
                      ? "blue"
                      : "inherit",
                      fontSize: "0.8rem" 
                }}
                >Repository
              </span>
            </ListItem>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/invoice-extraction"
              className={`${styles.menuitem1} ${(location.pathname === '/admin-console/invoice-extraction' || location.pathname.startsWith('/admin-console/invoice-extraction')) ? styles.active : ''}`}
              style={{
                textAlign: 'center',
                backgroundColor: (location.pathname === '/admin-console/invoice-extraction' || location.pathname.startsWith('/admin-console/invoice-extraction')) ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                padding: '8px',
                marginLeft: '10px',
                marginBottom: '16px',
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon>
                {/* Replace with your icon for Document Entity */}
                <BallotIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={(location.pathname === '/admin-console/invoice-extraction' || location.pathname.startsWith('/admin-console/invoice-extraction')) ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <span primary="Manage Extraction" style={{
                color: (location.pathname === '/admin-console/invoice-extraction' || location.pathname.startsWith('/admin-console/invoice-extraction')) ? 'blue' : 'inherit',
                fontSize:"0.8rem"
              }} >Manage Extraction
              </span>
            </ListItem>

          </div>
          
          {/* <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/searchdocument-type"
              className={`${styles.menuitem1} ${(location.pathname === '/admin-console/searchdocument-type' || location.pathname.startsWith('/admin-console/document-type')) ? styles.active : ''}`}
              style={{
                textAlign: 'center',
                backgroundColor: (location.pathname === '/admin-console/searchdocument-type' || location.pathname.startsWith('/admin-console/document-type')) ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                padding: '8px',
                marginLeft: '10px',
                marginBottom: '16px',
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon>
                < InsertDriveFileIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={location.pathname === '/admin-console/document-type' ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText primary="Document Type" style={{
            color: (location.pathname === '/admin-console/searchdocument-type' || location.pathname.startsWith('/admin-console/document-type')) ? 'blue' : 'inherit',
              }} />
            </ListItem>
          </div> */}
          {/* <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/searchinvoices"
              className={`${styles.menuitem1} ${
                location.pathname === "/searchinvoices" ? styles.active : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/searchinvoices"
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px", // Adjust the padding as needed
                marginLeft: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <ViewListIcon
                  style={{ alignItems: "center", marginLeft: "10px" }}
                  color={
                    location.pathname === "/searchinvoices"
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText>
                <Typography
                  variant="body1"
                  style={{
                    whiteSpace: "normal",
                    color:
                      location.pathname === "/searchinvoices"
                        ? "blue"
                        : "inherit",
                    fontweight: "bold",
                    fontSize:"0.8rem"
                  }}
                >
                  Search Documents
                </Typography>
              </ListItemText>
            </ListItem>
          </div> */}
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/document-category"
              className={`${styles.menuitem1} ${(location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? styles.active : ''}`}
              // className={`${styles.menuitem1} ${(location.pathname === '/admin-console/document-category') ? styles.active : ''}`}

              style={{
                textAlign: 'center',
                backgroundColor: (location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                // backgroundColor: (location.pathname === '/admin-console/document-category') ? 'rgba(25, 118, 210, 0.2)' : 'inherit',

                padding: '8px',
                marginLeft: '10px',
                marginBottom: '16px',
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon>
                {/* Replace with your icon for Document Entity */}
                <InsertDriveFileIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={(location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'primary' : 'inherit'} />
                {/* <InsertDriveFileIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={(location.pathname === '/admin-console/document-category') ? 'primary' : 'inherit'} /> */}

              </ListItemIcon>
              <span primary="Document Config" style={{
                color: (location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'blue' : 'inherit',
                // color: (location.pathname === '/admin-console/document-category') ? 'blue' : 'inherit',
                fontSize:"0.8rem"
              }} >Document Config</span>
            </ListItem>

          </div>
          
          {/* <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/favourite-list"
              className={`${styles.menuitem1} ${
                location.pathname === "/favourite-list" ? styles.active : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/favourite-list"
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px", // Adjust the padding as needed
                marginLeft: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <FavoriteIcon
                  style={{ alignItems: "center", marginLeft: "10px" }}
                  color={
                    location.pathname === "/favourite-list"
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <span
                primary="Favourite"
                style={{
                  color:
                    location.pathname === "/favourite-list"
                      ? "blue"
                      : "inherit",
                      fontSize:"0.8rem"
                }}
              >Favourite</span>
            </ListItem>
          </div> */}
          <div style={{ marginRight: "20px", marginTop: '17px' }}>
          {localStorage.getItem('userdata') &&  (JSON.parse(localStorage.getItem('userdata')).user_priviledge===0 || JSON.parse(localStorage.getItem('userdata')).user_priviledge===1) && 
          <ListItem
              component={Link}
              to="/admin-console/user"
              className={`${styles.menuitem1} ${location.pathname === '/admin-console/user' ? styles.active : ''}`}
              style={{
                textAlign: 'center',
                backgroundColor: location.pathname === '/admin-console/user' ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                padding: '8px',
                marginLeft: '10px',
                marginBottom: '16px',
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon>
                <PersonIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={location.pathname === '/admin-console/user' ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <span primary="Manage User" style={{
                color: location.pathname === '/admin-console/user' ? 'blue' : 'inherit',
                fontSize:"0.8rem"
              }}>Manage User</span>
            </ListItem>}
          </div>
          <div style={{ marginRight: "20px", marginTop: '17px' }}>
           {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" &&<ListItem
              component={Link}
              to="/admin-console/department"
              className={`${styles.menuitem1} ${location.pathname === '/admin-console/department' ? styles.active : ''}`}
              style={{
                textAlign: 'center',
                backgroundColor: location.pathname === '/admin-console/department' ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                padding: '8px',
                marginLeft: '10px',
                marginBottom: '16px',
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon color={location.pathname === '/admin-console/department' ? 'primary' : 'inherit'}>
                <img src="../department-structure.ico" width={30} height={30} style={{ alignItems: 'center', marginLeft: '15px',color:location.pathname === '/admin-console/department' ? 'primary' : 'inherit'}} />
                {/* <PersonIcon  /> */}
              </ListItemIcon>
              <span primary="Manage User" style={{
                color: location.pathname === '/admin-console/department' ? 'blue' : 'inherit',
                fontSize:"0.8rem"
              }}>Manage Department</span>
            </ListItem>}
          </div>
        </List>
      </Drawer>
      <Dialog open={logoutDialogOpen} onClose={cancelLogout}>
        <DialogTitle>Are you sure you want to Logout?</DialogTitle>
        <DialogActions>
          <Button onClick={cancelLogout} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Sidebar;
