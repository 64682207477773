import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from '@mui/material/Typography';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';


const TableHeaderRow = ({ data }) => {
  return (
    <tr>
      {
        data.map((val) => (
          <th>{val}</th>
        ))
      }
    </tr>
  );
};

const TableRoww = ({ data }) => {
  return data.map((data) => (
    <TableRow>
      { data &&
        /*  This maps each array item to a div adds
                the style declared above and return it */
        data.map((val) => (
          <TableCell align="right">{val}</TableCell>
        ))
      }
      {/* <td>{data[0]}</td><td>{data.lastname}</td><td>{data.email}</td><td>{data.password}</td> */}
    </TableRow>
  ));
};

const CustomTable = ({ data }) => {
  return (
    <Table aria-label="simple table">
      {/* <TableHeaderRow data={data[0]}/> */}
      {data &&
      <TableRoww data={data} />
    }
    </Table>
  );
};

export default function DataTable({data}) {
  // console.log("TABLE",data)
  const [page, setPage] = React.useState(1);
  const [currentTableData,setCurrentTableData]=React.useState([[]])
  const handleChange = (event, value) => {
    setPage(value);
    setCurrentTableData(data[value-1])
    console.log(data[value-1])
  };
  return (
    <div>
    
      {data.length!==0 &&
      <div>
        <span style={{fontSize:"14px"}}>
        Extracted Tables
        </span>
        <div>
        {data.length &&
        <Pagination count={data.length} page={page} onChange={handleChange} />
        }
        </div>
        </div>
      }
      {data.length==0 &&
      <div>Table not found</div>
      }
   
      <TableContainer component={Paper}>
        {data &&
        <CustomTable data={data[page-1]} />
        }
      </TableContainer>
    </div>
  );
}

// import * as React from 'react';
// import { DataGrid } from '@mui/x-data-grid';

// const columns = [
//   { field: 'id', headerName: 'Item ID', width: 100 },
//   { field: 'Description', headerName: 'Description', width: 100 },
//   { field: 'Quantity', headerName: 'Quantity', width: 100 },
//   { field: 'UOM', headerName: 'UOM', width: 100 },
//   { field: 'Price', headerName: 'Line Price', width: 100 },

// ];

// const rows = [
//   { id: "BC85A4", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity: '1000', UOM: "EACH" ,Price:"681.83 ",},
//    { id: "BC85A", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity: '1000', UOM: "EACH" ,Price:"681.83 "}, 
//    { id: "2", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity: '1000', UOM: "EACH" ,Price:"681.83 "}, 
//    { id: "3", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity: '1000', UOM: "EACH" ,Price:"681.83 "}, 
//    { id: "4", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity: '1000', UOM: "EACH" ,Price:"681.83 "}, 
//    { id: "6", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity:'1000', UOM: "EACH" ,Price:"681.83 "}, 
//    { id: "7", Description: 'COVER A4 CLEAR FOR BINDING 0.25MM',
//    Quantity: '1000', UOM: "EACH" ,Price:"681.83 "}, 
// ];


// export default function DataTable({table}) {
//   const [tableData,setTableData]=React.useState([])
//   React.useEffect(()=>{
//       if(len(table)>0){

//       }
//   },[])

//   return (
//     <div style={{ height: 400 }}>
//       <DataGrid
//         rows={rows}
//         columns={columns}
//         initialState={{
//           pagination: {
//             paginationModel: { page: 0, pageSize: 5 },
//           },
//         }}
//         pageSizeOptions={[5, 10]}
//         checkboxSelection
//       />
//     </div>
//   );
// }