import React, { useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { Stack, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

const BarChartComponent = () => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    // Handle date change logic here
  };

  const data = [
    { name: "January", blue: 120000, deepBlue: 80000 },
    { name: "February", blue: 190000, deepBlue: 140000 },
    { name: "March", blue: 250000, deepBlue: 180000 },
    { name: "April", blue: 220000, deepBlue: 160000 },
    { name: "May", blue: 150000, deepBlue: 120000 },
    { name: "June", blue: 100000, deepBlue: 60000 },
    { name: "July", blue: 80000, deepBlue: 50000 },
    { name: "August", blue: 160000, deepBlue: 100000 },
    { name: "September", blue: 200000, deepBlue: 150000 },
    { name: "October", blue: 180000, deepBlue: 140000 },
    { name: "November", blue: 140000, deepBlue: 120000 },
    { name: "December", blue: 100000, deepBlue: 80000 },
  ];

  const formatYAxis = (tick) => `${tick / 100000}L`;

  return (
    <div>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={data}
          margin={{ top: 20, right: 20, left: 20, bottom: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis tickFormatter={formatYAxis} />
          <Tooltip />
          <Legend />
          <Bar
            dataKey="blue"
            fill="#5bc0de"
            name="Document Uploaded"
            barSize={10}
          />
          <Bar
            dataKey="deepBlue"
            fill="#005AC1"
            name="Document Processed"
            barSize={10}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartComponent;
