import styles from "./user.module.css";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';

import Sidebar from "../../Includes/Sidebar.jsx";
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import {  Button, CircularProgress, DialogTitle, Menu, Snackbar, SnackbarContent } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem, ListItemIcon, FormControl, InputLabel, Select, IconButton, Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';

import BorderColorIcon from '@mui/icons-material/BorderColor';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import DeleteIcon from '@mui/icons-material/Delete';
import './User.css';
import { getAllDepartment ,addDepartment,editDepartment,deleteDepartment} from "../AdminConsoleApiHelper/DeparmentApiHelper.js";
import { userMasterData, userChangeStatus } from "../AdminConsoleApiHelper/AdminApiHelper.js";
const AdminDepartment = () => {
  const [openmenuitem, SetOpenmenuitem] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [Loadingtable, setLoadingtable] = useState(true)
  const [UserDetails, setUserdetails] = useState([])
  const [usertoken, setusertoken] = useState("");
  const [index, setIndex] = useState(0);
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  const [designationSearchResults, setDesignationSearchResults] = useState([]);
  const [roleSearchResults, setRoleSearchResults] = useState([]);
  const [departmentSearchResults, setDepartmentSearchResults] = useState([]);
  const [showStaticContent, setShowStaticContent] = useState(false);
  const [userconversation_id, setuserconversation_id] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastmessage, settoastmessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [total_rec, setTotal_rec] = useState('');

  const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const admin = userDataObj.superadmin;
      console.log('admin', admin)
  useEffect(() => {

    GetDepartmentData()
  }, []);
  const GetDepartmentData = async () => {
    setLoadingtable(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);
      const res = await getAllDepartment(deepuser_id, deeporg_id, userToken,"");

      // console.log(res)
      if (res.code === "200") {
        setTotal_rec(res.total_rec)
        setLoadingtable(false);
        // console.log(res.data)
        setUserdetails(res.data)
      } else {
        // Handle the error
        console.error("Error:",);
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(true);
    }
  };



  useEffect(() => {
    const fetchMasterData = async () => {
      setLoadingtable(true);
      try {
        const storedUserData = localStorage.getItem("userdata");

        if (!storedUserData) {
          console.error("User data not found in localStorage.");
          return;
        }

        const userDataObj = JSON.parse(storedUserData);
        console.log(userDataObj);
        if (!userDataObj || !userDataObj.token) {
          console.error(
            "User token not found in localStorage or component unmounted."
          );
          return;
        }

        const {
          token: userToken,
          user_id: deepuser_id,
          org_id: deeporg_id,
        } = userDataObj;

        setusertoken(userToken);
        setuser_id(deepuser_id);
        setuserorg_id(deeporg_id);
        setIndex(0);
        const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
        if (requestData.code == "200") {
          setuserconversation_id(requestData.conversation_id);
          // const designationResults = requestData.designation;
          // setDesignationSearchResults((prevResults) => [
          //     ...designationResults,
          //     ...prevResults,
          // ]);
          setDesignationSearchResults(requestData.designation)
          // const roleResults = requestData.role;
          // setRoleSearchResults((prevResults) => [
          //     ...roleResults,
          //     ...prevResults,
          // ]);
          setRoleSearchResults(requestData.role)
          // const departmentResults = requestData.department;
          // setDepartmentSearchResults((prevResults) => [
          //     ...departmentResults,
          //     ...prevResults,
          // ]);
          setDepartmentSearchResults(requestData.department)
          setLoadingtable(false)
          setShowStaticContent(false);
        } else {
          setShowStaticContent(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        // Make sure to set isLoading to false whether the fetch was successful or not
        setLoadingtable(false);
      }
    };

    fetchMasterData();

    return () => { };
  }, []);

  const handleAddDepartment = async () => {
    setButtonLoading(true)
    try {
      //   setdeleteLoading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await addDepartment(
        deepuser_id,
        deeporg_id,
        userToken,
        departmentName,
        departmentDescription
      );

      if (responseData.code === "200") {

        setButtonLoading(false)
        setOpenModal(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
        GetDepartmentData();
      } else {

        setButtonLoading(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      //   setdeleteLoading(false)
      setButtonLoading(false);
    }
  };
  const handleDeleteDepartment = async () => {
    setButtonLoading(true)
    try {
      //   setdeleteLoading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await deleteDepartment(
        deepuser_id,
        deeporg_id,
        userToken,
        departmentId
      );

      if (responseData.code === "200") {

        setViewDetailsModalOpen(false)
        setOpenModal(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
        GetDepartmentData();
      } else {

        setButtonLoading(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      //   setdeleteLoading(false)
      setButtonLoading(false);
    }
    setButtonLoading(false)

  };

  const handleEditDepartment = async () => {
    setButtonLoading(true)
    try {
      //   setdeleteLoading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await editDepartment(
        deepuser_id,
        deeporg_id,
        userToken,
        departmentName,
        departmentDescription,
        departmentId
      );

      if (responseData.code === "200") {

        setButtonLoading(false)
        setEditUserModalOpen(false);
        closeEditUserModal()
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
        GetDepartmentData();
      } else {

        setButtonLoading(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      //   setdeleteLoading(false)
      setButtonLoading(false);
    }
  };

 


  
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false)
  const openMenu = Boolean(openmenuitem);
  const openEditUserModal = () => {
    setEditUserModalOpen(true);
  };
  const openViewDetailsModal = () => {
    setViewDetailsModalOpen(true);
    SetOpenmenuitem(null);
  };
  const openChangeStatusModal = () => {
    setChangeStatusModalOpen(true);
    SetOpenmenuitem(null);
  };
  const closeEditUserModal = () => {
    setEditUserModalOpen(false);
    SetOpenmenuitem(null);
  };
  const closeViewDetailsModal = () => {
    setViewDetailsModalOpen(false);
    SetOpenmenuitem(null);
  };
  const closeChangeStatusModal = () => {
    setChangeStatusModalOpen(false);
    SetOpenmenuitem(null);
  };
  const [departmentName,setDepartmentName]=useState("")
  const [departmentDescription,setDepartmentDescription]=useState("")
  const [departmentId,setDepartmentId]=useState("")
  const [tempUserStatus, setTempUserStatus] = useState('')
 

  const handleClose = () => {
    SetOpenmenuitem(null);
  };
  const handlemodalclose = () => {
    // Add your logic for creating a new user here
    console.log('Creating a new user...');
    setOpenModal(false);
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  // const handleFileChange = (event) => {
  //   const imgFile = event.target.files[0];
  //   if (imgFile) {
  //     // Make sure a file is selected
  //     setUser_photo(URL.createObjectURL(imgFile));
  //     console.log('imgfile', imgFile)
  //     console.log('tempuserphoto', user_photo)
  //   }

  // };
 
  const handleClickMenu = (event, user) => {
    SetOpenmenuitem(event.currentTarget);
    
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };


  
  return (
    <Box component="main" sx={{ display: 'flex', backgroundColor: "#EFEDF1", flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ textAlign: 'left', marginLeft: '150px', marginRight: '50px', marginTop: '100px', display: 'flex', justifyContent: 'space-between', }}>

        <Typography sx={{ fontSize: '16px', color: '#606060' }}>Create and manage your departments</Typography>

        <Button
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
          variant="contained"
          color="primary"
          startIcon={<PersonAddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Create new department
        </Button>
      </Box>
      <Box sx={{ textAlign: 'left', marginLeft: '150px', fontSize: '13px', color: '#606060' }}>Total {total_rec ? total_rec : 0} users </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', marginLeft: '132px', marginRight: '2px', marginTop: '5px' }}>
        <TableContainer component={Paper} style={{ boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)', height: '100%', marginBottom: '15px' , maxHeight: "calc(100vh - 220px)",}}>
        <Table  size="small" sx={{ minWidth: 700 }}aria-label="simple table">
                  <TableHead sx={{  position: 'sticky', top: 0, zIndex: '999',backgroundColor: '#EFF1F3', }}>
              <TableRow>
                {/* <TableCell sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>SL. No.</TableCell> */}
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Name</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Organization	</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Description</TableCell>
                <TableCell align="center" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Loadingtable && (

                <TableCell colSpan={6} align="center" sx={{ width: '1000px' }}>
                  <CircularProgress />
                </TableCell>

              )}
              {!Loadingtable && (
                <>
                  {UserDetails.map((user, index) => (
                    <TableRow key={index}>
                      {/* <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell> */}
                      <TableCell >
                        <Box sx={{ display: 'flex' }}>
                          {/* <Box > <img src={user.photo} style={{ width: '27px', height: '27px', borderRadius: '50px' }} /> </Box> */}
                          <Box sx={{ marginTop: '4px', marginLeft: '5px' }}>{user.department_name	}</Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{user.organization_name	}</TableCell>
                      <TableCell align="left">{user.description}</TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          aria-controls="three-dot-menu"
                          aria-haspopup="true"
                          onClick={(event) => {
                            handleClickMenu(event, user);
                            setDepartmentId(user.department_id)
                            setDepartmentDescription(user.description)
                            setDepartmentName(user.department_name)
                          }}
                          sx={{
                            borderRadius: '50%', // Make the button round
                            backgroundColor: '#EFF1F3',
                            '&:hover': {
                              backgroundColor: '#EFF1F3', // Round hover effect
                            },
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={openmenuitem}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >

          <Box>
            
        
            <MenuItem onClick={openEditUserModal}>
              <ListItemIcon>
                <BorderColorIcon />
              </ListItemIcon>
              Edit Department
            </MenuItem>
            <MenuItem onClick={openViewDetailsModal}>
              <ListItemIcon >
                <DeleteIcon />
              </ListItemIcon>
              Delete Department
            </MenuItem>
            
            <Dialog open={editUserModalOpen} onClose={closeEditUserModal}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px",
                  width: '600px'
                },
              }} >
              <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                <Box>
                  <strong>Edit department</strong>

                </Box>
                <Box position='absolute' left='90%'>
                  <IconButton>
                    <CloseIcon onClick={closeEditUserModal} />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent >
                <Box sx={{ marginTop: '20px' }}>
                 
                  <Box sx={{ margin: '15px 0px', fontSize: '14px' }} fontWeight='bold'>
                   
                    <Box sx={{ margin: '15px 0px' }}>
                      <Box sx={{ display: 'flex', margin: '20px 0px',alignItems:'center' }}>
                        <Box sx={{width:"220px"}} fontWeight='bold'>Department name<span style={{ color: 'red' }}>*</span> </Box>
                        <Box >
                          <TextField sx={{ width: '320px' }}
                            value={departmentName}
                            placeholder="Enter department name"
                            onChange={(e) => setDepartmentName(e.target.value)}
                           />
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px',alignItems:'center' }}>
                        <Box  sx={{width:"220px"}} fontWeight='bold'>Department description<span style={{ color: 'red' }}>*</span> </Box>
                        <Box >
                          <TextField sx={{ width: '320px' }}
                            placeholder="Enter department description"
                            value={departmentDescription}
                            onChange={(e) => setDepartmentDescription(e.target.value)}
                            />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  
                </Box>
              </DialogContent>
              <DialogActions sx={{ marginBottom: '10px', marginRight: '25px' }}>
                <Button onClick={closeEditUserModal} variant="outlined" sx={{ textTransform: 'none' }}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ textTransform: 'none' }} onClick={handleEditDepartment} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                  {buttonLoading && <CircularProgress size={16} />}Edit Department
                </Button>
              </DialogActions>
            </Dialog>
            
           
          
          </Box>


        </Menu>
        <Dialog open={openModal} onClose={() => setOpenModal(false)} className="desktop_view_modal_show" sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: '100%',
            // display: 'flex',

            // alignItems: 'flex-end',
            // flexDirection: 'column'


          },
        }} >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
              <Box>
                <Typography variant="h6">Create new department</Typography>
              </Box>

              <Box >
                <IconButton>
                  <CloseIcon onClick={handlemodalclose} />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent >
            <Box sx={{ marginTop: '20px' }}> 
              <Box sx={{ margin: '15px 0px', fontSize: '14px' }} fontWeight='bold'>
                
                <Box sx={{ margin: '15px 0px' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>Department Name<span style={{ color: 'red' }}>*</span> </Box>
                    <Box >
                      <TextField sx={{ width: '320px' }}
                        placeholder="Enter department name"
                        onChange={(e) => setDepartmentName(e.target.value)}
                        />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>Department Description<span style={{ color: 'red' }}>*</span></Box>
                    <Box >
                      <TextField sx={{ width: '320px' }}
                        placeholder="Enter department description"
                        onChange={(e) => setDepartmentDescription(e.target.value)}
                         />
                    </Box>
                  </Box>
                </Box>
              </Box>
             
            </Box>
          </DialogContent>
          <DialogActions sx={{ marginBottom: '10px', marginRight: '30px', gap: '14px', textTransform: 'none' }}>
            <Button variant="outlined" onClick={handlemodalclose} sx={{ fontWeight: '600', textTransform: 'none' }} >
              Cancel
            </Button>
            <Button variant="contained" sx={{ fontWeight: '600', textTransform: 'none' }} onClick={handleAddDepartment} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
              {buttonLoading && <CircularProgress size={16} />}Save
            </Button>
          </DialogActions>
        </Dialog>
       
        <Dialog open={viewDetailsModalOpen} onClose={closeViewDetailsModal}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px",
                  width: '530px'
                },
              }} >
              <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                <Box>
                  <strong>Confirm delete department</strong>

                </Box>
                <Box position='absolute' left='90%'>
                  <IconButton>
                    <CloseIcon onClick={closeViewDetailsModal} />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent >
                <Box sx={{ marginTop: '20px' }}>
                Are you sure you want delete the department?
                </Box>
              </DialogContent>
              <DialogActions sx={{ marginBottom: '10px', marginRight: '30px', gap: '14px', textTransform: 'none' }}>
            <Button variant="outlined" onClick={closeViewDetailsModal} sx={{ fontWeight: '600', textTransform: 'none' }} >
              Cancel
            </Button>
            <Button variant="contained" sx={{ fontWeight: '600', textTransform: 'none' }} onClick={handleDeleteDepartment} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
              {buttonLoading && <CircularProgress size={16} />} delete
            </Button>
          </DialogActions>
             
            </Dialog>
        <Snackbar
          mode="outlined"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center', // Center bottom
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            style={{
              backgroundColor: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {toastmessage}
              </span>
            }
            action={[
              <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        
      </Box>
      <Sidebar />
    </Box>
  );
};

export default AdminDepartment;
