import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const GraphChart = () => {
  const data = [
    { year: 2010, value: 500000 },
    { year: 2012, value: 800000 },
    { year: 2014, value: 1200000 },
    { year: 2016, value: 2000000 },
    { year: 2018, value: 1500000 },
    { year: 2020, value: 1000000 },
    { year: 2022, value: 500000 },
  ];

  const formatYAxis = (tick) => `${tick / 100000}L`;

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <Line
          type="monotone"
          dataKey="value"
          stroke="#5bc0de"
          strokeWidth={2}
          dot={{ strokeWidth: 4 }}
        />
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis tickFormatter={formatYAxis} />
        <Tooltip />
        <Legend />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default GraphChart;
