export const getAllDepartment = async (user_id, userorg_id, token,department_id) => {
    try {
      const apiEndpoint = "admin-console/getalldepartment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        start_index: 0,
        limit: 100,
        department_id
      };
  
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const addDepartment = async (user_id, userorg_id, token, department_name,department_desc) => {
    try {
      const apiEndpoint = "admin-console/createdepartment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        department_name,
        department_desc
      };
  
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

export const deleteDepartment = async (user_id, userorg_id, token, department_id) => {
    try {
      const apiEndpoint = "admin-console/deletedepartment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        department_id
      };
  
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };

  export const editDepartment = async (user_id, userorg_id, token, department_name,department_desc,department_id) => {
    try {
      const apiEndpoint = "admin-console/editdepartment";
      const API_URL = `${window.constants.api_url}/${apiEndpoint}`;
  
      const requestData = {
        org_id: userorg_id,
        user_id,
        department_name,
        department_desc,
        department_id
      };
  
      const response = await fetch(API_URL, {
        method: "POST",
        headers: {
          token: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("API request error:", error);
      throw error;
    }
  };