export const getAllUser = async (user_id, userorg_id, token) => {
  try {
    const apiEndpoint = "admin-console/getallusers";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 100,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const fetchMoreUser = async (user_id, userorg_id, token, index) => {
  try {
    const apiEndpoint = "admin-console/getallusers";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: index,
      limit: 15,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const addUser = async (
  user_id,
  userorg_id,
  user_name,
  photo,
  gender,
  email,
  phone,
  emp_id,
  designation,
  role,
  department,
  token
) => {
  // const BASE_URL = `${window.constants.api_url}/admin-console/adduser`;
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", userorg_id.toString());
    formdata.append("name", user_name.toString());
    formdata.append("photo", photo);
    formdata.append("gender", gender.toString());
    formdata.append("email", email.toString());
    formdata.append("phone", phone.toString());
    formdata.append("emp_id", emp_id.toString());
    formdata.append("designation", designation.toString());
    formdata.append("role", role.toString());
    formdata.append("department", department.toString());

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/admin-console/adduser`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

// export const updateProfile = async (user_id, org_id, full_name, token, profilePhoto) => {
//     try {
//       var myHeaders = new Headers();
//       myHeaders.append("token", token);

//       var formdata = new FormData();
//       formdata.append("user_id", user_id.toString());
//       formdata.append("org_id", org_id.toString());
//       formdata.append("full_name", full_name.toString());
//       formdata.append("photo", profilePhoto);

//       var requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: formdata,
//         redirect: 'follow',
//       };

//       const response = await fetch(`${window.constants.api_url}/profile-update`, requestOptions);
//       const result = await response.json();
//       return result;
//     } catch (error) {
//       console.log('error', error);
//     }
//   };

export const editUser = async (
  user_id,
  userorg_id,
  profile_id,
  user_name,
  photo,
  gender,
  email,
  phone,
  emp_id,
  designation,
  role,
  department,
  token
) => {
  // const BASE_URL = `${window.constants.api_url}/admin-console/edituser`;

  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", userorg_id.toString());
    formdata.append("name", user_name.toString());
    formdata.append("photo", photo);
    formdata.append("gender", gender.toString());
    formdata.append("email", email.toString());
    formdata.append("phone", phone.toString());
    formdata.append("emp_id", emp_id.toString());
    formdata.append("designation", designation.toString());
    formdata.append("role", role.toString());
    formdata.append("department", department.toString());
    formdata.append("profile_id", profile_id.toString());
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/admin-console/edituser`,
      requestOptions
    );
    const result = await response.json();
    return result;
  } catch (error) {
    console.log("error", error);
  }
};

export const userMasterData = async (user_id, userorg_id, token) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/usermaster`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const userChangeStatus = async (
  user_id,
  userorg_id,
  profile_id,
  status,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/changeuserstatus`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      profile_id: profile_id,
      status: status,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllDocumentType = async (
  user_id,
  userorg_id,
  cat_id,
  token
) => {
  try {
    const apiEndpoint = "admin-console/getalldocumenttypes";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      cat_id,
      start_index: 0,
      limit: 15,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const addDocumentType = async (
  user_id,
  userorg_id,
  name,
  cat_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/adddocumenttypes`;
  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      name: name,
      cat_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};


export const editDocumentType = async (
  user_id,
  userorg_id,
  name,
  id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/editdocumenttype`;
  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      name: name,
      id: id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllDocumentEntity = async (
  user_id,
  userorg_id,
  catagory_id,
  token
) => {
  try {
    const apiEndpoint = "admin-console/getalldocumenttypes";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      catagory_id: catagory_id,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const addDocumentEntity = async (
  user_id,
  userorg_id,
  metaKey,
  description,
  tags,
  dataType,
  selectedValue,
  selectedSecondDropdownId,
  token,
  columnData
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/addentity`;
  try {
    const requestData = {
      user_id: user_id,
      org_id: userorg_id,
      meta_key: metaKey,
      meta_description: description,
      data_extraction_alias: tags,
      entity_datatype: dataType,
      category_id: selectedValue,
      documenttype_ref_id: selectedSecondDropdownId,
      table:columnData
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const editDocumentEntity = async (
  user_id,
  userorg_id,
  metaKey,
  description,
  tags,
  dataType,
  selectedValue,
  selectedSecondDropdownId,
  id,
  token,
  column_data
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/editentity`;
  try {
    const requestData = {
      user_id: user_id,
      org_id: userorg_id,
      meta_key: metaKey,
      meta_description: description,
      data_extraction_alias: tags,
      entity_datatype: dataType,
      category_id: selectedValue,
      id: id,
      documenttype_ref_id: selectedSecondDropdownId,
      table:column_data
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const deleteEntity = async (
  user_id,
  org_id,
  id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/deleteentity`;
  try {
    const requestData = {
      user_id: user_id,
      org_id: org_id,
      id: id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const entityChangeStatus = async (
  user_id,
  userorg_id,
  category_id,
  selectedSecondDropdownId,
  entity_id,
  status,
  type,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/changedocentitystatus`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      category_id: category_id,
      documenttype_ref_id: selectedSecondDropdownId,
      entity_id: entity_id,
      status: status,
      type: type,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const setEntityOrder = async (
  user_id,
  userorg_id,
  category_id,
  order,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/setdocumententityorder`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      category_id: category_id,
      order: order,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const SearchinvoiceExtDetails = async (
  user_id,
  userorg_id,
  cat_id,
  datelist,
  fromdate,
  todate,
  confidence_range,
  search_string,
  selectedStatus,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/searchinvoiceextraction`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      cat_id,
      date: {
        type: "1",
        value: datelist,
      },

      confidence_range,
      search_string,
      status: selectedStatus,
      start_index: 0,
      limit: 10,
    };
    if (fromdate !== "" && todate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromdate},${todate}`,
      };
    }
    console.log(requestData.datelist);
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const LoadSearchinvoiceExtDetails = async (
  user_id,
  userorg_id,
  cat_id,
  datelist,
  fromdate,
  todate,
  confidence_range,
  search_string,
  selectedStatus,
  token,
  index
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/searchinvoiceextraction`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      cat_id,
      date: {
        type: "1",
        value: datelist,
      },

      confidence_range,
      search_string,
      status: selectedStatus,
      start_index: index,
      limit: 10,
    };
    if (fromdate !== "" && todate !== "") {
      requestData.date = {
        type: "2",
        value: `${fromdate},${todate}`,
      };
    }
    console.log(requestData.datelist);
    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const invoiceExtractionDetails = async (
  user_id,
  userorg_id,
  doc_id,
  token,
  page_no,
  language
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/invoiceextractiondetails`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id,
      page_no:String(page_no),
      ext_language:language
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    // console.log("____")
    // console.log(JSON.stringify(response))
    // console.log("____")

    const responseData = await response.json();
    // console.log(responseData)

    return responseData;
  } catch (error) {
    console.log("API request error:", error);
    throw error;
  }
};
export const DeleteDocumentDetails = async (
  user_id,
  userorg_id,
  doc_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/deletedocument`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const ChangeDocumentStatus = async (
  user_id,
  userorg_id,
  catagory_id,
  doc_id,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/changedocumentstatus`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id,
      catagory_id
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const UpdateinvoicEextraction = async (
  user_id,
  userorg_id,
  doc_id,
  meta_id,
  meta_key,
  search_key,
  meta_value,
  token
) => {
  const BASE_URL = `${window.constants.api_url}/admin-console/updateinvoiceextraction`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      doc_id,
      meta_id,
      meta_key,
      search_key,
      meta_value,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const getAllDoccategory = async (user_id, userorg_id, token) => {
  try {
    const apiEndpoint = "admin-console/viewcategory";
    const API_URL = `${window.constants.api_url}/${apiEndpoint}`;

    const requestData = {
      org_id: userorg_id,
      user_id,
      start_index: 0,
      limit: 15,
    };

    const response = await fetch(API_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};
export const setDocumentOrder = async (
  user_id,
  userorg_id,
  category_id,
  order,
  token
) => {
  const apiEndpoint = "admin-console/setdocumenttypeorder";
  const BASE_URL = `${window.constants.api_url}/${apiEndpoint}`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      category_id: category_id,
      order: order,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};

export const showCategory = async (
  user_id,
  userorg_id,
  item_id,
  token
) => {
  const apiEndpoint = "admin-console/showcategory";
  const BASE_URL = `${window.constants.api_url}/${apiEndpoint}`;

  try {
    const requestData = {
      user_id,
      org_id: userorg_id,
      item_id: item_id,
    };

    const response = await fetch(BASE_URL, {
      method: "POST",
      headers: {
        token: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
};