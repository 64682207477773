import React, { useState, useEffect, useRef } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import styles from "./InvoiceExtraction.module.css";
import AdminSidebar from "../Includes/AdminSidebar.jsx";
import Sidebar from "../../Includes/Sidebar.jsx";
import { EditText } from "react-edit-text";
import "react-edit-text/dist/index.css";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AssistantIcon from "@mui/icons-material/Assistant";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import WarningIcon from "@mui/icons-material/Warning";
import { BeatLoader, RingLoader, MoonLoader } from "react-spinners";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Chip from "@mui/material/Chip";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ReplayIcon from "@mui/icons-material/Replay";

// import Typography from '@mui/material/Typography';
// import Box from '@mui/material/Box';
import {
  SearchinvoiceExtDetails,
  invoiceExtractionDetails,
  UpdateinvoicEextraction,
  DeleteDocumentDetails,
  getAllDocumentType,
  ChangeDocumentStatus,
  getAllDoccategory,
  LoadSearchinvoiceExtDetails,
} from "../AdminConsoleApiHelper/AdminApiHelper.js";
import { getAllCategories } from "../../ApiHelper/Profile.js";
import {
  Box,
  Grid,
  Menu,
  Button,
  MenuItem,
  ListItemIcon,
  Select,
  InputLabel,
  FormControl,
  IconButton,
  TableContainer,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Dialog,
  DialogContent,
  DialogTitle,
  CircularProgress,
  DialogActions,
  TextField,
  Popover,
  Card,
  CardContent,
  ListItem,
  List,
  DialogContentText,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import Slider from "@mui/material/Slider";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import FilterListIcon from "@mui/icons-material/FilterList";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import invoice_img from "../../../invoice.png";
import DateFilter from "./DateFilter.jsx";
import ListItemText from "@mui/material/ListItemText";
import { documentPreview } from "../../ApiHelper/Profile.js";
import EditIcon from "@mui/icons-material/Edit";
import InfiniteScroll from "react-infinite-scroll-component";
import DataTable from "./dataTable.jsx";
function valuetext(value) {
  return `${value}`;
}

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

// CustomTabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.number.isRequired,
//   value: PropTypes.number.isRequired,
// };

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const InvoiceExtraction = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [docid, setdocid] = useState("");
  const [itemid, setitemid] = useState("");
  const [confidencecolor, setconfidencecolor] = useState("");
  const [userstatus, setuserstatus] = useState("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [deleteloader, setdeleteloader] = useState(false);
  const [statusloader, setstatusloader] = useState(false);
  const [Selectedkey, setSelectedkey] = useState(null);
  const { windows } = props;
  const [totalrecord, settotalrecord] = useState("");
  const [age, setAge] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [menuVisibilities, setMenuVisibilities] = useState({});
  const [index, setIndex] = useState(0);
  const [searchResults, setSearchResults] = useState([]);
  const [detailsResults, setdetailsResults] = useState([]);
  const [extractionResults, setextractionResults] = useState([]);
  const [viewDetailsModalOpen, setviewDetailsModalOpen] = useState(false);
  const [doclink, setdoclink] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [editedField, setEditedField] = useState(null);
  const [loading, setLoading] = useState(true);
  const [StaticDocType, setStaticDocType] = useState([]);
  const [value, setValue] = React.useState([0, 100]);
  const [datelist, setDatelist] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [todate, settodate] = useState("");
  const [DocTypeBtnid, setDocTypeBtnid] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [editedValue, setEditedValue] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedKey, setSelectedKey] = useState("");
  const [editindex, seteditindex] = useState("");
  const [parentkey, setparentkey] = useState("");
  const [editmeta_id, seteditmeta_id] = useState("");
  const [editdoc_id, seteditdoc_id] = useState("");
  const [itemaccountid, setitemaccountid] = useState("");
  const [dataModified, setDataModified] = useState(false);
  const [loadingIcons, setLoadingIcons] = useState({});
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null); // Use a different name for the state variable
  const [status, setStatus] = useState("");
  const [statusClicked, setStatusClicked] = useState(false);
  const [confidencePopover, setConfidencePopover] = useState(null);
  const [confidenceClicked, setConfidenceClicked] = useState(false);
  const [getItemid, setgetItemid] = useState("");
  const [Tabvalue, setTabValue] = React.useState(0);
  const [tableData, seveTableData] = useState([]);

  const [categoryData,setCategoryData]=useState([])
  const [category, setCategory] = React.useState('');
  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };
  const handleClickMenu = (
    event,
    docid,
    item_id,
    user_status,
    confidencecolor
  ) => {
    setAnchorEl(event.currentTarget);
    setitemid(item_id);
    setconfidencecolor(confidencecolor);
    setdocid(docid);
    setuserstatus(user_status);
    console.log(docid);
    setCurrentLanguage("English");
    // setAnchorEl (null);
    // setTempIsSuperAdmin(user.superadmin)
  };

  const handleClosee = () => {
    setAnchorEl(null);
  };
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [confirmationAction, setConfirmationAction] = useState(null);

  // const [deleteId, setDeleteId] = useState(null);

  const [itemId, setItemId] = useState(null);

  const handleConfirmAction = (action, id) => {
    setConfirmationAction(action);
    setItemId(id);
    setOpenConfirmation(true);
  };
  const handleGetAllCategories=async()=>{
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllCategories(
        userToken,
        user_id,
        userorg_id,
      );

      if (res.code === "200") {
        // setCategoryData()
        setCategoryData(res.data)
        // if(res.data.length>0){
        //   setCategory(res.data[0].name)
        // }
      } else {
        // Handle the error
        setOpenSnackbar(true);
        setSnackbarMessage(res.message);
        // console.error("Error:", res.message);
      }
    } catch (error) {
      setOpenSnackbar(true);
      setSnackbarMessage("Server Error");
      console.error("Error:", error);
    }
  }
  const handleExecuteAction = () => {
    setOpenConfirmation(false);
    switch (confirmationAction) {
      case "reprocess":
        handlechangeStatus(itemId);
        break;
      case "delete":
        handleDelete(itemId);
        break;
      default:
        break;
    }
  };

  const handleCancelAction = () => {
    setOpenConfirmation(false);
    setConfirmationAction(null);
    setItemId(null);
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const openviewDetailsModal = () => {
    setviewDetailsModalOpen(true);
  };
  const closeviewDetailsModal = () => {
    setviewDetailsModalOpen(false);
  };
  const handleChange = (event) => {
    setAge(event.target.value);
  };
  const dateRef = useRef();
  const Loader = () => (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        // backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <CircularProgress />
    </div>
  );
  const handleClose = () => {
    setAge(""); // Reset the selected value
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const GetDocTypeData = async () => {
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await getAllDoccategory(user_id, userorg_id, userToken);

      // console.log(res)
      if (res.code === "200") {
        console.log(res.data);
        setStaticDocType(res.data);
      } else {
        // Handle the error
        console.error("Error:");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleLoadMore = async () => {
    setLoading(false);
    setLoadingMore(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      setIndex(searchResults.length + 10);
      const data = await LoadSearchinvoiceExtDetails(
        deepuser_id,
        deeporg_id,
        DocTypeBtnid,
        datelist,
        fromDate,
        "",
        value,
        searchQuery,
        status,
        userToken,

        searchResults.length
      );

      if (data.code === "200") {
        setSearchResults((prevResults) => [...prevResults, ...data.data]);
        settotalrecord(data.total_docs);
        //  console.log(data.data.length > 0)
        setLoadingMore(false);
        // setHasMore(data.data.length > 0);
        if (data.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const ConfidenceApply = () => {
    setConfidenceClicked(true);
    setConfidencePopover(false);
    fetchExtractionDetails();
  };
  const ConfidenceApplyRevert = () => {
    setConfidenceClicked(false);
    setConfidencePopover(false);
  };
  const handleconfidencePopoverClick = (event) => {
    // if (confidenceClicked === false)
    setConfidencePopover(event.currentTarget);
  };
  const confidencePopoverClose = () => {
    setConfidencePopover(null);
    setValue([0, 100]);
  };
  const openConfidencePopover = Boolean(confidencePopover);
  const confidenceId = openConfidencePopover ? "simple-popover" : undefined;

  const [documentTypePopover, setDocumentTypePopover] = useState(null);
  const [docTypeClicked, setDocTypeClicked] = useState(false);
  const [docTypeBtnText, setDocTypeBtnText] = useState("");
  const [currentLanguage, setCurrentLanguage] = useState("English");
  const [totalPageNo, setTotalPageNo] = useState(1);
  const [pageno, setpageno] = useState(1);
  const [totalInvoicevalueafterdiscountExcludingVAT,settotalInvoicevalueafterdiscountExcludingVAT]=useState(0)
  const DocTypeApply = (e, id, name) => {
    setDocTypeClicked(true);
    setDocumentTypePopover(false);
    setDocTypeBtnText(name);
    setDocTypeBtnid(id);
    console.log("text:", docTypeBtnText);
    fetchExtractionDetails(status, id);
  };
  const DocTypeApplyRevert = () => {
    setDocTypeClicked(false);
    setDocumentTypePopover(null);

    setDocTypeBtnText("Category");
    setDocTypeBtnid("");
  };
  const handledocumentTypePopoverClick = (event) => {
    // if (docTypeClicked === false)
    setDocumentTypePopover(event.currentTarget);
  };
  const documentTypePopoverClose = () => {
    setDocumentTypePopover(null);
    setDocTypeBtnid("");
  };

  const openDocumentTypePopover = Boolean(documentTypePopover);
  const documentTypeId = openDocumentTypePopover ? "simple-popover" : undefined;

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleTextFieldChange = (index, newValue) => {
    // Update the value array based on the changed TextField
    const updatedValue = [...value];
    updatedValue[index] = newValue;
    setValue(updatedValue);
  };

  const handleTextFieldBlur = (index) => {
    // Ensure the value is within the valid range
    const updatedValue = [...value];
    updatedValue[index] = Math.min(Math.max(updatedValue[index], 0), 100);
    setValue(updatedValue);
  };

  const [orderAmtIconChange, setOrderAmtIconChange] = useState(false);
  const [orderAmtValueChange, setOrderAmtValueChange] =
    useState("order amount");
  const handleOrderAmtChange = (e, userIndex, entityIndex, dataKey) => {
    const { value } = e.target;

    const updatedDetailsResults = detailsResults.map((user, index) => {
      if (index === userIndex) {
        return {
          ...user,
          extraction_data: user.extraction_data.summary.map((entityItem, i) => {
            if (i === entityIndex) {
              const updatedEntityItem = { ...entityItem };
              Object.keys(updatedEntityItem).forEach((key) => {
                if (key === dataKey) {
                  updatedEntityItem[key].value = value;
                }
              });
              return updatedEntityItem;
            }
            return entityItem;
          }),
        };
      }
      return user;
    });

    setdetailsResults(updatedDetailsResults);
  };

  const handleSave = async () => {
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;
      const metaDataKey = `'${parentkey}'[${editindex}].'${selectedKey}'`;
      const SearchDataKey = `'${selectedKey}'[${editindex}]`;
      const responseData = await UpdateinvoicEextraction(
        user_id,
        userorg_id,
        editdoc_id,
        editmeta_id,
        metaDataKey, // Assuming you want to pass the selected key as meta_key
        SearchDataKey,
        editedValue,
        userToken
      );

      if (responseData.code === "200") {
        setOpenDialog(false);
        setSelectedKey("");
        setEditedValue("");
        seteditindex("");
        setparentkey("");
        seteditmeta_id("");
        seteditdoc_id("");
        console.log(responseData.data);
        handleViewupdate(editdoc_id);
      } else {
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [viewDtailsPage, setViewDetailsPage] = useState(false);

  const handleViewDetails = async (docid, item, langauge) => {
    setAnchorEl(null);
    setgetItemid(itemid);
    setLoadingIcons((prevState) => ({
      ...prevState,
      [itemid]: true,
    }));
    //  console.log(itemid);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        pageno,
        langauge
      );
        // console.log(responseData)
      if (responseData.code === "200") {
        setLoadingIcons((prevState) => ({
          ...prevState,
          [itemid]: false,
        }));

        // #TODO
        if(responseData.data[0].extraction_data.summary.length==0){
          setSnackbarMessage("No Extraction Data Found")
          setOpenSnackbar(true)
          return 
        }
        const accountid = responseData.account_id;
        handleRowClick(accountid, itemid, langauge);
        setViewDetailsPage(true);
        setitemaccountid(accountid);
        setdetailsResults(responseData.data);
        setTotalPageNo(responseData.data[0].total_pages);
        // console.log("TOTAL PAGE NO.",responseData.data[0].total_pages)
        setextractionResults(responseData.data[0].extraction_data.summary);
        seveTableData(responseData.data[0].extraction_data.table);
        // console.log(responseData.data[0].extraction_data[0].key);
        try{
          for (const [key, value] of Object.entries(
            responseData.data[0].extraction_data.summary[0]
          )) {
            if (value.length > 0) {
              setSelectedkey(key);
            }
          }
        }
        catch{
          console.log("NO DATA")
        }
        
        // for(let i=0;i<responseData.data[0].extraction_data.summary[0],keys();i++){
        //   if(responseData.data[0].extraction_data.summary[i].length>0){
        //   }
        // }
        //  setSelectedkey(extractionResults.extraction_data[0])
        // console.log("tapan:", responseData.data[0].extraction_data);

        // handleRowClick(accountid, itemid);
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.log("Error:",error);
    }
  };
  const handleViewupdate = async (docid) => {
    // console.log(itemid);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        currentLanguage
      );

      if (responseData.code === "200") {
        const accountid = responseData.account_id;
        setdetailsResults(responseData.data);
        setextractionResults(responseData.data[0].extraction_data);
        // console.log('tapan:',responseData.data[0].extraction_data);
        setViewDetailsPage(true);
        setitemaccountid(accountid);
        handleRowClick(accountid, getItemid);
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDelete = async (docid) => {
    // console.log(itemid);
    setdeleteloader(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await DeleteDocumentDetails(
        user_id,
        userorg_id,
        docid,
        userToken
      );

      if (responseData.code === "200") {
        setdeleteloader(false);
        fetchExtractionDetails();
        handleClosee();
        // const accountid = responseData.account_id;
        // setdetailsResults(responseData.data);
        // setextractionResults(responseData.data[0].extraction_data);
        setSnackbarMessage(responseData.message);
        setOpenSnackbar(true);
        // setViewDetailsPage(true);
        // setitemaccountid(accountid);
        //  handleRowClick(accountid,getItemid)
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getcategoryindex=()=>{
    for(let i=0;i<categoryData.length;i++){
      if(category==categoryData[i].name){
        return categoryData[i].id
      }
    }
  }
  const handlechangeStatus = async (docid) => {
    // console.log(itemid);
    setstatusloader(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await ChangeDocumentStatus(
        user_id,
        userorg_id,
        getcategoryindex(),
        docid,
        userToken
      );

      if (responseData.code === "200") {
        setstatusloader(false);
        fetchExtractionDetails();
        handleClosee();
        // const accountid = responseData.account_id;
        // setdetailsResults(responseData.data);
        // setextractionResults(responseData.data[0].extraction_data);
        setSnackbarMessage(responseData.message);
        setOpenSnackbar(true);
        // setViewDetailsPage(true);
        // setitemaccountid(accountid);
        //  handleRowClick(accountid,getItemid)
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlebacknavigation = () => {
    setTabValue(0);
    setpageno(1);
    setViewDetailsPage(false);
    GetDocTypeData();
    fetchExtractionDetails();
  };

  const handleApplydatecustom = async ({ fromDateval, toDateval }) => {
    // setDatelist(selectedValue);
    settodate(toDateval);
    setFromDate(fromDateval);
    console.log("Received toDateval:", toDateval);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      setLoading(true);
      const responseData = await SearchinvoiceExtDetails(
        user_id,
        userorg_id,
        DocTypeBtnid,
        datelist,
        fromDateval,
        toDateval,
        value,
        searchQuery,
        status,
        userToken
      );

      if (responseData.code === "200") {
        setLoading(false);
        setSearchResults(responseData.data);
        settotalrecord(responseData.total_docs);
        if (responseData.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        console.log(responseData.data); // This contains the master data for the modal dropdown
      } else {
        setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleApplydate = async (selectedValue) => {
    setDatelist(selectedValue);

    console.log(selectedValue);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      setLoading(true);
      const responseData = await SearchinvoiceExtDetails(
        user_id,
        userorg_id,
        DocTypeBtnid,
        selectedValue,
        "",
        "",
        value,
        searchQuery,
        status,
        userToken
      );

      if (responseData.code === "200") {
        setLoading(false);
        setSearchResults(responseData.data);
        settotalrecord(responseData.total_docs);
        if (responseData.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    handleGetAllCategories()
    GetDocTypeData();
    fetchExtractionDetails();
  }, []);
  function disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  function enableBodyScroll() {
    document.body.style.overflow = "auto";
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    disableBodyScroll();

    // Return a cleanup function to enable body scroll when the component unmounts
    return () => {
      enableBodyScroll();
    };
  }, []);

  const handleDataPagination = async (direction) => {
    // setdoclink("")
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;
    if (direction === "left") {
      if (pageno == 1) {
        return;
      }
      setpageno(pageno - 1);
      handleRowClick(itemaccountid, getItemid, pageno - 1);

      try {
        const responseData = await invoiceExtractionDetails(
          user_id,
          userorg_id,
          docid,
          userToken,
          pageno - 1,
          currentLanguage
        );

        if (responseData.code === "200") {
          setLoadingIcons((prevState) => ({
            ...prevState,
            [itemid]: false,
          }));

          // #TODO
          const accountid = responseData.account_id;
          setdetailsResults(responseData.data);
          setextractionResults(responseData.data[0].extraction_data.summary);
          seveTableData(responseData.data[0].extraction_data.table);
          // console.log(responseData.data[0].extraction_data[0].key);
          //  setSelectedkey(extractionResults.extraction_data[0])
          // console.log("tapan:", responseData.data[0].extraction_data);
          setViewDetailsPage(true);
          setitemaccountid(accountid);
          if (responseData.data[0].total_pages) {
            setTotalPageNo(responseData.data[0].total_pages);
          }
          for (const [key, value] of Object.entries(
            responseData.data[0].extraction_data.summary[0]
          )) {
            if (value.length > 0) {
              setSelectedkey(key);
            }
          }
          // setSelectedkey(Object.keys(responseData.data[0].extraction_data.summary[0])[0]);
          // handleRowClick(accountid, itemid);
        } else {
          // setLoading(false);
          console.error("Error:", responseData.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    if (direction === "right") {
      if (pageno == totalPageNo) {
        return;
      }
      setpageno(pageno + 1);
      handleRowClick(itemaccountid, getItemid, pageno + 1);
      try {
        const responseData = await invoiceExtractionDetails(
          user_id,
          userorg_id,
          docid,
          userToken,
          pageno + 1,
          currentLanguage
        );

        if (responseData.code === "200") {
          setLoadingIcons((prevState) => ({
            ...prevState,
            [itemid]: false,
          }));

          // #TODO
          const accountid = responseData.account_id;
          setdetailsResults(responseData.data);
          setextractionResults(responseData.data[0].extraction_data.summary);
          seveTableData(responseData.data[0].extraction_data.table);
          // console.log(responseData.data[0].extraction_data[0].key);
          //  setSelectedkey(extractionResults.extraction_data[0])
          // console.log("tapan:", responseData.data[0].extraction_data);
          setViewDetailsPage(true);
          setitemaccountid(accountid);
          if (responseData.data[0].total_pages) {
            setTotalPageNo(responseData.data[0].total_pages);
          }
          for (const [key, value] of Object.entries(
            responseData.data[0].extraction_data.summary[0]
          )) {
            if (value.length > 0) {
              setSelectedkey(key);
            }
          }
          // setSelectedkey(Object.keys(responseData.data[0].extraction_data.summary[0])[0]);
          // handleRowClick(accountid, itemid);
        } else {
          // setLoading(false);
          console.error("Error:", responseData.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const handleDataPaginationByPageNo = async (page_no) => {
    // setdoclink("")
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    setpageno(page_no);
    handleRowClick(itemaccountid, getItemid, page_no);

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        page_no,
        currentLanguage
      );

      if (responseData.code === "200") {
        setLoadingIcons((prevState) => ({
          ...prevState,
          [itemid]: false,
        }));

        // #TODO
        const accountid = responseData.account_id;
        setdetailsResults(responseData.data);
        setextractionResults(responseData.data[0].extraction_data.summary);
        seveTableData(responseData.data[0].extraction_data.table);
        // console.log(responseData.data[0].extraction_data[0].key);
        //  setSelectedkey(extractionResults.extraction_data[0])
        // console.log("tapan:", responseData.data[0].extraction_data);
        setViewDetailsPage(true);
        setitemaccountid(accountid);
        if (responseData.data[0].total_pages) {
          setTotalPageNo(responseData.data[0].total_pages);
        }
        for (const [key, value] of Object.entries(
          responseData.data[0].extraction_data.summary[0]
        )) {
          if (value.length > 0) {
            setSelectedkey(key);
          }
        }
        // setSelectedkey(Object.keys(responseData.data[0].extraction_data.summary[0])[0]);
        // handleRowClick(accountid, itemid);
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowClick = async (accountid, itemid, page_no) => {
    // Navigate to the desired location when a row is clicked
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await documentPreview(
        userToken,
        user_id,
        userorg_id,
        accountid,
        itemid,
        page_no
      );

      if (res.code === "200") {
        setLoading(false);
        setdoclink(res.doc_link);
        // console.log(doclink)
        // window.open(res.doc_link, '_blank');
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleKeyClick = (language) => {
    // setSelectedkey(key);
    setCurrentLanguage(language);
    handleViewDetails(docid, itemId, language);
    // Add any additional logic you need when a key is clicked
  };
  const handleLanguageClick = async (language) => {
    setCurrentLanguage(language);
    setAnchorEl(null);
    setgetItemid(itemid);
    setLoadingIcons((prevState) => ({
      ...prevState,
      [itemid]: true,
    }));
    //  console.log(itemid);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        pageno,
        language
      );

      if (responseData.code === "200") {
        setLoadingIcons((prevState) => ({
          ...prevState,
          [itemid]: false,
        }));

        // #TODO
        const accountid = responseData.account_id;
        // handleRowClick(accountid, itemid);
        setViewDetailsPage(true);
        setitemaccountid(accountid);
        setdetailsResults(responseData.data);
        setTotalPageNo(responseData.data[0].total_pages);
        // console.log("TOTAL PAGE NO.",responseData.data[0].total_pages)
        setextractionResults(responseData.data[0].extraction_data.summary);
        seveTableData(responseData.data[0].extraction_data.table);
        // console.log(responseData.data[0].extraction_data[0].key);
        for (const [key, value] of Object.entries(
          responseData.data[0].extraction_data.summary[0]
        )) {
          if (value.length > 0) {
            setSelectedkey(key);
          }
        }
        // for(let i=0;i<responseData.data[0].extraction_data.summary[0],keys();i++){
        //   if(responseData.data[0].extraction_data.summary[i].length>0){
        //   }
        // }
        //  setSelectedkey(extractionResults.extraction_data[0])
        // console.log("tapan:", responseData.data[0].extraction_data);

        // handleRowClick(accountid, itemid);
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };
  const [isDisabled, setIsDisabled] = useState(false);
  const handleInputChange = (
    section,
    invoiceIndex,
    field,
    value,
    editdoc_id,
    metaid,
    page_no
  ) => {
    seteditindex(invoiceIndex);
    setEditedValue(value);
    setIsDisabled(true);
    // setTimeout(() => {
    setSelectedKey(field);
    setparentkey(section);
    seteditdoc_id(editdoc_id);
    seteditmeta_id(metaid);
    setpageno(page_no);
    //handleRowClick(accountid, editdoc_id);

    setextractionResults((prevValues) => {
      const newValues = JSON.parse(JSON.stringify(prevValues)); // Deep clone the array to avoid mutation
      newValues[0][section][invoiceIndex][field].value = value;

      return newValues;
    });
    setDataModified(true);
    //   setIsDisabled(false);
    // }, 3000);
  };

  const handleInput = (page_no) => {
    setpageno(page_no);
    //handleRowClick(accountid, editdoc_id);
    handleRowClick(itemaccountid, getItemid, page_no);
  };
  useEffect(() => {
    let timer;
    // Call handleSave() only when dataModified is true
    if (dataModified) {
      // Set a timer for 1 second delay
      timer = setTimeout(() => {
        handleSave();
        setDataModified(false); // Reset dataModified after saving
      }, 5000);
    }

    // Clean up the timer if component unmounts or dataModified changes
    return () => clearTimeout(timer);
  }, [extractionResults]);

  const handleClearButtonClick = () => {
    const tableContainer = tableContainerRef.current;
    if (tableContainer) {
      tableContainer.scrollTo(0, 0); // Scroll the table container to the top
    }
    const textField = document.getElementById("searchTextField");
    if (textField) {
      textField.value = ""; // Set the input field value to an empty string
    }
    settodate("");
    setFromDate("");
    setDocTypeBtnid("");
    setDatelist("");
    setValue([0, 100]);
    ConfidenceApplyRevert();
    DocTypeApplyRevert();
    StatusApplyRevert();
    setStatus("");
    dateRef.current.handleResetClick();
    setSearchQuery("");

    clearfetchExtractionDetails();
  };
  const ClearButtonClick = () => {
    settodate("");
    setFromDate("");

    setDatelist("");
    // clearfetchExtractionDetails();
  };
  const clearfetchExtractionDetails = async (id) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;
    const typeId = id || DocTypeBtnid;
    try {
      setLoading(true);
      const responseData = await SearchinvoiceExtDetails(
        user_id,
        userorg_id,
        "",
        "",
        "",
        "",
        [0, 100],
        "",
        "",
        userToken
      );

      if (responseData.code === "200") {
        setLoading(false);
        setSearchResults(responseData.data);
        settotalrecord(responseData.total_docs);
        if (responseData.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStatusPopoverClick = (event) => {
    setPopoverAnchorEl(event.currentTarget); // Use the new state variable
  };

  const handleClosestatus = () => {
    setPopoverAnchorEl(null); // Use the new state variable
    setStatus("");
  };

  const handleStatusSelect = (selectedStatus) => {
    setStatus(selectedStatus);
    setStatusClicked(true);
    setPopoverAnchorEl(null); // Use the new state variable
    fetchExtractionDetails(selectedStatus);
  };

  const StatusApplyRevert = () => {
    setStatusClicked(false);
    setStatus("status");
  };

  const openid = Boolean(popoverAnchorEl); // Use the new state variable
  const statusid = openid ? "status-popover" : undefined;

  const fetchExtractionDetails = async (selectedStatus, id) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;
    const typeId = id || DocTypeBtnid;
    const statusselected = selectedStatus || status;
    try {
      setLoading(true);
      const responseData = await SearchinvoiceExtDetails(
        user_id,
        userorg_id,
        typeId,
        datelist,
        fromDate,
        todate,
        value,
        searchQuery,
        statusselected,
        userToken
      );

      if (responseData.code === "200") {
        setLoading(false);
        setSearchResults(responseData.data);
        settotalrecord(responseData.total_docs);

        if (responseData.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const tableContainerRef = useRef(null);
  // useEffect(() => {
  //   const tableContainer = tableContainerRef.current;

  //   // Check if tableContainerRef.current is not null before adding event listener
  //   if (tableContainer) {
  //     const handleScroll = () => {
  //       // Check if the user has scrolled to the bottom of the table container
  //       if (
  //         tableContainer.scrollTop + tableContainer.clientHeight >=
  //         tableContainer.scrollHeight
  //       ) {
  //         // Trigger the load more function when scrolled to the bottom
  //         handleLoadMore();
  //       }
  //     };

  //     // Add scroll event listener to the table container
  //     tableContainer.addEventListener("scroll", handleScroll);

  //     // Check if tableContainerRef.current is not null before adding event listener
  //     //     if (tableContainer) {
  //     //       const handleScroll = () => {
  //     //         // Check if the user has scrolled to the bottom of the table container
  //     //         if (
  //     //           tableContainer.scrollTop + tableContainer.clientHeight >=
  //     //           tableContainer.scrollHeight
  //     //         ) {
  //     //           // Trigger the load more function when scrolled to the bottom
  //     //           handleLoadMore();
  //     //         }
  //     //       };

  //     //       // Add scroll event listener to the table container
  //     //       tableContainer.addEventListener("scroll", handleScroll);

  //     return () => {
  //       // Remove scroll event listener on component unmount
  //       tableContainer.removeEventListener("scroll", handleScroll);
  //     };
  //   }
  // }, [handleLoadMore, tableContainerRef]); // Ensure this effect runs only once, similar to componentDidMount

  const callDerive=()=>{
    console.log("Sdf")
  }

  return (
    <>
      <Box
        component="main"
        sx={{
          display: "flex",
          backgroundColor: "#EFEDF1",
          flexDirection: "column",
          minHeight: "100vh",
        }}
      >
        {viewDtailsPage ? (
          <Box
            sx={{
              textAlign: "left",
              marginLeft: "110px",
              paddingLeft: "20px",
              paddingTop: "100px",
              paddingRight: "20px",
              height: "calc(100vh - 20px)",
              overflowY: "auto",
            }}
          >
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ color: "#005AC1", marginTop: "2px" }}>
                  {" "}
                  <ArrowBackIcon
                    sx={{ fontSize: 20, cursor: "pointer" }}
                    onClick={handlebacknavigation}
                  />
                </Box>
                <Box sx={{ marginTop: "2px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#005AC1",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={handlebacknavigation}
                  >
                    Go back
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              {detailsResults.map((user, index) => (
                <Box
                  sx={{
                    // marginLeft: "20px",
                    marginRight: "20px",

                    padding: "15px",
                    width: "50vw",
                    minWidth: "600px",
                    borderRadius: "8px",
                    backgroundColor: "white",
                  }}
                >
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={Tabvalue}
                        onChange={handleTabChange}
                        aria-label="basic tabs example"
                      >
                        <Tab label="Summary" {...a11yProps(0)} />
                        <Tab label="Tables" {...a11yProps(1)} />
                        {/* <Tab label="Tab 3" {...a11yProps(2)} />
                        <Tab label="Tab 4" {...a11yProps(3)} /> */}
                      </Tabs>
                    </Box>
                    <CustomTabPanel value={Tabvalue} index={0}>
                      <Box
                        sx={{
                          padding: "12px",
                          borderRadius: "8px",
                          marginBottom: "8px",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "13px" }}
                          >
                            {user.doc_name}
                          </Typography>
                        </Box>

                        <Box key={index} sx={{ marginTop: "8px" }}>
                          <Typography sx={{ fontSize: "12px" }}></Typography>
                          {user.processing_date && (
                            <Typography sx={{ fontSize: "12px" }}>
                              Processing Date: {user.processing_date}
                            </Typography>
                          )}

                          {/* {confidencecolor} */}
                          {/* {user.confidence && (
                            <Typography
                              sx={{ fontSize: "12px", color: confidencecolor }}
                            >
                              Confidence: {user.confidence}
                            </Typography>
                          )} */}
                          {user.category_name && (
                            <Typography sx={{ fontSize: "12px" }}>
                              Category Name: {user.category_name}
                            </Typography>
                          )}
                          {Object.keys(user.extraction_data.summary[0])[0] && (
                            <Typography sx={{ fontSize: "12px" }}>
                              Doc Type:{" "}
                              {Object.keys(user.extraction_data.summary[0])[0]}
                            </Typography>
                          )}
                          {user.extracted_page_no && (
                            <Typography sx={{ fontSize: "12px" }}>
                              Extracted Pages:{" "}
                              {user.extracted_page_no
                                .split(",")
                                .map((pages, idx) => (
                                  <Chip
                                    style={{
                                      margin: "3px",
                                      background:
                                        pages == pageno ? "lightblue" : "none",
                                    }}
                                    label={pages}
                                    variant="outlined"
                                    onClick={() =>
                                      handleDataPaginationByPageNo(pages)
                                    }
                                  />
                                ))}
                            </Typography>
                          )}
                        </Box>
                      </Box>
                      <Box>
                        {extractionResults.length == 0 && (
                          <p>No data extracted from this page</p>
                        )}
                        <Box>
                          {extractionResults.map((entityItem, entityIndex) => (
                            <Box
                              key={entityIndex}
                              sx={{
                                width: "100%",
                                marginLeft: "10px",
                                marginTop: entityIndex > 0 ? "10px" : 0,
                                marginBottom: "10px",
                              }}
                            >
                              <div
                                style={{
                                  height: "calc(100vh - 200px)",
                                  overflowY: "auto",
                                  padding: "10px",
                                  paddingBottom: "20px",
                                }}
                              >
                                <div className={styles.categories}>
                                  <Box
                                    style={{
                                      display: "flex",
                                      overflowX: "auto",
                                      overflow: "hidden",
                                      marginBottom: "30px",
                                      marginTop: "10px",
                                      marginLeft: "-15px",
                                    }}
                                  >
                                    {/* Render your categories here */}
                                  </Box>
                                  {user.extraction_data.summary.map(
                                    (entityItem, entityIndex) =>
                                      user.extracted_language
                                        .split(",")
                                        .map((key, tabIndex) => (
                                          // <></>
                                          <Box
                                            key={tabIndex}
                                            className={`${styles.inputChip} ${
                                              currentLanguage === key
                                                ? styles.selectedCategory
                                                : ""
                                            }`}
                                            onClick={() =>
                                              handleLanguageClick(key)
                                            }
                                          >
                                            {/* {extractionResults.key.length>0 && */}
                                            {key}
                                            {/* } */}
                                          </Box>
                                        ))
                                  )}
                                </div>

                                <div>
                                  {user.extraction_data.summary.map(
                                    (entityItem, entityIndex) =>
                                    Object.keys(entityItem).map(
                                      (key) =>
                                     
                                          Selectedkey === key && (
                                            <div key={entityIndex}>
                                              {entityItem[key].map(
                                                (data, dataIndex) => 
                                                // console.log(data)
                                                
                                                // settotalInvoicevalueafterdiscountExcludingVAT("G")
                                                (
                                               
                                                  <List
                                                    key={dataIndex}
                                                    sx={{
                                                      width: "100%",
                                                      bgcolor:
                                                        "background.paper",
                                                    }}
                                                    style={{
                                                      margin: "0px",
                                                      padding: "0px",
                                                    }}
                                                  >
                                                    
                                                    {Object.keys(data).map(
                                                      (dataKey) => (
                                                        <>
                                                                                                                <ListItem
                                                          sx={{
                                                            flex: 1,
                                                            flexDirection:
                                                              "row" /*it was column*/,
                                                            maring: "0px",
                                                          }}
                                                          style={{
                                                            margin: "0px",
                                                            padding: "0px",
                                                          }}
                                                          key={dataKey}
                                                        >
                                                          <div style={{width:"600px"}}>
                                                          {dataKey}
                                                          </div>
                                                          {/* <ListItemText
                                                            sx={{
                                                              width:"240px"
                                                            }}
                                                            primary={dataKey}
                                                          /> */}
                                                          <div style={{width:"400px"}}
                                                          onClick={() =>
                                                            handleInput(
                                                              data[dataKey]
                                                                .page_no
                                                            )
                                                          }
                                                          
                                                          >
                                                           { data[dataKey].value=="Not visible" ?
                                                            ( "Not Available" ):
                                                            ( data[dataKey]
                                                              .value)
                                                                
                                                             
                                                            }
                                                          </div>
                                                          {/* <ListItemText
                                                            
                                                            noWrap
                                                            sx={{
                                                              justifyContent:
                                                                "start",
                                                              // minWidth: 3,
                                                              // maxWidth:130,
                                                              cursor: "pointer",
                                                            }}
                                                          >
                                                           
                                                            { data[dataKey].value=="Not visible" ?
                                                            ( "Not Available" ):
                                                            ( data[dataKey]
                                                              .value)
                                                                
                                                             
                                                            }
                                                            
                                                          </ListItemText> */}
                                                        </ListItem>
                                                          </>
                                                        
                                                      )
                                                    )}
                                                    {/* {dataIndex <
                                                entityItem[key].length - 1 && (
                                                <hr
                                                  style={{
                                                    margin: "10px 1px",
                                                    border: "0.5px solid #ccc",
                                                  }}
                                                />
                                              )} */}
                                                  </List>
                                                  //         <Typography key={dataIndex} sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                                                  //           {Object.keys(data).map((dataKey) => (
                                                  //             <div key={dataKey}>
                                                  //               <span>{dataKey} - <span style={{fontWeight: 'normal',}}>{data[dataKey].value}</span></span>

                                                  //             </div>
                                                  //           ))}
                                                  //           {dataIndex < entityItem[key].length - 1 && (
                                                  //   <hr style={{ margin: '10px 1px', border: '0.5px solid #ccc' }} />
                                                  // )}
                                                  //         </Typography>
                                                )
                                              )}
                                              
                                            </div>
                                          )
                                      )
                                  )}
                                </div>
                              </div>
                            </Box>
                          ))}
                        </Box>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={Tabvalue} index={1}>
                    {user.extracted_page_no && (
                            <Typography sx={{ fontSize: "14px" }}>
                              Extracted Pages:{" "}
                              {user.extracted_page_no
                                .split(",")
                                .map((pages, idx) => (
                                  <Chip
                                    style={{
                                      margin: "3px",
                                      background:
                                        pages == pageno ? "lightblue" : "none",
                                    }}
                                    label={pages}
                                    variant="outlined"
                                    onClick={() =>
                                      handleDataPaginationByPageNo(pages)
                                    }
                                  />
                                ))}
                            </Typography>
                          )}
                      {tableData.length > 0 && tableData[0] != null && (
                        <DataTable data={tableData[0]["value"]} />
                      )}
                    </CustomTabPanel>
                   
                  </Box>
                </Box>
              ))}
              <Box
                sx={{
                  width: "200vw",
                  minHeight: "100vh",
                  position: "relative",
                  overflow: "hidden",
                }}
              >
                {loading ? (
                  // Circular progress loader and "Please wait..." text
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      background: "rgba(255, 255, 255, 0.8)", // Add a semi-transparent background
                    }}
                  >
                    <CircularProgress />
                    <p>Please wait...</p>
                  </Box>
                ) : (
                  // If not loading, render the iframe
                  <div style={{ width: "100%", height: "100%" }}>
                    <iframe
                      style={{ width: "100%", height: "100%", border: "none" }}
                      src={doclink}
                      scrolling="no"
                    />
                    <div
                    // style={{
                    //   position: "fixed",
                    //   zIndex: "3000",
                    //   bottom: "10vh",
                    //   right: "15vw",
                    //   background: "white",
                    //   padding: "10px",
                    //   borderRadius: "10px",
                    // }}
                    >
                      {/* <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                        }}
                      >
                        <ChevronLeftIcon
                          onClick={() => handleDataPagination("left")}
                        />
                        <span>
                          {" "}
                          Page {pageno} / {totalPageNo}
                        </span>
                        <ChevronRightIcon
                          onClick={() => handleDataPagination("right")}
                        />
                      </div> */}
                    </div>
                  </div>
                )}
              </Box>
            </Box>
          </Box>
        ) : (
          <Box
            sx={{
              textAlign: "left",
              marginLeft: "131px",
              marginRight: "1px",
              marginTop: "100px",
            }}
          >
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                minHeight: "100vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // marginLeft: "12px",
                  // marginRight: "12px",
                  justifyContent: "space-between",
                  // background:"red"
                }}
              >
                <Box sx={{ display: "flex",flexDirection:"row",justifyContent:"space-between" }}>
                  <div style={{display:'flex',flexDirection:"row"}}>
                  <Box sx={{ marginLeft: "12px" }}>
                    <Button
                      onClick={handledocumentTypePopoverClick}
                      sx={
                        docTypeClicked
                          ? {
                              color: "#2B74E2",
                              backgroundColor: "#DBE2F9",
                              textTransform: "none",
                              borderRadius: "8px",
                              border:
                                "1px solid var(--M3-sys-light-outline, #2B74E2)",
                              marginRight: "15px",
                            }
                          : {
                              "&:hover": {
                                backgroundColor: "#EFEDF1", // Set the hover color
                              },
                              backgroundColor: "#EFEDF1",
                              color: "#74777F",
                              textTransform: "none",
                              borderRadius: "8px",
                              border:
                                "1px solid var(--M3-sys-light-outline, #74777F)",
                              marginRight: "15px",
                            }
                      }
                    >
                      <FilterListIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      {docTypeBtnText ? docTypeBtnText : "Category"}
                      {/* Document Type */}
                      {/* {docTypeClicked ? (
                      <IconButton
                     
                        style={{ padding: "0px 0px" }}
                      >
                     <CloseIcon
                onClick={(e) => {
                  e.stopPropagation(); // Stop event propagation
                  DocTypeApplyRevert(); // Call your close icon handler
                }}
                          sx={{
                            height: "15px",
                            width: "15px",
                            color: "#2B74E2",
                          }}
                        />
                      </IconButton>
                    ) : null} */}
                    </Button>
                  </Box>
                  <Box>
                    <DateFilter
                      ref={dateRef}
                      onApplycustomdate={handleApplydatecustom}
                      onApply={handleApplydate}
                      onClose={ClearButtonClick}
                    />
                  </Box>
                  <Box>
                    <Button
                      onClick={handleconfidencePopoverClick}
                      sx={
                        confidenceClicked
                          ? {
                              color: "#2B74E2",
                              backgroundColor: "#DBE2F9",
                              textTransform: "none",
                              borderRadius: "8px",
                              border:
                                "1px solid var(--M3-sys-light-outline, #2B74E2)",
                              marginRight: "15px",
                            }
                          : {
                              "&:hover": {
                                backgroundColor: "#EFEDF1", // Set the hover color
                              },
                              backgroundColor: "#EFEDF1",
                              color: "#74777F",
                              textTransform: "none",
                              borderRadius: "8px",
                              border:
                                "1px solid var(--M3-sys-light-outline, #74777F)",
                              marginRight: "15px",
                            }
                      }
                    >
                      <AssignmentTurnedInIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      Confidence
                      {/*    {confidenceClicked ? (
                      <IconButton
                        onClick={ConfidenceApplyRevert}
                        style={{ padding: "0px 0px" }}
                      >
                        <CloseIcon
                          sx={{
                            height: "15px",
                            width: "15px",
                            color: "#2B74E2",
                          }}
                        />
                      </IconButton>
                    ) : null} */}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={handleStatusPopoverClick}
                      sx={
                        statusClicked
                          ? {
                              color: "#2B74E2",
                              backgroundColor: "#DBE2F9",
                              textTransform: "none",
                              borderRadius: "8px",
                              border:
                                "1px solid var(--M3-sys-light-outline, #2B74E2)",
                              marginRight: "15px",
                            }
                          : {
                              "&:hover": {
                                backgroundColor: "#EFEDF1", // Set the hover color
                              },
                              backgroundColor: "#EFEDF1",
                              color: "#74777F",
                              textTransform: "none",
                              borderRadius: "8px",
                              border:
                                "1px solid var(--M3-sys-light-outline, #74777F)",
                              marginRight: "15px",
                            }
                      }
                    >
                      <AssistantIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />

                      {statusClicked ? status : "Status"}
                      {/* {statusClicked ? (
                      <IconButton
                        onClick={StatusApplyRevert}
                        style={{ padding: "0px 0px" }}
                      >
                        <CloseIcon
                          sx={{
                            height: "15px",
                            width: "15px",
                            color: "#2B74E2",
                          }}
                        />
                      </IconButton>
                    ) : null} */}
                    </Button>
                  </Box>
                  <Box>
                    <Button
                      onClick={() => fetchExtractionDetails()}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#EFEDF1", // Set the hover color
                        },
                        backgroundColor: "#EFEDF1",
                        color: "#74777F",
                        textTransform: "none",
                        borderRadius: "8px",
                        border:
                          "1px solid var(--M3-sys-light-outline, #74777F)",
                        marginRight: "15px",
                      }}
                    >
                      <ReplayIcon
                        sx={{
                          width: "20px",
                          height: "20px",
                          marginRight: "5px",
                        }}
                      />
                      Reload
                    </Button>
                  </Box>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "right",
                     
                    }}
                  >
                    <Box sx={{ display: "flex" }}>
                      <div>
                        <TextField
                          // id="searchTextField"
                          id="outlined-size-small"
                          defaultValue="Small"
                          size="small"
                          value={searchQuery}
                          onChange={handleSearchChange}
                          sx={{
                            "& .MuiInputBase-input": {},
                            marginRight: "10px",
                            // height:"1vh"
                          }}
                          placeholder="Search by file name"
                          InputProps={{
                            startAdornment: (
                              <IconButton
                                onClick={() => fetchExtractionDetails()}
                              >
                                <SearchIcon />
                              </IconButton>
                            ),
                          }}
                          onKeyDown={(e) => {
                            if (e.key === "Enter") {
                              e.preventDefault(); // Prevent default "Enter" behavior
                              e.stopPropagation();
                              fetchExtractionDetails();
                            }
                          }}
                        />
                      </div>
                      <Box>
                        <Button
                          variant="outlined"
                          // onClick={handleCreateUser}
                          sx={{ textTransform: "none", fontWeight: "bold" }}
                          onClick={handleClearButtonClick}
                        >
                          Clear All
                        </Button>
                      </Box>
                      {/* <Button variant="contained" startIcon={<SearchIcon />}
                                    // onClick={handleCreateUser}
                                    sx={{ textTransform: 'none', fontWeight: 'bold' }}
                                >
                                    File Name Search
                                </Button> */}
                    </Box>
                  </div>
                </Box>

                <Box>
                  {/* <Button
                    variant="outlined"
                    sx={{
                      textTransform: "none",
                      fontWeight: "bold",
                      marginRight: "15px",
                    }}
                    onClick={() => {
                      GetDocTypeData();
                      fetchExtractionDetails();
                    }}
                  >
                    Refresh
                  </Button> */}
                </Box>
                <Typography
                  className={styles.resultsFound}
                  sx={{
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    marginTop: "8px",
                    marginLeft: "12px",
                    color: "#74777F",
                  }}
                >
                  {totalrecord} {""} results found
                </Typography>

                <Box sx={{ width: "100%", marginBottom: "30px" }}>
                  {loading && <Loader />}

                  <TableContainer
                    ref={tableContainerRef}
                    component={Paper}
                    style={{
                      boxShadow:
                        "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",

                      maxHeight: "calc(100vh - 170px)",
                    }}
                  >
                    <Table
                      size="small"
                      sx={{ minWidth: 700 }}
                      aria-label="simple table"
                    >
                      <TableHead
                        sx={{
                          position: "sticky",
                          top: 0,
                          zIndex: "999",
                          backgroundColor: "#EFF1F3",
                        }}
                      >
                        <TableRow>
                          {/* <TableCell sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>SL. No.</TableCell> */}
                          {/* <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Sl No.</TableCell> */}
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            File Name
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            Category
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            Processing Date
                          </TableCell>
                          {/* <TableCell
                            align="left"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            Confidence
                          </TableCell> */}
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            PO Matching
                          </TableCell>
                          <TableCell
                            align="left"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            Status
                          </TableCell>
                          <TableCell
                            align="center"
                            sx={{
                              fontSize: "16px",
                              fontWeight: "700",
                              color: "#4A5568",
                            }}
                          >
                            View Details
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {/* {Loadingtable && (

                <TableCell colSpan={6} align="center" sx={{ width: '1000px' }}>
                  <CircularProgress />
                </TableCell>

              )} */}
                        {/* {!Loadingtable && ( */}
                        <>
                          {searchResults.map((user, index) => (
                            <TableRow key={index}>
                              {/* <TableCell component="th" scope="row">
                                                    {index + 1}
                                                </TableCell> */}
                              <TableCell>
                                <Box sx={{ display: "flex" }}>
                                  <Box>
                                    <PictureAsPdfIcon
                                      sx={{
                                        color: "red",
                                        marginRight: "8px",
                                        marginTop: "4px",
                                        width: "20px",
                                        height: "20px",
                                      }}
                                    />
                                  </Box>
                                  <Box
                                    sx={{ marginTop: "4px", marginLeft: "5px" }}
                                  >
                                    {user.doc_name}
                                  </Box>
                                </Box>
                              </TableCell>
                              <TableCell align="left">
                                {user.category_name}{" "}
                              </TableCell>
                              <TableCell align="left">
                                {user.processing_date}{" "}
                              </TableCell>

                              {/* <TableCell align="left">
                                <Box
                                  disabled
                                  style={{
                                    color: user.confidence_color,
                                    fontWeight: "bold",
                                    textTransform: "none",
                                  }}
                                >
                                  {user.confidence}{" "}
                                  {user.warning === "yes" && (
                                    <WarningAmberIcon
                                      fontSize="small"
                                      sx={{ color: "#eed202" }} // You can adjust the styling of the icon here
                                    />
                                  )}
                                </Box>
                              </TableCell> */}
                              <TableCell align="left">
                                {/* <Button

                                  disabled
                                  style={{
                                    color: "#27AE60",
                                    backgroundColor: "#27AE6029",
                                    borderBlockColor: "#27AE603D",
                                    borderRadius: "8px",
                                    width: "90px",
                                    fontWeight: "bold",
                                    textTransform: "none",
                                  }}
                                > */}
                                {user.po_matching_status}
                                {/* </Button> */}
                              </TableCell>
                              <TableCell align="left">
                                {user.status === "processed" && (
                                  // <Button
                                  //   disabled
                                  //   style={{
                                  //     color: "#27AE60",
                                  //     backgroundColor: "#27AE6029",
                                  //     borderBlockColor: "#27AE603D",
                                  //     borderRadius: "8px",
                                  //     width: "90px",
                                  //     fontWeight: "bold",
                                  //     textTransform: "none",
                                  //   }}
                                  // >
                                  <p>Processed</p>
                                  // </Button>
                                )}
                                {user.status === "processing" && (
                                  // <Button
                                  //   disabled
                                  //   style={{
                                  //     color: "#3366CC", // Text color
                                  //     backgroundColor: "#BBDEFB", // Lighter blue background color
                                  //     borderBlockColor: "#27AE603D",
                                  //     borderRadius: "8px",
                                  //     width: "90px",
                                  //     fontWeight: "bold",
                                  //     textTransform: "none",
                                  //   }}
                                  // >
                                  <p>Processing</p>
                                  // </Button>
                                )}

                                {user.status === "failed" && (
                                  // <Button
                                  //   disabled
                                  //   style={{
                                  //     color: "#FF0000", // Text color
                                  //     backgroundColor: "#FFEBEE", // Light red background color
                                  //     borderBlockColor: "#27AE603D",
                                  //     borderRadius: "8px",
                                  //     width: "90px",
                                  //     fontWeight: "bold",
                                  //     textTransform: "none",
                                  //   }}
                                  // >
                                  <p>Failed</p>
                                  // </Button>
                                )}

                                {user.status === "incomplete" && (
                                  // <Button
                                  //   disabled
                                  //   style={{
                                  //     color: "orange", // Text color
                                  //     backgroundColor: "#FFE0B2", // Light orange background color
                                  //     borderBlockColor: "#27AE603D",
                                  //     borderRadius: "8px",
                                  //     width: "90px",
                                  //     fontWeight: "bold",
                                  //     textTransform: "none",
                                  //   }}
                                  // >
                                  <p>Incomplete</p>
                                  // </Button>
                                )}

                                {user.status === "user override" && (
                                  <Button
                                    disabled
                                    style={{
                                      color: "#9F2B68", // Text color
                                      backgroundColor: "#FBD7FC", // Light orange background color
                                      borderBlockColor: "#27AE603D",
                                      borderRadius: "8px",

                                      fontWeight: "bold",
                                      textTransform: "none",
                                    }}
                                  >
                                    User override
                                  </Button>
                                )}
                              </TableCell>

                              <TableCell align="center">
                                {user.status == "processing" ? (
                                  <>
                                    <Typography>N/A</Typography>
                                  </>
                                ) : (
                                  <IconButton
                                    aria-label="more"
                                    aria-controls="three-dot-menu"
                                    aria-haspopup="true"
                                    onClick={(event) =>
                                    {
                                      setCategory(user.category_name)
                                      handleClickMenu(
                                        event,
                                        user.doc_id,
                                        user.item_id,
                                        user.status,
                                        user.confidence_color
                                      )
                                    }
                                    }
                                    //  onClose={handleClosee}
                                  >
                                    <MoreVertIcon />
                                  </IconButton>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </>
                        {/* )} */}
                      </TableBody>
                    </Table>
                    {hasMore && totalrecord > searchResults.length && (
                      // <InfiniteScroll
                      //   dataLength={searchResults.length}
                      //   next={handleLoadMore}
                      //   hasMore={hasMore}
                      //   loader={
                      //     <div
                      //       style={{
                      //         display: "flex",
                      //         justifyContent: "center",
                      //         alignItems: "center",
                      //         height: "50px",
                      //       }}
                      //     >
                      //       <BeatLoader disableShrink color="#2F80ED" />
                      //     </div>
                      //   }
                      //   endMessage={
                      //     <div
                      //       style={{ textAlign: "center", marginTop: "10px" }}
                      //     >
                      //       {!loadingMore && !hasMore && (
                      //         <div>No More Records</div>
                      //       )}
                      //       <IconButton
                      //         onClick={() => window.scrollTo(0, 0)}
                      //         style={{ color: "#2F80ED" }}
                      //         size="medium"
                      //       >
                      //         <KeyboardArrowUpIcon />
                      //       </IconButton>
                      //     </div>
                      //   }
                      // />

                                  <div
                                        style={{
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",

                                        }}
                                      >
                                  <Button onClick={handleLoadMore} style={{margin:"10px",border:"1px solid lightgray"}} >

                                  {loadingMore ? (
                         <React.Fragment>
                           <RingLoader size={24}  />
                           &nbsp; Loading
                         </React.Fragment>
                       ) : (
                         'Load More'
                       )}
                                </Button>
                                </div>
                    )}
                  </TableContainer>
                </Box>
              </Box>
              <>
                {searchResults.length === 0 && (
                  <div
                    style={{
                      color: "grey",
                      fontSize: "13px",
                      marginTop: "20px",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    No record found
                  </div>
                )}
              </>
            </Box>
          </Box>
        )}

        <Sidebar />
        <Menu
          id="three-dot-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClosee}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {userstatus === "processed" || userstatus === "user override" ? (
            <MenuItem
              onClick={() => {
                handleViewDetails(docid, itemid, currentLanguage);
              }}
            >
              {loadingIcons[itemid] ? (
                <CircularProgress size={24} />
              ) : (
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
              )}
              View Details
            </MenuItem>
          ) : null}
          <MenuItem onClick={() => {
            handleConfirmAction("reprocess", docid)}}>
            {statusloader ? (
              <CircularProgress size={24} />
            ) : (
              <ListItemIcon>
                <CheckCircleIcon />
              </ListItemIcon>
            )}
            Reprocess
          </MenuItem>
          <MenuItem onClick={() => handleConfirmAction("delete", docid)}>
            {deleteloader ? (
              <CircularProgress size={24} />
            ) : (
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
            )}
            Delete
          </MenuItem>
        </Menu>
        <Dialog open={openDialog} onClose={handleCloseDialog}>
          <DialogTitle>{selectedKey}</DialogTitle>
          <DialogContent>
            <TextField
              value={editedValue}
              onChange={(e) => setEditedValue(e.target.value)}
              fullWidth
              margin="dense"
              variant="outlined"
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleCloseDialog}
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                marginRight: "Opx",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                marginRight: "Opx",
              }}
              onClick={handleSave}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
        <Popover
          id={confidenceId}
          open={openConfidencePopover}
          anchorEl={confidencePopover}
          onClose={confidencePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Box sx={{ padding: "15px" }}>
            <Typography id="input-slider" gutterBottom fontWeight="bold">
              Select Confidence Score
            </Typography>
            <Box alignItems="center">
              <Box sx={{ display: "flex", margin: "20px 0px" }}>
                <Typography>0</Typography>
                <Slider
                  value={value}
                  onChange={handleSliderChange}
                  valueLabelDisplay="auto"
                  getAriaValueText={valuetext}
                  sx={{ margin: "0px 20px", width: "40vw", minWidth: "400px" }}
                />
                <Typography>100</Typography>
              </Box>

              <Box sx={{ display: "flex" }}>
                <Typography sx={{ marginTop: "8px", marginRight: "10px" }}>
                  Score in between :
                </Typography>
                <TextField
                  value={value[0]}
                  size="small"
                  sx={{ width: "55px" }}
                  onChange={(e) => handleTextFieldChange(0, e.target.value)}
                  onBlur={() => handleTextFieldBlur(0)}
                />
                <Typography sx={{ margin: "8px 4px" }}>and</Typography>
                <TextField
                  value={value[1]}
                  size="small"
                  sx={{ width: "55px" }}
                  onChange={(e) => handleTextFieldChange(1, e.target.value)}
                  onBlur={() => handleTextFieldBlur(1)}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20px",
              }}
            >
              <Box>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                  onClick={confidencePopoverClose}
                >
                  Cancel
                </Button>
              </Box>
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="outlined"
                  sx={{ textTransform: "none", fontWeight: "bold" }}
                >
                  Reset
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    textTransform: "none",
                    fontWeight: "bold",
                  }}
                  onClick={() => ConfidenceApply()}
                >
                  Apply
                </Button>
              </Box>
            </Box>
          </Box>
        </Popover>
        <Popover
          id={documentTypeId}
          open={openDocumentTypePopover}
          anchorEl={documentTypePopover}
          onClose={documentTypePopoverClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <Typography>
            <Card
              variant="outlined"
              style={{
                display: "flex",
              }}
            >
              <CardContent style={{ display: "flex", padding: "5px 10px" }}>
                <List
                  dense
                  sx={{
                    padding: "0px",
                  }}
                >
                  {StaticDocType.map((item) => (
                    <ListItem sx={{ margin: "0px", padding: "0px" }}>
                      <Button
                        sx={{ width: "100%", justifyContent: "left" }}
                        // onClick={() => handleListItemClick(item.label, item.value)}
                        onClick={(e) => DocTypeApply(e, item.id, item.name)}
                      >
                        {item.name}
                      </Button>
                    </ListItem>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Typography>
        </Popover>
        <Popover
          id={statusid}
          open={openid}
          anchorEl={popoverAnchorEl} // Use the new state variable
          onClose={handleClosestatus}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List>
            {[
              "processed",
              "processing",
              "failed",
              "user override",
              "incomplete",
            ].map((text) => (
              <ListItem key={text} sx={{ margin: "0px", padding: "5px" }}>
                <Button
                  sx={{ width: "100%", justifyContent: "left" }}
                  // onClick={() => handleListItemClick(item.label, item.value)}
                  onClick={() => handleStatusSelect(text)}
                >
                  {text}
                </Button>
              </ListItem>
            ))}
          </List>
        </Popover>
        <Dialog open={openConfirmation} onClose={handleCancelAction}>
          <DialogTitle>Confirm Action</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to {confirmationAction} this document?
            </DialogContentText>
            {confirmationAction==='delete' &&
            <DialogContentText>
            Extraction data will be deleted.
            </DialogContentText>
              }
            
          </DialogContent>
          {confirmationAction!=='delete' &&
          <FormControl style={{margin:"20px"}}>
        <InputLabel id="demo-simple-select-label">Category*</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Category"
          onChange={handleCategoryChange}
        >
          {categoryData &&
          categoryData.map((data,index)=>(
          <MenuItem value={data.name}>{data.name}</MenuItem>
          ))
          }
        </Select>
      </FormControl>
      }
          <DialogActions>
            <Button onClick={handleCancelAction} color="primary">
              Cancel
            </Button>
            <Button onClick={handleExecuteAction} color="primary" autoFocus>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000} // Adjust the duration as needed
          onClose={handleCloseSnackbar}
          message={snackbarMessage}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center", // Center bottom
          }}
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={() => setOpenSnackbar(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        >
          {/* <MuiAlert
    elevation={6}
    variant="filled"
    onClose={handleCloseSnackbar}
    severity="success" // You can change the severity to 'info', 'warning', or 'error' as needed
  >
    {snackbarMessage}
  </MuiAlert> */}
        </Snackbar>
      </Box>
    </>
  );
};
export default InvoiceExtraction;
