export const uploadFileDocument = async (
  category_id,
  account_id,
  folder_id,
  uploadDocument
) => {
  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  const userToken = userDataObj.token;
  const user_id = userDataObj.user_id;
  const userorg_id = userDataObj.org_id;

  // var header = new Headers();
  // header.append("Content-Type", "multipart/form-data");
  // header.append("token", userToken);

  var formdata = new FormData();
  formdata.append("user_id", user_id.toString());
  formdata.append("category_id", category_id.toString());

  formdata.append("org_id", userorg_id.toString());
  formdata.append("parent_id", folder_id.toString());
  formdata.append("account_id", account_id.toString());
  formdata.append("file_name", uploadDocument);

  var requestOptions = {
    method: "POST",
    headers: {
      token: userToken,
    },
    body: formdata,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      // "https://54.236.17.116/documentai-lumen-api-v1/web/uploaditemtorepository",
      `${window.constants.api_url}/web/uploaditemtorepository`,
      requestOptions
    );
    const res = await response.json();
    console.log("uploadfiledoc api: ", res);
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const downloadFileDocument = async (account_id, file_path_array) => {
  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  const userToken = userDataObj.token;
  const user_id = userDataObj.user_id;
  const userorg_id = userDataObj.org_id;

  const myHeaders = new Headers();
  myHeaders.append("token", userToken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    user_id: user_id,
    org_id: userorg_id,
    account_id: account_id,
    file_path: file_path_array,
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      // "https://54.236.17.116/documentai-lumen-api-v1/web/uploaditemtorepository",
      `${window.constants.api_url}/web/downloaditemfromrepository`,
      requestOptions
    );
    const res = await response.json();
    console.log(res);
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const deleteFileDocument = async (account_id,file_id, file_path_array) => {
  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  const userToken = userDataObj.token;
  const user_id = userDataObj.user_id;
  const userorg_id = userDataObj.org_id;

  const myHeaders = new Headers();
  myHeaders.append("token", userToken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    user_id: user_id,
    org_id: userorg_id,
    account_id: account_id,
    file_path: file_path_array,
    id:file_id
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      // "https://54.236.17.116/documentai-lumen-api-v1/web/uploaditemtorepository",
      `${window.constants.api_url}/web/deletefile`,
      requestOptions
    );
    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const createfolder = async (account_id, parent_id, folder_name) => {
  const userData = localStorage.getItem("userdata");
  const userDataObj = JSON.parse(userData);
  const userToken = userDataObj.token;
  const user_id = userDataObj.user_id;
  const userorg_id = userDataObj.org_id;

  const myHeaders = new Headers();
  myHeaders.append("token", userToken);
  myHeaders.append("Content-Type", "application/json");

  const raw = JSON.stringify({
    user_id: user_id,
    org_id: userorg_id,
    account_id: account_id,
    parent_id: parent_id,
    folder_name: folder_name
  });

  const requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  try {
    const response = await fetch(
      // "https://54.236.17.116/documentai-lumen-api-v1/web/uploaditemtorepository",
      `${window.constants.api_url}/web/createfolder`,
      requestOptions
    );
    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
