import React, { useState, useEffect } from "react";
import ReceiptIcon from "@mui/icons-material/Receipt";
import "./documentCategory.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { TextField, InputAdornment } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import DeleteIcon from "@mui/icons-material/Delete";
// import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import EditIcon from '@mui/icons-material/Edit';
import DialogContentText from '@mui/material/DialogContentText';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import AdminSidebar from "../Includes/AdminSidebar.jsx";
import Sidebar from "../../Includes/Sidebar.jsx";
import Chip from '@mui/material/Chip';

import {
  getAllDocumentType,
  getAllDoccategory,
} from "../AdminConsoleApiHelper/AdminApiHelper.js";
import { userMasterData } from "../AdminConsoleApiHelper/AdminApiHelper.js";
import DocumentTypeView from "./documentTypeView.jsx";
import {
  CreateCategory,
  DeleteCategory,
  editCategory
} from "../../ApiHelper/categoryAndType.js";
import { useTheme } from '@mui/material/styles';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}


export default function DocumentCategoryView() {
  const [allCategoryData, setAllCategoryData] = useState([]);
  const [allDocTypeData, setAllDocTypeData] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [currPage, setCurrPage] = useState("category");
  const [currDocType, setCurrDocType] = useState("");
  const [currDocTypeId, setCurrDocTypeId] = useState("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open, setOpen] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);
  const handleOpenDelete = () => setOpenDeleteDialog(true);
  const handleCloseDelete = () => setOpenDeleteDialog(false);
  const [buttonLoading, setLoadingButton] = useState(false);
  const [photo, setphoto] = useState();
  const [name, setname] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [responseMsg, setResponseMsg] = useState("");
  const [deleteCategoryId,setDeleteCategoryId]=useState("")
  const [userMasterDataa,setUserMasterDataa]=useState({})
  const [modelName,setModelName]=useState("")
  const [language,setLanguage]=useState([])
  const [editAddModalSwitch,setEditDeleteModalSwitch]=useState(false)
  const [allowAdd,setAllowAdd]=useState("no")
  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setLanguage(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };
  const theme = useTheme();
  const handleSnakebarClick = () => {
    setSnackBarOpen(true);
  };
  const handleSnakebarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarOpen(false);
  };

  const action = (
    <div>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleSnakebarClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </div>
  );

  const GetCatType = async () => {
    try {
      setLoadingCategory(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await getAllDoccategory(user_id, userorg_id, userToken);
      if (res.code === "200") {
        console.log(res.data);
        setAllCategoryData(res.data);
        setAllowAdd(res.allow_create)
        setLoadingCategory(false);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingCategory(false);
    }
  };
  const handleUserMasterData = async () => {
    try {
      setLoadingCategory(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await userMasterData(user_id, userorg_id, userToken);
      if (res.code === "200") {
        console.log(res);
        setUserMasterDataa(res);
        // setLoadingCategory(false);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingCategory(false);
    }
  };
  const GetDocEntity = async (selectedOptionId) => {
    try {
      setCurrPage("doctype");
      setLoadingCategory(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await getAllDocumentType(
        user_id,
        userorg_id,
        selectedOptionId,
        userToken
      );
      if (res.code === "200") {
        console.log(res.data);
        setAllDocTypeData(res.data);
        setLoadingCategory(false);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingCategory(false);
    }
  };

  const handleSave = async () => {
    // try {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;
    if(language.length>2){
      setResponseMsg("Please select only two languages");
      handleSnakebarClick();
      return ;
    }
    const res = await CreateCategory(userToken, user_id, org_id, name, photo,language.join(","), modelName);
    if (res.code === "200") {
      setOpen(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    } else {
      setOpen(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    }
    GetCatType();
    // console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };
  const handleEdit = async () => {
    // try {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;
    if(language.length>2){
      setResponseMsg("Please select only two languages");
      handleSnakebarClick();
      return ;
    }
    const res = await editCategory(userToken, user_id, org_id, deleteCategoryId, photo,language.join(","), modelName);
    if (res.code === "200") {
      setOpen(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    } else {
      setOpen(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    }
    GetCatType();
    // console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };

  const handleDelete = async (cat_id) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;

    const res = await DeleteCategory(userToken, user_id, org_id, cat_id);
    if (res.code === "200") {
      setOpenDeleteDialog(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
      GetCatType();
    } else {
      setOpenDeleteDialog(false);
      setResponseMsg(res.message);
      handleSnakebarClick();
    }
    console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };

  useEffect(() => {
    handleUserMasterData();
    GetCatType();
    setCurrPage("category");
    setCurrDocType("");
  }, []);

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        backgroundColor: "#EFEDF1",
        flexDirection: "column",
        minHeight: "100vh",
        // overflow:"visible"
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          // overflowY: "auto",
          marginLeft: "150px",
          marginRight: "30px",
          marginTop: "65px",
          // overflow:"visible"
        }}
      >
        <div>
          <p>Create or Manage your Organisation Documents</p>
          <div
            style={{
              color: "blue",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <span
              onClick={() => {
                setCurrPage("category");
              }}
              className="doccategorytext"
            >
              Document Category
            </span>
            {loadingCategory === false && currPage == "doctype" && (
              <span>
                &nbsp;{" "}
                <ArrowForwardIosIcon
                  style={{ width: "14px", height: "14px" }}
                />{" "}
                {currDocType}
              </span>
            )}
          </div>
        </div>
        {loadingCategory === true && (
          <div>
            <CircularProgress />
          </div>
        )}
        {loadingCategory === false && currPage == "category" && (
          <div className="doc_category_card_div">
            {allowAdd==="yes" &&
            <div className="doc_category_card" onClick={()=>{
              setEditDeleteModalSwitch(true)
              setname("")
              setLanguage([])
              setModelName("")
              handleOpen()
            }}>
              <div className="doc_category_card_icon">
                <img src="/plus_logo.png" width={50} height={50} />
              </div>
              <p>Custom</p>
            </div>
            }
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "16px",
                  width: "100%",
                  display: "flex",
                  alignItems: "flex-end",
                  flexDirection: "column",
                },
              }}
            >
              <DialogContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-betwwen",
                    alignItems: "flex-end",
                    marginBottom: "30px",
                  }}
                >
                  {editAddModalSwitch==true &&
                  <Typography variant="h6">Create new document category</Typography>
                }
                 {editAddModalSwitch==false &&
                  <Typography variant="h6">Edit category</Typography>
                }
                  <Box position="absolute" left="90%">
                    <IconButton>
                      <CloseIcon onClick={handleClose} />
                    </IconButton>
                  </Box>
                </Box>
                {/* Your form for creating a new user goes here */}
                <Box
                  display="flex"
                  flexGrow={1}
                  style={{ flexDirection: "column" }}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    sx={{ width: "100%" }}
                  >
                    <Box
                      fontWeight="bold"
                      sx={{ flex: 1, marginRight: "50px", marginTop: "15px",minWidth:"100px" }}
                    >
                      Name
                      {/* Red mandatory star icon */}
                      <span style={{ color: "red" }}>*</span>
                    </Box>
                    <Box sx={{ flex: 4 }}>
                      <TextField
                        label="Name"
                        sx={{ marginRight: "15px",minWidth:"380px" }}
                        value={name}
                        disabled={editAddModalSwitch==false}
                        onChange={(e) => setname(e.target.value)}
                        InputProps={{ sx: { borderRadius: "8px" } }}
                      />
                    </Box>
                  </Box>
                  {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" &&
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    sx={{ width: "100%", marginTop: "15px"}}
                  >
                    <Box
                      fontWeight="bold"
                      sx={{ flex: 1, marginRight: "47px", marginTop: "15px",minWidth:"100px" }}
                    >
                      Model
                      {/* Red mandatory star icon */}
                      <span style={{ color: "red" }}>*</span>
                    </Box>
                    <Box sx={{ flex: 4, marginLeft: "2px",marginRight:"10px"}}>
                    <FormControl  sx={{width:"380px"}}>
                      <InputLabel id="demo-simple-select-label">Model</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={modelName}
                        label="Model"
                        onChange={(e)=>{setModelName(e.target.value)}}
                      >
                        {userMasterDataa.model && userMasterDataa['model'].map((data,index)=>(
                          <MenuItem value={data.name}>{data.name}</MenuItem>
                        ))

                        }
                      </Select>
                    </FormControl>
                    </Box>
                  </Box>
                  }
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    sx={{ width: "100%", marginTop: "15px"}}
                  >
                    <Box
                      fontWeight="bold"
                      sx={{ flex: 1, marginRight: "47px", marginTop: "15px" }}
                    >
                      Languages
                      {/* Red mandatory star icon */}
                      <span style={{ color: "red" }}>*</span>
                    </Box>
                    <Box sx={{ flex: 4, marginLeft: "3px",marginRight:"10px"}}>
                    <FormControl sx={{ m: 1, width: 380 }}>
        <InputLabel id="demo-multiple-chip-label">Select Languages</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={language}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Select Languages" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} onClick={(e) => {
                  console.log("SFKLNMSL")
                  e.stopPropagation()
                  setLanguage(language.filter(item => item == value))
                }}/>
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {userMasterDataa.language && userMasterDataa['language'].map((name) => (
            <MenuItem
              key={name.name}
              value={name.name}
              style={getStyles(name.name, language, theme)}
            >
              <Checkbox checked={language.indexOf(name.name) > -1} />
              {name.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
                    </Box>
                  </Box>
                  {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" &&
                  <div className="image_div">
                    <span>Add Image:</span>
                    <input
                    style={{marginLeft:"36px"}}
                      type="file"
                      id="file-input"
                      onChange={(e) => {
                        setphoto(e.target.files[0]);
                      }}
                    />
                  </div>
              }
                  
                </Box>

                <DialogActions
                  sx={{
                    marginBottom: "10px",
                    marginTop: "15px",
                    marginRight: "0",
                    gap: "14px",
                  }}
                >
                  <Button
                    variant="outlined"
                    onClick={handleClose}
                    sx={{ textTransform: "none", fontWeight: "bold" }}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none", fontWeight: "bold" }}
                    onClick={() => {
                      if(editAddModalSwitch==true){
                        handleSave();
                      }
                      else{
                        handleEdit();
                      }
                    }}
                    backgroundColor={buttonLoading && "#616161"}
                    disabled={buttonLoading}
                  >
                    {buttonLoading && <CircularProgress size={16} />}Save
                  </Button>
                </DialogActions>
              </DialogContent>
            </Dialog>
            {allCategoryData.map((categoryData, index) => (
              <div
                className="doc_category_card"
                onClick={() => {
                  setCurrDocType(categoryData.name);
                  setCurrDocTypeId(categoryData.id);
                  GetDocEntity(categoryData.id);
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="doc_category_card_icon">
                    {categoryData.icon !== "" && (
                      <img
                        src={categoryData.icon}
                        style={{ width: "50px", height: "50px" }}
                      />
                    )}
                    {categoryData.icon === "" && (
                      <ReceiptIcon style={{ width: "50px", height: "50px" }} />
                    )}
                  </div>
                  {categoryData.allow_edit==="yes" &&
                  <div style={{display:"flex",flexDirection:'row'}}>
                  <Tooltip
                    title="Delete"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCategoryId(categoryData.id)
                      handleOpenDelete()
                    }}
                  >
                    <IconButton>
                      <DeleteIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip
                    title="Edit"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteCategoryId(categoryData.id)
                      setEditDeleteModalSwitch(false)
                      setname(categoryData.name)
                      if(categoryData.ext_language!==""){
                        setLanguage(categoryData.ext_language.split(","))
                      }
                      else{
                        setLanguage([])
                      }
                      setModelName(categoryData.ext_model)
                      handleOpen()
                    }}
                  >
                    <IconButton>
                      <EditIcon />
                    </IconButton>
                  </Tooltip>
                  </div>
                  }
                </div>
                <p style={{marginBottom:"0px"}}>{categoryData.name}</p>
                {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="no" && categoryData.predefind_data	=== "yes" &&
                <span style={{fontSize:"11px",fontStyle:"italic"}}>Predefined</span>
                }
                {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" && categoryData.predefind_data	=== "no" &&
                <span style={{fontSize:"11px",fontStyle:"italic"}}>By: {categoryData.department_name}	</span>
                }
              </div>
            ))}
            
    
          </div>
        )}
         <Dialog
        open={openDeleteDialog}
        onClose={handleCloseDelete}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
       
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Are you sure you want to delete?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete}>Disagree</Button>
          <Button onClick={()=>{
          handleDelete(deleteCategoryId)}} autoFocus>
            Agree
          </Button>
        </DialogActions>
      </Dialog>
        {loadingCategory === false && currPage == "doctype" && (
          <div className="doc_category_card_div">
            <DocumentTypeView
              allDocTypeData={allDocTypeData}
              currCatName={currDocType}
              currCatId={currDocTypeId}
            />
          </div>
        )}
        <div className="snackbardiv">
          <Snackbar
          anchorOrigin={{vertical:"bottom",horizontal:"center"}}
            open={snackBarOpen}
            autoHideDuration={3000}
            onClose={handleSnakebarClose}
            message={responseMsg}
            action={action}
          />
        </div>
      </Box>
      <Sidebar />
    </Box>
  );
}
