
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import AdminSidebar from '../Includes/AdminSidebar.jsx';
import { Button, CircularProgress, Snackbar } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddIcon from '@mui/icons-material/Add';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Select, IconButton, Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import { addDocumentType, editDocumentType ,getAllDocumentType,setEntityOrder,setDocumentOrder } from '../AdminConsoleApiHelper/AdminApiHelper.js';
import Sidebar from '../../Includes/Sidebar.jsx';
const DocumentType = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [Loadingtable, setLoadingtable] = useState(true);
  const [UserDetails, setUserdetails] = useState([]);
  const [documentName, setDocumentName] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [usertoken, setusertoken] = useState("");
  const [index, setIndex] = useState(0);
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [orderNumbers, setOrderNumbers] = useState([])
  const selectedValue = location.state?.selectedValue;
  const selectedName = location.state?.selectedName;
  const buttonStyles = {
    borderRadius: '8px',
    color: 'var(--Orange, #F2994A)',
    border: '1px solid rgba(242, 153, 74, 0.24)',
    background: 'rgba(242, 153, 74, 0.16)',
    '&:hover': {
      background: 'rgba(242, 153, 74, 0.24)',
    },
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '700',
    lineheight: '20px',
  };
  const buttonactiveStyles = {
    borderRadius: '8px',
    color: '#27AE60',
    border: '1px solid rgba(39, 174, 96, 0.24)',
    background: 'rgba(39, 174, 96, 0.16)',
    '&:hover': {
      background: 'rgba(242, 153, 74, 0.24)',
    },
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '700',
    lineheight: '20px',
  };

  const openSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbarOpen(true);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosemodal = () => {
    // Add your logic for creating a new user here
    console.log('Creating a new user...');
    setOpenModal(false);
    setOpenEditModal(false)
  };
  const handlebacknavigation = () => {
    // Perform any necessary actions before navigating
    // For now, let's just navigate to "/inner-page"
    navigate('/admin-console/searchdocument-type');
  };
  useEffect(() => {
    GetDocTypeData();
  }, []);
  const GetDocTypeData = async () => {
    try {
      setLoadingtable(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;
console.log(selectedValue);

      setusertoken(userToken);
      setuser_id(user_id);
      setuserorg_id(userorg_id);
      setIndex(0);
      const res = await getAllDocumentType(user_id, userorg_id,selectedValue, userToken);

      // console.log(res)
      if (res.code === "200") {

        setLoadingtable(false);
        console.log(res.data)
        setUserdetails(res.data)
        const extractedOrderNumbers = res.data.map((user) => user.order_number);
        setOrderNumbers(extractedOrderNumbers);
      } else {
        // Handle the error
        console.error("Error:",);
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(true);
    }
  };

  const AddDocumentType = async (name) => {
    setButtonLoading(true)
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;
      const res = await addDocumentType(user_id, userorg_id, name,selectedValue, userToken)
      if (res.code === "200") {
        setButtonLoading(false)
        // setLoadingtable(false);
        console.log(res.data)
        setOpenModal(false);
        openSnackbar(res.message);
        GetDocTypeData();
        // setUserdetails(res.data)
      } else {
        // Handle the error
        setButtonLoading(false)
        console.error("Error:",);
        openSnackbar(res.message);
      }
    }
    catch (error) {
      setButtonLoading(false)
      console.error("Error:", error);
      //   setdeleteLoading(false)
    }

  };

  const editModalData = (event, docType) => {
    setOpenEditModal(true);
    setDocumentName(docType.name)
    setDocumentId(docType.id)

  }

  const EditDocumentType = async (name, id) => {
    setButtonLoading(true)
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;


      const res = await editDocumentType(user_id, userorg_id, name, id, userToken)
      // console.log(res)
      if (res.code === "200") {
        setButtonLoading(false)
        // setLoadingtable(false);
        console.log(res.data)
        setOpenEditModal(false);
        openSnackbar(res.message);
        GetDocTypeData();
        // setUserdetails(res.data)
      } else {
        // Handle the error
        setButtonLoading(false)
        console.error("Error:",);
        openSnackbar(res.message);
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Error:", error);

    }
  };
  const handleSetOrder = (userDetails) => {
    const changedOrders = [];
    // Log orderNumbers along with corresponding meta_key
    userDetails.forEach((user, index) => {

      const changedOrderEntry = `${user.id}-${orderNumbers[index]}`;
      changedOrders.push(changedOrderEntry);
    });
    console.log(changedOrders);
    SetDocumentEntityOrder(changedOrders);
  };
  const SetDocumentEntityOrder = async (order) => {
    setButtonLoading(true)
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;
      const res = await setDocumentOrder(user_id, userorg_id, selectedValue, order, userToken);

      if (res.code === "200") {
        setButtonLoading(false)
        GetDocTypeData();
        // setLoadingtable(false);

        openSnackbar(res.message);
        // setUserdetails(res.data)
      } else {
        // Handle the error
        setButtonLoading(false)
        console.error("Error:",);
        openSnackbar(res.message);
      }
    } catch (error) {
      setButtonLoading(false)
      console.error("Error:", error);

    }
  };
  function disableBodyScroll() {
    document.body.style.overflow = 'hidden';
  }
  
  function enableBodyScroll() {
    document.body.style.overflow = 'auto';
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    disableBodyScroll();
  
    // Return a cleanup function to enable body scroll when the component unmounts
    return () => {
      enableBodyScroll();
    };
  }, []);
  return (
    <Box component="main" sx={{ display: 'flex', backgroundColor: "#EFEDF1", flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ textAlign: 'left', marginLeft: '150px', marginRight: '50px', marginTop: '100px', display: 'flex', justifyContent: 'space-between', }}>
      <Box sx={{ display: 'flex' }}>
            <ArrowBackIcon sx={{ color: '#005AC1', fontSize: 20, cursor: 'pointer',marginTop:'6px' }} onClick={handlebacknavigation} />
            <Typography sx={{ fontSize: '14px', color: '#005AC1', fontWeight: '500', cursor: 'pointer' ,marginTop:'6px'}} onClick={handlebacknavigation}>Go back</Typography>
            <Typography sx={{ fontSize: '22px', color: '#606060', fontWeight: '500' ,marginLeft:'20px'}}>Category Type: {selectedName} </Typography>
          </Box>
 

        <Button
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Create new document type
        </Button>
      </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', marginLeft: '132px', marginRight: '5px', marginTop: '20px' }}>
        <TableContainer component={Paper} style={{ boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)' ,marginBottom:'15px', maxHeight: "calc(100vh - 220px)",}}>
        <Table  size="small" sx={{ minWidth: 700 }}aria-label="simple table">
                  <TableHead sx={{  position: 'sticky', top: 0, zIndex: '999',backgroundColor: '#EFF1F3', }}>
              <TableRow>
                   <TableCell sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Order No.</TableCell> 
                {/* <TableCell sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>SL. No.</TableCell> */}
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568', }}>Document Name</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568', }}>Created On</TableCell>
                {/* <TableCell align="center" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568', }}>Action</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Loadingtable && (

                <TableCell colSpan={6} align="center" sx={{ width: '1000px' }}>
                  <CircularProgress />
                </TableCell>

              )}
              {!Loadingtable && (
                <>
                  {UserDetails.map((docType, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <TextField
                          style={{ maxWidth: '50px' }}
                          type='text'
                          key={index}
                          value={orderNumbers[index]}
                          onChange={(e) => {
                            // Allow only numeric input
                            const numericValue = e.target.value.replace(/[^0-9.]/g, '');
                            const updatedOrderNumbers = [...orderNumbers]; // Create a copy of the array
                            updatedOrderNumbers[index] = numericValue; // Update the value at the specific index
                            setOrderNumbers(updatedOrderNumbers); // Update the state
                          }}
                        />
                      </TableCell>
                      {/* <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell> */}
                      <TableCell align="left">{docType.name}</TableCell>
                      <TableCell align="left">{docType.created_on}</TableCell>
                      {/* <TableCell align="center">
                        <Button sx={{ color: '#005AC1', fontWeight: 'bold', textTransform: 'none' }} onClick={(event) => editModalData(event, docType)}>
                          Edit
                        </Button>
                      </TableCell> */}
                    </TableRow>
                  ))}

                </>
              )}
              {/* Add more rows as needed */}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginBottom: '50px' }}>
          <Button
            sx={{
              textTransform: 'none',
              fontWeight: 'bold',
              marginTop: '20px',
              marginBottom: '50px',
              backgroundColor: '#005AC1'
            }}
            variant="contained"
            onClick={() => handleSetOrder(UserDetails)}
            backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
            {buttonLoading && <CircularProgress size={16} />} Set Order
          </Button></Box> 
        <Dialog open={openModal} onClose={() => setOpenModal(false)} sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: '100%',
            display: 'flex',

            alignItems: 'flex-end',
            flexDirection: 'column'


          },
        }} >
          <DialogContent  >
            <Box sx={{ display: 'flex', justifyContent: 'space-betwwen', alignItems: 'flex-end', marginBottom: '30px' }}>
              <Typography variant="h6">Create new document type</Typography>
              <Box position='absolute' left='90%'>
                <IconButton>
                  <CloseIcon onClick={handleClosemodal} />
                </IconButton>
              </Box>
            </Box>
            {/* Your form for creating a new user goes here */}
            <Box display='flex' flexGrow={1} >
              <Box display='flex' flexDirection="row" alignItems="flex-start" sx={{ width: '100%', }}>
                <Box fontWeight='bold' sx={{ flex: 1, marginRight: '50px', marginTop: '15px' }} >
                  Name
                  {/* Red mandatory star icon */}
                  <span style={{ color: 'red' }}>*</span>
                </Box>
                <Box sx={{ flex: 4, marginLeft: '1%' }}>
                  <TextField
                    label='Name'
                    sx={{ width: '440px', marginRight: '15px' }}
                    // value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                    InputProps={{ sx: { borderRadius: '8px' } }}
                  />
                </Box>
              </Box>
            </Box>



            <DialogActions sx={{ marginBottom: '10px', marginTop: '15px', marginRight: '0', gap: '14px' }}>
              <Button variant="outlined" onClick={handleClosemodal} sx={{ textTransform: 'none', fontWeight: 'bold' }}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={() => {
                AddDocumentType(documentName);

              }}
              backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}
              >
                {buttonLoading && <CircularProgress size={16} />}Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
        <Dialog open={openEditModal} onClose={() => setOpenEditModal(false)} sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: '100%',
            display: 'flex',

            alignItems: 'flex-end',
            flexDirection: 'column'


          },
        }} >
          <DialogContent  >
            <Box sx={{ display: 'flex', justifyContent: 'space-betwwen', alignItems: 'flex-end', marginBottom: '30px' }}>
              <Typography variant="h6">Edit document type</Typography>
              <Box position='absolute' left='90%'>
                <IconButton>
                  <CloseIcon onClick={handleClosemodal} />
                </IconButton>
              </Box>
            </Box>
            {/* Your form for creating a new user goes here */}
            <Box display='flex' flexGrow={1} >
              <Box display='flex' flexDirection="row" alignItems="flex-start" sx={{ width: '100%', }}>
                <Box fontWeight='bold' sx={{ flex: 1, marginRight: '50px', marginTop: '15px' }} >
                  Name
                  {/* Red mandatory star icon */}
                  <span style={{ color: 'red' }}>*</span>
                </Box>
                <Box sx={{ flex: 4, marginLeft: '1%' }}>
                  <TextField
                    label='Name'
                    sx={{ width: '440px', marginRight: '15px' }}
                    value={documentName}
                    onChange={(e) => setDocumentName(e.target.value)}
                    InputProps={{ sx: { borderRadius: '8px' } }}
                  />
                </Box>
              </Box>
            </Box>



            <DialogActions sx={{ marginBottom: '10px', marginTop: '15px', marginRight: '0', gap: '14px' }}>
              <Button variant="outlined" onClick={handleClosemodal} sx={{ textTransform: 'none', fontWeight: 'bold' }}>
                Cancel
              </Button>
              <Button variant="contained" sx={{ textTransform: 'none', fontWeight: 'bold' }} onClick={(event) => {
                EditDocumentType(documentName, documentId);

              }}
              backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}
              >
                {buttonLoading && <CircularProgress size={16} />} Edit
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Box>
      <Sidebar />
      <Snackbar
        mode="outlined"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center', // Center bottom
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        action={[
          <IconButton key="close" color="inherit" onClick={() => setSnackbarOpen(false)}>
            <CloseIcon />
          </IconButton>,
        ]}
      />
    </Box>
  );
};

export default DocumentType;
