import React, { useState, useEffect, useRef } from "react";
import {
  fetchFavorites,
  fetchRepositoryData,
  documentPreview,
  fetchRepositoryByDirectoryId,
  markAsFavorite,
  undoFavorite,
} from "../ApiHelper/Profile.js";
import Typography from "@mui/material/Typography";

import Sidebar from "../Includes/Sidebar";

import {
  Button,
  DialogContentText,
  DialogTitle,
  Snackbar,
  styled,
} from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import Checkbox from "@mui/material/Checkbox";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  LinearProgress,
} from "@mui/material";
import { TextField, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { useLocation } from "react-router-dom"; // Import useNavigate
import { handleBreakpoints } from "@mui/system";
import {
  createfolder,
  deleteFileDocument,
  downloadFileDocument,
  uploadFileDocument,
} from "../ApiHelper/Repository.js";
import { getAllCategories } from "../ApiHelper/Profile.js";
import { LoadingButton } from "@mui/lab";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CreateNewFolderIcon from "@mui/icons-material/CreateNewFolder";
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { showCategory } from "../AdminConsole/AdminConsoleApiHelper/AdminApiHelper.js";
const RepositoryConnected = () => {
  const location = useLocation();
  const account_id = location.state?.account_id;
  const folder_id = location.state?.folder_id;
  const email = location.state?.email;
  const [history, setHistory] = useState([folder_id]);
  const [starredRows, setStarredRows] = useState([]);
  const [directorry, setdirectory] = useState([]);
  const [file, setfile] = useState([]);
  const [uploadDocument, setUploadDocument] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(true);
  const [navigation, setnavigation] = useState();
  const [loading, setLoading] = useState(true); // Add loading state
  const [gobackid, setgobackid] = useState([]);
  const navigate = useNavigate(); // Use useNavigate
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [showGoBackButton, setShowGoBackButton] = useState(false);
  const [loadingBTN, setLoadingBTN] = useState(false);

  const [open, setOpen] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [newFolderDialog, setNewFolderDialog] = useState(false);
  const [newFolderName, setNewFolderName] = useState("");
  const [deleteConfirmationDialog, setDeleteConfirmationDialog] =
    useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const [fileIdDelete, setFileIdDelete] = useState(null);

  const [downloadState, setDownloadState] = useState(null); // null, "loading", or "completed"
  const [category, setCategory] = React.useState('');

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };
  const handleCloseSnackBar = (event, reason) => {
    if (reason === "clickaway") {
      setUploadDocument(null);
      setOpenSnackBar(false);
      return;
    }
    setUploadDocument(null);
    setOpenSnackBar(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setUploadDocument(null);
    setOpen(false);
  };

  const handleNewClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleNewFile = () => {
    setOpen(true);
    handleCloseMenu();
  };

  const handleNewFolder = () => {
    setNewFolderDialog(true);
    handleCloseMenu();
  };

  const handleNewFolderNameChange = (event) => {
    setNewFolderName(event.target.value);
  };

  const handleRowClick = async (docid, rowIndex) => {
    // Navigate to the desired location when a row is clicked
    setLoading(true);
    setClickedRowIndex(rowIndex); // Set the clicked row index
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await documentPreview(
        userToken,
        user_id,
        userorg_id,
        account_id,
        docid
      );

      if (res.code === "200") {
        setLoading(false);
        console.log(res.doc_link);
        window.open(res.doc_link, "_blank");
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [openModal, setOpenModal] = useState(false);
  const [categoryData,setCategoryData]=useState([])

  

  const buttonStyles = {
    borderRadius: "8px",
    color: "var(--Orange, #F2994A)",

    background: "rgba(242, 153, 74, 0.16)",
    "&:hover": {
      background: "rgba(242, 153, 74, 0.24)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };
  const buttonactiveStyles = {
    borderRadius: "8px",
    color: "#27AE60",

    background: "rgba(39, 174, 96, 0.16)",
    "&:hover": {
      background: "rgba(39, 174, 96, 0.16)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };

  const buttonredStyles = {
    borderRadius: "8px",
    color: "#EB5757",

    background: "rgba(235, 87, 87, 0.12)",
    "&:hover": {
      background: "rgba(235, 87, 87, 0.12)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };

  const buttonnewStyles = {
    borderRadius: "8px",
    color: "#2F80ED",

    background: "rgba(47, 128, 237, 0.12)",
    "&:hover": {
      background: "rgba(47, 128, 237, 0.12)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };

  const handleClosemodal = () => {
    // Add your logic for creating a new user here
    console.log("Creating a new user...");
    setOpenModal(false);
  };

  const handleStarClick = async (
    index,
    event,
    directoryId,
    docname,
    docsize,
    accountid,
    lastmodified
  ) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    const isAlreadyChecked = file[index].favourite === "yes";

    try {
      let apiResponse;
      if (isAlreadyChecked) {
        apiResponse = await undoFavorite(
          userToken,
          user_id,
          userorg_id,
          directoryId
        );
      } else {
        apiResponse = await markAsFavorite(
          userToken,
          user_id,
          userorg_id,
          directoryId,
          docname,
          docsize,
          accountid,
          lastmodified,
          "yes"
        );
      }

      if (apiResponse.code === "200") {
        console.log(apiResponse.message);

        const updatedFiles = [...file];
        updatedFiles[index].favourite = isAlreadyChecked ? "no" : "yes";
        setfile(updatedFiles);

        event.stopPropagation();
      } else {
        console.error("Error:", apiResponse.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDepartment = async () => {
    setLoading(true);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await fetchRepositoryData(
        userToken,
        user_id,
        userorg_id,
        account_id,
        folder_id
      );

      if (res.code === "200") {
        setdirectory(res.directory);
        setnavigation(res.navigation);
        setgobackid(res.goback_id);
        setfile(res.files);
        console.log(res.files);
        setnavigation(res.current_path);

        setLoading(false);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchDepartmentdata = async (previousGobackId) => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await fetchRepositoryData(
        userToken,
        user_id,
        userorg_id,
        account_id,
        previousGobackId
      );

      if (res.code === "200") {
        setdirectory(res.directory);
        setnavigation(res.navigation);
        setgobackid(res.goback_id);
        setfile(res.files);
        console.log(res.files);
        setnavigation(res.current_path);

        setLoading(false);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const getcategoryindex=()=>{
    console.log("LKSNDFLKNSLD")
    console.log(category)
    if(category===""){
      setSnackBarMessage("Please select category");
      setOpenSnackBar(true);
      return;
    }
    for(let i=0;i<categoryData.length;i++){
      if(category==categoryData[i].name){
        return categoryData[i].id
      }
    }
  }
  const handleGetAllCategories=async()=>{
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllCategories(
        userToken,
        user_id,
        userorg_id,
      );

      if (res.code === "200") {
        // setCategoryData()
        setCategoryData(res.data)
        // if(res.data.length>0){
        //   setCategory(res.data[0].name)
        // }
      } else {
        // Handle the error
        setOpenSnackBar(true);
        setSnackBarMessage(res.message);
        // console.error("Error:", res.message);
      }
    } catch (error) {
      setOpenSnackBar(true);
      setSnackBarMessage("Server Error");
      console.error("Error:", error);
    }
  }

  useEffect(() => {
    fetchDepartment();
    handleGetAllCategories()
  }, []);

  const handleDirectoryDoubleClick = async (directoryId, rowIndex) => {
    setLoading(true);
    setClickedRowIndex(rowIndex); // Set the clicked row index
    const userData = localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await fetchRepositoryByDirectoryId(
        userToken,
        user_id,
        userorg_id,
        account_id,
        directoryId
      );

      console.log("handleDirectoryDoubleClick: ", res);

      if (res.code === "200") {
        console.log("double click response: " + JSON.stringify(res));
        setdirectory(res.directory);
        setfile(res.files);
        console.log(res.files);
        setLoading(false);
        setgobackid(res.goback_id);
        setHistory((prevHistory) => [...prevHistory, res.goback_id]);
        setnavigation(res.current_path);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    console.log("double clicked history: ", history);
  };

  const yourCallbackFunction = (gobackid) => {
    // Your custom logic with gobackid
    console.log("Go Back ID:", gobackid);
  };

  console.log("history: ", history);

  const handleGoBack = () => {
    const previousHistory = [...history];
    console.log("Previous History then: ", previousHistory);

    previousHistory.pop();
    const previousGobackId = previousHistory[previousHistory.length - 1];
    console.log("Previous History now: ", previousHistory);

    if (previousHistory.length === 0) {
      // If there's no previous history, hide the Go Back button
      setShowGoBackButton(false);
    } else {
      setShowGoBackButton(true);
    }

    yourCallbackFunction(previousGobackId);
    fetchDepartmentdata(previousGobackId);

    setHistory(previousHistory);
  };

  const fileInputRef = useRef(null);

  const handleUploadModalClick = () => {
    fileInputRef.current.click();
  };

  const handleFileUpload = async (e) => {
    // setLoadingBTN(true);
    setDisableSubmitBtn(true);
    e.preventDefault();
    const fileToUpload = e.target.files[0];
    if (!fileToUpload) {
      console.error("No file selected.");
      return;
    }

    try {
      setUploadDocument(fileToUpload);
    } catch (error) {
      console.error("Error setting file:", error);
    }
  };

  const handleModalSubmit = async () => {
    
    setLoadingBTN(true);

    try {
      const result = await uploadFileDocument(
        getcategoryindex(),
        account_id,
        gobackid,
        uploadDocument
      );
      console.log("upload api response: ", result);
      if (result.code === "200") {
        setLoadingBTN(false);
        fetchDepartmentdata(gobackid);
        setUploadDocument(null);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
        setOpen(false);
      } else if (result.code === "500") {
        setLoadingBTN(false);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
      } else {
        setLoadingBTN(false);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
      }
    } catch (error) {
      console.error(error);
      setLoadingBTN(false);
    }
  };

  useEffect(() => {
    // Check if the current path is the root path and update showGoBackButton accordingly
    const isRootPath = history.length === 0;
    setShowGoBackButton(!isRootPath);
  }, [history]);

  function disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  function enableBodyScroll() {
    document.body.style.overflow = "auto";
  }
  const handleShowCategory=async(id,idx)=>{
  // console.log(id,idx)
  // setLoading(true);

const userData = localStorage.getItem("userdata");
const userDataObj = JSON.parse(userData);
const userToken = userDataObj.token;
const user_id = userDataObj.user_id;
const userorg_id = userDataObj.org_id;


try {
  const res = await showCategory(
    user_id,
    userorg_id,
    id,
    userToken,
  );

  if (res.code === "200") {
 
    
    setfile(file.map(data => {
      if (data.id === id) {
        // Create a *new* object with changes
        return { ...data, category: res.category_name	 };
      } else {
        // No changes
        return data;
      }
    }));
  } else {
    // Handle the error
    setSnackBarMessage(res.message)
    setOpenSnackBar(true)
  }
} catch (error) {
  console.error("Error:", error);
  setSnackBarMessage("Server Error")
    setOpenSnackBar(true)
}
// setLoading(false);
// console.log("double clicked history: ", history);

  }

  useEffect(() => {
    window.scrollTo(0, 0);
    disableBodyScroll();

    // Return a cleanup function to enable body scroll when the component unmounts
    return () => {
      enableBodyScroll();
    };
  }, []);

  const DropZone = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
    border: "2px dashed #ccc",
    borderRadius: "8px",
    padding: "20px",
    cursor: "pointer",
    transition: "border-color 0.2s ease-in-out",
    "&:hover": {
      borderColor: "#005AC1",
    },
  }));

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (!file) {
      console.error("No file selected.");
      return;
    }

    try {
      setUploadDocument(file);
    } catch (error) {
      console.error("Error setting file:", error);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
  };

  const downloadFile = async (item_name) => {
    try {
      setDownloadState("loading");
      const currentPathArray = navigation.split("/");
      const rootIndex = currentPathArray.indexOf("root:");
      let filteredPathArray = currentPathArray.slice(rootIndex + 1);
      filteredPathArray.push(item_name);
      console.log("filteredPathArray:", filteredPathArray);
      filteredPathArray = filteredPathArray.map((pathPart) =>
        pathPart.replace(/ /g, "%20")
      );
      const result = await downloadFileDocument(account_id, filteredPathArray);
      if (result.code === "200") {
        const downloadUrl = result.data["@microsoft.graph.downloadUrl"];
        window.open(downloadUrl);
        setDownloadState("completed");
        setClickedRowIndex(null);
      }
    } catch (error) {
      console.error(error);
      setDownloadState(null);
      setClickedRowIndex(null);
    }
  };

  const deleteFile = async (item_name,item_id) => {
    setDeleteConfirmationDialog(true);
    setFileToDelete(item_name);
    setFileIdDelete(item_id);

  };

  const handleDeleteCancel = () => {
    setDeleteConfirmationDialog(false);
    setFileToDelete(null);
    setFileIdDelete(null);

  };

  const handleDeleteConfirmation = async () => {
    setLoadingBTN(true);
    try {
      const currentPathArray = navigation.split("/");
      const rootIndex = currentPathArray.indexOf("root:");
      let filteredPathArray = currentPathArray.slice(rootIndex + 1);
      filteredPathArray.push(fileToDelete);
      console.log("FilteredPathArray: " + filteredPathArray);
      filteredPathArray = filteredPathArray.map((pathPart) =>
        typeof pathPart === "string" ? pathPart.replace(/ /g, "%20") : pathPart
      );
      const result = await deleteFileDocument(account_id,fileIdDelete ,filteredPathArray);
      if (result.code === "204") {
        setLoadingBTN(false);
        setDeleteConfirmationDialog(false);
        setFileToDelete(null);
        setFileIdDelete(null);
        fetchDepartmentdata(gobackid);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
      } else {
        setLoadingBTN(false);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
      }
    } catch (error) {
      console.error(error);
      setLoadingBTN(false);
    }
  };

  const handleNewFolderSubmit = async () => {
    setLoadingBTN(true);
    try {
      const result = await createfolder(account_id, gobackid, newFolderName);
      if (result.code === "201") {
        setLoadingBTN(false);
        setNewFolderDialog(false);
        setNewFolderName("");
        fetchDepartmentdata(gobackid);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
      } else {
        setLoadingBTN(false);
        setOpenSnackBar(true);
        setSnackBarMessage(result.message);
      }
    } catch (error) {
      console.error(error);
      setLoadingBTN(false);
    }
  };

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        backgroundColor: "#EFEDF1",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{
          textAlign: "left",
          marginLeft: "150px",
          marginRight: "50px",
          marginTop: "70px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* <Link
          style={{
            textDecoration: "none",
            display: "flex",
            alignItems: "center",
            color: "inherit",
            cursor: "default",
          }}
        >
          <IconButton size="small" color="inherit">
            <HomeIcon fontSize="small" />
          </IconButton>
          {navigation}
        </Link> */}
      </Breadcrumbs>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          marginLeft: "132px",
          marginRight: "1px",
          marginTop: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            marginBottom: "10px",
            marginLeft: "25px",
            marginRight: "25px",
            alignContent: "right",
            zIndex: 1, // Ensure it's above other elements
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {showGoBackButton && (
              <Box sx={{ display: "flex" }}>
                  {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" &&
                  <>
                <Box sx={{ color: "#005AC1", marginTop: "2px" }}>
                  {" "}
                  <ArrowBackIcon
                    sx={{ fontSize: 20, cursor: "pointer" }}
                    onClick={handleGoBack}
                  />
                </Box>
                <Box sx={{ marginTop: "2px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#005AC1",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={handleGoBack}
                  >
                    Go back
                  </Typography>
                </Box>
                </>
              }
              </Box>
            )}
          </Box>

          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              backgroundColor: "#005AC1",
              marginLeft: "20px",
            }}
            startIcon={<AddIcon />}
            aria-controls="new-menu"
            aria-haspopup="true"
            onClick={handleNewFile}
          >
            File Upload
          </Button>

          {/* <Menu
            id="new-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MenuItem onClick={handleNewFile}>
              <ListItemIcon>
                <CloudUploadIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>File Upload</ListItemText>
            </MenuItem>
            <MenuItem onClick={handleNewFolder}>
              <ListItemIcon>
                <CreateNewFolderIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Create Folder</ListItemText>
            </MenuItem>
          </Menu> */}

          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{"Upload Document"}</DialogTitle>
            <DialogContent
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems:'center'
              }}
            >
              <FormControl style={{margin:"10px 0 10px 0px",width:"94%"}}>
        <InputLabel id="demo-simple-select-label">Category*</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={category}
          label="Category"
          onChange={handleCategoryChange}
        >
          {categoryData &&
          categoryData.map((data,index)=>(
          <MenuItem value={data.name}>{data.name}</MenuItem>
          ))
          }
        </Select>
      </FormControl>
              <Button
                onClick={handleUploadModalClick}
                style={{ textTransform: "none", color: "grey" }}
              >
                <DropZone
                  onDrop={handleDrop}
                  onDragOver={handleDragOver}
                  onDragLeave={handleDragLeave}
                >
                  <CloudUploadIcon fontSize="large" color="action" />
                  <Typography
                    variant="body1"
                    color="textSecondary"
                    align="center"
                  >
                    <span style={{ color: "#005AC1", fontWeight: "bold" }}>
                      Click to upload
                    </span>{" "}
                    or drag and drop <br></br> PDF files (Max 20 MB)
                  </Typography>
                </DropZone>
              </Button>
              <input
                type="file"
                hidden
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
              <span>
                {uploadDocument
                  ? "✅File Selected: " + uploadDocument.name
                  : ""}
              </span>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose}>Cancel</Button>
              <LoadingButton
                onClick={handleModalSubmit}
                autoFocus
                variant="contained"
                loading={loadingBTN}
                disabled={uploadDocument === null ? true : false}
              >
                Submit
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={newFolderDialog}
            onClose={() => setNewFolderDialog(false)}
          >
            <DialogTitle>Create New Folder</DialogTitle>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                id="folderName"
                label="Folder Name"
                type="text"
                placeholder="Enter new folder name"
                fullWidth
                variant="outlined"
                value={newFolderName}
                onChange={handleNewFolderNameChange}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setNewFolderDialog(false)}>Cancel</Button>
              <LoadingButton
                loading={loadingBTN}
                variant="contained"
                onClick={handleNewFolderSubmit}
                disabled={newFolderName === "" ? true : false}
              >
                Create
              </LoadingButton>
            </DialogActions>
          </Dialog>

          <Dialog
            open={deleteConfirmationDialog}
            onClose={handleDeleteCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Confirm Delete</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this file "{fileToDelete}"?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDeleteCancel}>Cancel</Button>
              <LoadingButton
                loading={loadingBTN}
                onClick={handleDeleteConfirmation}
                autoFocus
                variant="contained"
              >
                Delete
              </LoadingButton>
            </DialogActions>
          </Dialog>
        </Box>

        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={openSnackBar}
          onClose={handleCloseSnackBar}
          message={snackBarMessage}
          autoHideDuration={5000} // 5 seconds timeout
          key={"bottom" + "center"}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackBar}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />

        <TableContainer
          component={Paper}
          style={{
            boxShadow:
              "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
            maxHeight: "calc(100vh - 200px)",
          }}
        >
          <Table size="small" sx={{ minWidth: 700 }} aria-label="simple table">
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: "999",
                backgroundColor: "#EFF1F3",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  {" "}
                  Name
                </TableCell>
                <TableCell
                align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  {" "}
                  Category
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  {" "}
                  Size
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Last modified
                </TableCell>
                <TableCell
                  align="center"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <Box
                  sx={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",

                    backdropFilter: "blur(5px)", // Apply blur effect
                  }}
                />
              )}

              {/* Show loader */}
              {loading && (
                <CircularProgress
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 9999,
                  }}
                />
              )}
              {!loading &&
              <>
              {file.length === 0 && directorry.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {directorry.map((entry, index) => (
                    <TableRow
                      onClick={() => handleDirectoryDoubleClick(entry.id)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#E5E7EB" },
                      }}
                    >
                      <TableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <FolderIcon
                            sx={{ color: "yellow", marginRight: "20px" }}
                          />{" "}
                          {entry.name}
                        </Box>
                      </TableCell>
                      <TableCell >
                        --


                      </TableCell>
                      <TableCell align="left">--</TableCell>
                      <TableCell align="left">{entry.last_modified}</TableCell>
                      <TableCell align="center">--</TableCell>
                    </TableRow>
                  ))}
                  {file.map((item, index) => (
                    <TableRow
                      onClick={() => handleRowClick(item.id)}
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#E5E7EB" },
                      }}
                    >
                      <TableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {" "}
                          {/* <Checkbox
                            icon={<StarBorder />}
                            checkedIcon={<Star />}
                            checked={item.favourite === "yes"}
                            onClick={(event) =>
                              handleStarClick(
                                index,
                                event,
                                item.id,
                                item.name,
                                item.size,
                                account_id,
                                item.last_modified
                              )
                            }
                            sx={{
                              color:
                                item.favourite === "yes" ? "yellow" : "inherit",
                            }}
                          /> */}
                          <PictureAsPdfIcon
                            sx={{ color: "red", marginRight: "20px" }}
                          />
                          {item.name}
                        </Box>
                      </TableCell>
                      <TableCell >
                        {!item.category &&
                        <Button style={{textDecoration:"underline",color:'blue',
                            fontSize:"11px"
                        }}
                        onClick={(event)=>{
                          event.stopPropagation();
                          handleShowCategory(item.id,index)}}
                        >show category</Button>
                        }
                        {item.category &&
                        <>{item.category}</>
                    }

                      </TableCell>
                      <TableCell align="left">{item.size}</TableCell>
                      <TableCell align="left"> {item.last_modified}</TableCell>
                      <TableCell align="left">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {downloadState === "loading" &&
                          item.id === clickedRowIndex ? (
                            <CircularProgress size={24} />
                          ) : (
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                setDownloadState("loading");
                                setClickedRowIndex(item.id);
                                downloadFile(item.name);
                              }}
                            >
                              <DownloadIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={(event) => {
                              event.stopPropagation();
                              deleteFile(item.name,item.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  {/* Add more rows as needed */}
                </>
              )}
              </>
              }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Sidebar />
    </Box>
  );
};

export default RepositoryConnected;
