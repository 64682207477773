import { TextField, InputAdornment, DialogTitle } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import AdminSidebar from "../Includes/AdminSidebar.jsx";
import Sidebar from "../../Includes/Sidebar.jsx";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import Chip from "@mui/material/Chip";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import DeleteIcon from '@mui/icons-material/Delete';
import {
  MenuItem,
  FormControl,
  InputLabel,
  Snackbar,
  Button,
  Select,
  IconButton,
  CircularProgress,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Checkbox from "@mui/material/Checkbox";
import {
  CreateDocType,
  DeleteDocType,
  EditDocType,
} from "../../ApiHelper/categoryAndType.js";
import { styled } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom"; // Import useNavigate
import styles from "./managedoc.module.css";
import { useParams } from "react-router-dom";
import RemoveIcon from "@mui/icons-material/Remove";
import EditIcon from '@mui/icons-material/Edit';
import {
  addDocumentEntity,
  editDocumentEntity,
  entityChangeStatus,
  setEntityOrder,
  deleteEntity
} from "../AdminConsoleApiHelper/AdminApiHelper.js";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 14,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 10,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const ManageDocEntity = () => {
  const location = useLocation();
  // const { selectedValue } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [metaKey, setMetaKey] = useState("");
  const [dataType, setDataType] = useState("");
  const [description, setDescription] = useState("");
  const [Loadingtable, setLoadingtable] = useState(true);
  const [UserDetails, setUserdetails] = useState([]);
  const [users, setUsers] = useState([]);
  // const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [tempMetaKey, setTempMetaKey] = useState("");
  const [tempDataType, setTempDataType] = useState("");
  const [tempId, setTempId] = useState("");
  const [tempDescription, setTempDescription] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const selectedValue = location.state?.selectedValue;
  const selectedName = location.state?.selectedName;
  const selectedSecondDropdownId = location.state?.selectedSecondDropdownId;
  const selectedSecondDropdownValue =
    location.state?.selectedSecondDropdownValue;

  const [photo, setphoto] = useState();
  // const [responseMsg, setResponseMsg] = useState("");
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [docTypeDetailData, setDocTypeDetailData] = useState("");
  const [columnData, setColumnData] = useState([]);


  const [inputValue, setInputValue] = useState("");
  const [tempInputValue, setTempInputValue] = useState("");
  const [tags, setTags] = useState([]);
  const [tempTags, setTempTags] = useState([]);
  const [inputSecondValue, setSecondInputValue] = useState("");
  const [tempSecondInputValue, setSecondTempInputValue] = useState("");
  const [secondtags, setSecondTags] = useState([]);
  const [secondtempTags, setSecondTempTags] = useState([]);
  const [isSecondLanguagePresent,setIsSecondLanguagePresent]=useState(false)
  const [displayedExistingData, setDisplayedExistingData] = useState(false);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [name, setname] = useState("");
  const [desc, setDesc] = useState("");

  const [checkedEnglish, setCheckedEnglish] = React.useState(false);
  const [checkedArabic, setCheckedArabic] = React.useState(false);
  const [checkedAzerbaijani, setCheckedAzerbaijani] = React.useState(false);

  const [inputColumnValue, setInputColumnValue] = useState("");
  const [columnNames, setColumnNames] = useState("");
  const [columnAlias, setColumnAlias] = useState([]);
  const [viewColumnDialog, setViewColumnDialog] = useState(false);

  const [allowCreate,setAllowCreate]=useState("no")

  const handleSnakebarClick = () => {
    setSnackBarOpen(true);
  };
  const handleSave = async (cat_id) => {
    // try {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;

    const res = await CreateDocType(
      userToken,
      user_id,
      org_id,
      cat_id,
      name,
      photo
    );
    if (res.code === "200") {
      setOpen(false);
      setSnackbarMessage(res.message);
      handleSnakebarClick();
      GetDocEntity(cat_id);
    } else {
      setOpen(false);
      setSnackbarMessage(res.message);
      handleSnakebarClick();
    }
    // GetCatType();
    console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };
  const handleCloseModal = () => {
    setInputValue("")
    setSecondInputValue("")
    setTags([])
    setSecondTags([])

    setOpenModal(false);
  };
  const handleCloseEditModal = () => {
    setOpenEditModal(false);
  };
  const convertToArray = (string) => {
    if (string !== undefined) {
      return string.split(",");
    }
    return [""];
  };

  const EditModal = (event, user) => {
    setOpenEditModal(true);
    setTempMetaKey(user.meta_key);
    setTempDataType(user.entity_datatype);
    setTempDescription(user.meta_description);
    console.log(user)
    setTempTags(user.data_extraction_alias[docTypeDetailData.ext_language.split(",")[0]].split(", ").map((tag) => tag.trim()));
    if(isSecondLanguagePresent==true){
      setSecondTempTags(user.data_extraction_alias[docTypeDetailData.ext_language.split(",")[1]].split(", ").map((tag) => tag.trim()));
    }
    // setTempInputValue(user.data_extraction_alias);
    setTempId(user.id);
    setColumnData(user.table);
    setColumnAlias("");
    setColumnNames("");
  };

  const openSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackBarOpen(true);
  };
  // Use useNavigate
  const handlebacknavigation = () => {
    // Perform any necessary actions before navigating
    // For now, let's just navigate to "/inner-page"
    navigate("/admin-console/document-category");
  };
  // Replace with your actual data types

  const [switchState, setSwitchState] = useState({});
  const [status, setStatus] = useState("");
  const HandleDeleteEntity = async (id) => {
  
    try {
      // console.log('status after',status);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await deleteEntity(
        user_id,
        userorg_id,
        id,
        userToken
      );
      if (res.code == 200) {
        setSnackbarMessage(res.message);
        handleSnakebarClick();
        GetDocEntity();
      }
      else{
        setSnackbarMessage(res.message);
        handleSnakebarClick();
      }
    } catch (error) {
      // console.error("Error:", error);
      setLoadingtable(true);
      setSnackbarMessage("Server Error");
      handleSnakebarClick();
    }
  };
  const HandleEntityStatusChange = async (switchType, userid, userStatus) => {
    // let statuss='';
    console.log("userStatus", userStatus);
    setTempId(userid);
    //   if(userStatus === false){

    //     //  statuss = 'no'
    //     console.log('status from false')
    //     setStatus('no')
    //   }
    //   if(userStatus === true){

    //    //  statuss = 'no'
    //    console.log('status from true')
    //    setStatus('yes')
    //  }
    try {
      // console.log('status after',status);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      console.log("status", status);
      console.log("userid", tempId);
      const res = await entityChangeStatus(
        user_id,
        userorg_id,
        selectedValue,
        selectedSecondDropdownId,
        userid,
        userStatus,
        switchType,
        userToken
      );
      if (res.code == 200) {
        console.log("status changed");
        GetDocEntity();
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(true);
    }
  };
  const [entityStatus, setEntityStatus] = useState("");
  const handleSwitchChange = (switchType, userId, userStatus) => (event) => {
    // setEntityStatus(event.target.checked)
    if (event.target.checked == "false") {
      setEntityStatus("yes");
    }
    if (event.target.checked == "true") {
      setEntityStatus("no");
    }
    console.log("Switch changed:", event.target.checked);
    // console.log('status from before:', entityStatus);
    if (event.target.checked) {
      HandleEntityStatusChange(switchType, userId, "yes");
    } else {
      HandleEntityStatusChange(switchType, userId, "no");
    }
  };

  useEffect(() => {
    setLoadingtable(true);
    GetDocEntity();
  }, []);
  const GetDocEntity = async () => {
    // console.log(selectedValue);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    let responsedata = {
      user_id: user_id,
      org_id: userorg_id,
      category_id: selectedValue,
      documenttype_ref_id: selectedSecondDropdownId,
    };
    const headers = {
      token: userToken,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        `${window.constants.api_url}/admin-console/getallentity`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(responsedata),
        }
      );

      const res = await response.json();
      // console.log(res)
      if (res.code === "200") {
        console.log(res.data);
        setUserdetails(res.data);
        setAllowCreate(res.allow_create	)
        // res.doctype_details.doctype_alias=res.doctype_details.doctype_alias.split(',');
        // res.doctype_details.ext_language=res.doctype_details.ext_language.split(',');
        const extractedOrderNumbers = res.data.map((user) => user.order_number);
        setOrderNumbers(extractedOrderNumbers);
       
        setDocTypeDetailData(res.doctype_details);
        if(res.doctype_details.ext_language.split(",").length>1){
          setIsSecondLanguagePresent(true)
        }
        else{
          setIsSecondLanguagePresent(false)
        }
        // console.log("PRINT ARRAY", docTypeDetailData);
        setLoadingtable(false);
        if(res.doctype_details.ext_language.includes('english')){
          setCheckedEnglish(true)
        }
        if(res.doctype_details.ext_language.includes('arabic')){
          setCheckedArabic(true)
        }
        if(res.doctype_details.ext_language.includes('azerbaijani(latin)')){
          setCheckedAzerbaijani(true)
        }
        // console.log(checkedAzerbaijani,checkedArabic)
        // const order = res.data.order_number
        // setOrderNumbers((index)=>(res.data.order_number)
        //   )
        //   console.log('orderno:',orderNumbers)
      } else {
        // Handle the error
        console.error("Error:");
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(true);
    }
  };
  useEffect(() => {
    // Initialize tempTags with existing data when the component mounts
    const existingData = tempInputValue; // Replace with your actual existing data
    const existingDataTags = existingData
      .split(",")
      .map((tag) => tag.trim())
      .filter((tag) => tag !== "");
    
    const existingSecondData = tempSecondInputValue; // Replace with your actual existing data
    const existingSecondDataTags = existingSecondData
        .split(",")
        .map((tag) => tag.trim())
        .filter((tag) => tag !== "");

    setSecondTags(existingSecondDataTags);
    setDisplayedExistingData(true);
  }, []); // Empty dependency array ensures this effect runs only once on mount

  const handleOpen = () => {
    setname(docTypeDetailData.doctype_name);
    setDesc(docTypeDetailData.doctype_desc);
    setTags(docTypeDetailData.doctype_alias.split(","));
    // Clear the input value
    setInputValue("");
    // handleKeyPress()
    setOpen(true);
  };
  const handleClose = () => setOpen(false);
  const handleInputChange = (event,language_index) => {
    if(language_index=='second'){
        setSecondInputValue(event.target.value)
    }
    else{
      setInputValue(event.target.value);
    }
  };
  const handleTempInputChange = (event,language_index) => {
    // setTempInputValue(event.target.value);
    if(language_index=='second'){
      setSecondTempInputValue(event.target.value)
  }
  else{
    setTempInputValue(event.target.value);
  }
  };
  const handleKeyPress = (event,language_index) => {
    if(language_index=='second'){
      if (event.key === "Enter" && inputSecondValue.trim() !== "") {
        // Add the tag to the list
        setSecondTags((prevTags) => [...prevTags, inputSecondValue.trim()]);
        // Clear the input value
        setSecondInputValue("");
      }
    }
    else{
      if (event.key === "Enter" && inputValue.trim() !== "") {
        // Add the tag to the list
        setTags((prevTags) => [...prevTags, inputValue.trim()]);
        // Clear the input value
        setInputValue("");
      }
    }
    
  };
  const handleKeyPressColumn = (event) => {
    if (event.key === "Enter" && inputColumnValue.trim() !== "") {
      // Add the tag to the list
      setColumnAlias((prevTags) => [...prevTags, inputColumnValue.trim()]);
      // Clear the input value
      setInputColumnValue("");
    }
  };

  const handleDeleteTag = (tagToDelete,language_index) => {
    // Remove the tag from the list
    if(language_index=='second'){
      setSecondTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    }
    else{
      setTags((prevTags) => prevTags.filter((tag) => tag !== tagToDelete));
    }
  };
  const handleColumnDeleteTag = (tagToDelete) => {
    // Remove the tag from the list
    setColumnAlias((prevTags) =>
      prevTags.filter((columnAlias) => columnAlias !== tagToDelete)
    );
  };
  const handleTempKeyPress = (event,language_index) => {
    if(language_index=='second'){
      if (event.key === "Enter" && tempSecondInputValue.trim() !== "") {
        setSecondTempTags((prevTags) => [...prevTags, tempSecondInputValue.trim()]);
        setSecondTempInputValue("");
        setDisplayedExistingData(true);
        event.preventDefault();
      }
    }
    else{
      if (event.key === "Enter" && tempInputValue.trim() !== "") {
        setTempTags((prevTags) => [...prevTags, tempInputValue.trim()]);
        setTempInputValue("");
        setDisplayedExistingData(true);
        event.preventDefault();
      }
    }
    
  };
  const handleTempDeleteTag = (tempTagToDelete,language_index) => {
    // Check if the tag being deleted is part of the existing data
    if(language_index=='second'){
      const isExistingDataTag = secondtempTags.includes(tempTagToDelete);

      setSecondTempTags((prevTags) =>
        prevTags.filter((tempTag) => tempTag !== tempTagToDelete)
      );
      if (isExistingDataTag) {
        setDisplayedExistingData(false);
      }
    }
    else{
      const isExistingDataTag = tempTags.includes(tempTagToDelete);

      setTempTags((prevTags) =>
        prevTags.filter((tempTag) => tempTag !== tempTagToDelete)
      );
      if (isExistingDataTag) {
        setDisplayedExistingData(false);
      }
    }
    
  };
  const getDifferentLanguageAliasObject=(tags,secondtags)=>{
    let obj={}
    if(isSecondLanguagePresent==true){
      obj[docTypeDetailData.ext_language.split(",")[1]]=secondtags.join(", ")
    }
    obj[docTypeDetailData.ext_language.split(",")[0]]=tags.join(", ")
    
    return obj;
  }
  const AddDocumentEntity = async () => {
    console.log("ADD API CALL")
    setButtonLoading(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await addDocumentEntity(
        user_id,
        userorg_id,
        metaKey,
        description,
        getDifferentLanguageAliasObject(tags,secondtags),
        dataType,
        selectedValue,
        selectedSecondDropdownId,
        userToken,
        columnData
      );

      if (res.code === "200") {
        setButtonLoading(false);
        setMetaKey("");
        setDataType("");
        setDescription("");
        setTags([]);
        setSecondTags([])
        setColumnAlias("");
        setColumnNames("");
        setColumnData([]);
        GetDocEntity();
        // setLoadingtable(false);
        console.log(res.data);
        setOpenModal(false);
        openSnackbar(res.message);
        // setUserdetails(res.data)
      } else {
        // Handle the error
        setButtonLoading(false);
        console.error("Error:");
        openSnackbar(res.message);
      }
    } catch (error) {
      setButtonLoading(false);
      console.error("Error:", error);
    }
  };
  const handlelanguage=()=>{
    console.log(checkedArabic,checkedEnglish)
    if(checkedEnglish==true && checkedArabic==true){
       return "english,arabic"
     }
     if(checkedEnglish==true && checkedAzerbaijani==true){
      return "english,azerbaijani(latin)"
    }
     else if(checkedArabic==true && checkedAzerbaijani==true){
      return "arabic,azerbaijani(latin)"
     }
     else if(checkedArabic==true){
      return "arabic"
     }
     else if(checkedAzerbaijani==true){
      return "azerbaijani(latin)"
     }
     else if(checkedEnglish==true){
      return "english"
     }
     else{
      return ""
     }
  }
  const handleEditDocType = async () => {
    // try {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const org_id = userDataObj.org_id;
    // console.log(checkedArabic,desc,checkedEnglish,checkedArabic,tags)
    // if(checkedArabic==true && checkedEnglish==true && checkedAzerbaijani==true){
    //   handleSnakebarClick();
    //   setSnackbarMessage("Please select at most two languages");
    //   return ;
    // }
    await setTimeout(handlelanguage, 1000);

    const res = await EditDocType(
      userToken,
      user_id,
      org_id,
      selectedSecondDropdownId,
      name,
      photo,
      desc,
      tags.join(", "),
      docTypeDetailData.ext_language
    );
    if (res.code === "200") {
      setOpen(false);
      setSnackbarMessage(res.message);
      handleSnakebarClick();
      GetDocEntity();
      // GetDocEntity(currCatId);
    } else {
      setOpen(false);
      setSnackbarMessage(res.message);
      handleSnakebarClick();
    }
    // GetCatType();
    console.log(res);
    // } catch {
    //   console.log("Error Occur");
    // }
  };

  const EditDocumentEntity = async () => {
    setButtonLoading(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;
      const res = await editDocumentEntity(
        user_id,
        userorg_id,
        tempMetaKey,
        tempDescription,
        getDifferentLanguageAliasObject(tempTags,secondtempTags),
        tempDataType,
        selectedValue,
        selectedSecondDropdownId,
        tempId,
        userToken,
        columnData
      );

      if (res.code === "200") {
        setButtonLoading(false);
        setTempMetaKey("");
        setTempDataType("");
        setTempDescription("");
        setTempTags([]);
        setSecondTempTags([]);
        GetDocEntity();
        handleCloseEditModal();
        // setLoadingtable(false);
        console.log(res.data);
        setOpenModal(false);
        openSnackbar(res.message);
        // setUserdetails(res.data)
      } else {
        // Handle the error
        setButtonLoading(false);
        console.error("Error:");
        openSnackbar(res.message);
      }
    } catch (error) {
      setButtonLoading(false);
      console.error("Error:", error);
    }
  };
  const handleNewColumnClick = () => {
    setColumnData([
      ...columnData,
      { column_name: columnNames, column_alias: columnAlias.join(",") },
    ]);
    setColumnAlias("");
    setColumnNames("");
  };
  const handleDeleteColumn = (idx) => {
    const newArray = [
      ...columnData.slice(0, idx), // Elements before the one to delete
      ...columnData.slice(idx + 1), // Elements after the one to delete
    ];
    setColumnData(newArray);
  };
  const SetDocumentEntityOrder = async (order) => {
    setButtonLoading(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;
      const res = await setEntityOrder(
        user_id,
        userorg_id,
        selectedValue,
        order,
        userToken
      );

      if (res.code === "200") {
        setButtonLoading(false);
        GetDocEntity();
        // setLoadingtable(false);

        openSnackbar(res.message);
        // setUserdetails(res.data)
      } else {
        // Handle the error
        setButtonLoading(false);
        console.error("Error:");
        openSnackbar(res.message);
      }
    } catch (error) {
      setButtonLoading(false);
      console.error("Error:", error);
    }
  };
  const [orderNumbers, setOrderNumbers] = useState([]);
  // const [orderNumbers, setOrderNumbers] = useState(UserDetails.map((user,index) => user.order_number));
  console.log("orderno:", orderNumbers);

  const handleSetOrder = (userDetails) => {
    const changedOrders = [];
    // Log orderNumbers along with corresponding meta_key
    userDetails.forEach((user, index) => {
      const changedOrderEntry = `${user.id}-${orderNumbers[index]}`;
      changedOrders.push(changedOrderEntry);
    });
    console.log(changedOrders);
    SetDocumentEntityOrder(changedOrders);
  };
  function disableBodyScroll() {
    document.body.style.overflow = "hidden";
  }

  function enableBodyScroll() {
    document.body.style.overflow = "auto";
  }
  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   disableBodyScroll();

  //   // Return a cleanup function to enable body scroll when the component unmounts
  //   return () => {
  //     enableBodyScroll();
  //   };
  // }, []);

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        backgroundColor: "#EFEDF1",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <div style={{overflow:'auto'}}>
      <Box sx={{ display: "flex" ,marginTop: "70px",marginBottom:"10px",marginLeft:"135px"}}>
      <ArrowBackIcon
              sx={{
                color: "#005AC1",
                fontSize: 25,
                cursor: "pointer",
                marginTop: "6px",
              }}
              onClick={handlebacknavigation}
            />
            <Typography
              sx={{
                fontSize: "18px",
                color: "#005AC1",
                fontWeight: "500",
                cursor: "pointer",
                marginTop: "6px",
              }}
              onClick={handlebacknavigation}
            >
              Go back
            </Typography>
            
          </Box>
      <Box
        sx={{
          textAlign: "left",
          marginLeft: "132px",
          marginRight: "3px",
          
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          background: "white",
          // margin:"10px",
          padding: "10px",
          marginBottom: "10px",
          borderRadius: "10px",
        }}
      >
        
        {/* <div> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "10px",
            }}
          >
            <span style={{ fontSize: ".9rem", color: "blue" }}>Doc Type</span>
            <span style={{ fontSize: "1.1rem" }}>
              {docTypeDetailData.doctype_name}
            </span>
          </div>
          <div>
      {allowCreate==="yes" &&

            <Button style={{ border: "1px solid blue" }} onClick={handleOpen}>
              Edit Details
            </Button>
          }
          </div>
        </div>
        <div style={{ marginBottom: "10px" }}>
          <span style={{ fontSize: ".9rem", fontWeight: "bold" }}>
            Doc Type Description: &nbsp;
          </span>
          <span>{docTypeDetailData.doctype_desc}</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
          <span style={{ fontSize: ".9rem", fontWeight: "bold" }}>
            Key Contents
          </span>
          <div style={{ marginLeft: "24px" }}>
            <span style={{ marginRight: "3px" }}>:</span>
          {docTypeDetailData && 
          <>
            {convertToArray(docTypeDetailData.doctype_alias).map(
              (data, idx) => (
                
                <span
                  style={{
                    background: "rgba(0, 0, 0, 0.5)",
                    color: "white",
                    borderRadius: "10px",
                    marginRight: "4px",
                    padding: "0px 5px 0px 5px",
                  }}
                >
                  {data}
                </span>
              
              )
            )}
            </>
          }
            
            {/* <span style={{background:"rgba(0, 0, 0, 0.5)",color:"white",borderRadius:"10px", marginRight:"4px",padding:"2px"}}>Option1</span>
          <span style={{background:"rgba(0, 0, 0, 0.5)",color:"white",borderRadius:"10px", marginRight:"4px",padding:"2px"}}>Option1</span> */}
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span style={{ fontSize: ".9rem", fontWeight: "bold" }}>
            Languages
          </span>
          <div style={{ marginLeft: "40px" }}>
            <span style={{ marginRight: "3px" }}>:</span>
            {docTypeDetailData && 
            <>
            {convertToArray(docTypeDetailData.ext_language).map((data, idx) => (
              <span
                style={{
                  background: "rgba(216, 226, 255, 1)",
                  color: "rgba(0, 90, 193, 1)",
                  borderRadius: "10px",
                  marginRight: "4px",
                  padding: "0px 5px 0px 5px",
                }}
              >
                {data}
              </span>
            ))}
            </>}
            {/* <span style={{background:"rgba(216, 226, 255, 1)",color:"rgba(0, 90, 193, 1)",borderRadius:"10px", marginRight:"4px",padding:"2px"}}>Option1</span>
          <span style={{background:"rgba(216, 226, 255, 1)",color:"rgba(0, 90, 193, 1)",borderRadius:"10px", marginRight:"4px",padding:"2px"}}>Option1</span> */}
          </div>
        </div>

        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          sx={{
            "& .MuiDialog-paper": {
              borderRadius: "16px",
              width: "100%",
              display: "flex",

              alignItems: "flex-end",
              flexDirection: "column",
            },
          }}
        >
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-betwwen",
                alignItems: "flex-end",
                marginBottom: "30px",
              }}
            >
              <Typography variant="h6">
                Edit {docTypeDetailData.doctype_name} details
              </Typography>
              <Box position="absolute" left="90%">
                <IconButton>
                  <CloseIcon onClick={handleClose} />
                </IconButton>
              </Box>
            </Box>
            {/* Your form for creating a new user goes here */}
            <Box
              display="flex"
              flexGrow={1}
              style={{ flexDirection: "column" }}
            >
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <Box
                  fontWeight="bold"
                  sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
                >
                  Name
                  {/* Red mandatory star icon */}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box sx={{ flex: 4, marginLeft: "1%" }}>
                  <TextField
                    label="Name"
                    sx={{ width: "400px", marginRight: "15px" }}
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    InputProps={{ sx: { borderRadius: "8px" } }}
                    disabled
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <Box
                  fontWeight="bold"
                  sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
                >
                  Desc
                  {/* Red mandatory star icon */}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box sx={{ flex: 4, marginLeft: "2%", marginTop: "10px" }}>
                  <TextField
                    label="Description"
                    sx={{ width: "400px", marginRight: "15px" }}
                    value={desc}
                    onChange={(e) => setDesc(e.target.value)}
                    InputProps={{ sx: { borderRadius: "8px" } }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                <Box
                  fontWeight="bold"
                  sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
                >
                  Keywords
                  {/* Red mandatory star icon */}
                  <span style={{ color: "red" }}>*</span>
                </Box>
                <Box style={{ display: "flex", flexWrap: "wrap" }}>
                  <TextField
                    // label="Enter Alias Name"

                    placeholder="Enter Alias Name"
                    variant="outlined"
                    sx={{ width: "400px", marginRight: "15px" }}
                    margin="normal"
                    value={inputValue}
                    onChange={handleInputChange}
                    onKeyDown={handleKeyPress}
                    helperText="Please hit enter to add the keyword after typing"
                    InputProps={{
                      style: {
                        display: "flex",
                        flexWrap: "wrap",
                        borderRadius: "10px",
                      },
                      startAdornment: Array.isArray(tags)
                        ? tags.map((tag) => (
                            <Chip
                              color="primary"
                              key={tag}
                              label={tag}
                              onDelete={() => handleDeleteTag(tag)}
                              style={{ marginTop: "10px" }}
                            />
                          ))
                        : null,
                    }}
                  />
                </Box>
              </Box>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                sx={{ width: "100%" }}
              >
                {/* <Box
                  fontWeight="bold"
                  sx={{ flex: 1, marginRight: "50px", marginTop: "15px" }}
                >
                  Select Languages
               
                  <span style={{ color: "red" }}>*</span>
                </Box> */}
                {/* <Box>
                  <FormGroup
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedEnglish}
                          onChange={async (e) => {
                            await setCheckedEnglish(e.target.checked);
                          }}
                          
                        />
                      }
                      label="English"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedArabic}
                          onChange={async (e) => {
                            await setCheckedArabic(e.target.checked);
                          }}
                          
                        />
                      }
                      label="Arabic"
                    />
                    <FormControlLabel control={<Checkbox   checked={checkedAzerbaijani}
              onChange={async (e)=>{
               await setCheckedAzerbaijani(e.target.checked)
               
              }}
                />} label="azerbaijani(latin)" />
                  </FormGroup>
                </Box> */}
              </Box>
              <div className="image_div">
                <span>Add Image:</span>
                <input
                  type="file"
                  id="file-input"
                  onChange={(e) => {
                    setphoto(e.target.files[0]);
                  }}
                />
              </div>
            </Box>

            <DialogActions
              sx={{
                marginBottom: "10px",
                marginTop: "15px",
                marginRight: "0",
                gap: "14px",
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{ textTransform: "none", fontWeight: "bold" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                sx={{ textTransform: "none", fontWeight: "bold" }}
                onClick={() => {
                  handleEditDocType();
                }}
                backgroundColor={buttonLoading && "#616161"}
                disabled={buttonLoading}
              >
                {buttonLoading && <CircularProgress size={16} />}Save
              </Button>
            </DialogActions>
          </DialogContent>
        </Dialog>

        {/* </div> */}
      </Box>
      <Box
        sx={{
          textAlign: "left",
          marginLeft: "150px",
          marginRight: "10px",
          // marginTop: "100px",
          display: "flex",
          justifyContent: "flex-start",
        }}
      >
        <Box
          sx={{ display: "flex",  flexDirection:"row-reverse"}}
          flexGrow={1}
        >
          
          <Box >
      {allowCreate==="yes" &&

            <Button
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                marginRight: "Opx",
              }}
              variant="contained"
              color="primary"
              onClick={() => {
                setTags([]);
                setOpenModal(true);
              }}
              startIcon={<AddIcon />}
            >
              Add new entity
            </Button>
          }
          </Box>
        </Box>
      </Box>
      {/* <Box sx={{ textAlign: 'left', marginLeft: '150px', marginRight: '50px', marginTop: '15px', display: 'flex', justifyContent: 'space-between' }}> */}
      {/* <Box> */}
      {/* <Typography sx={{ fontSize: '22px', color: '#606060', fontWeight: '500' }}>Document Type: {selectedName} </Typography> */}
      {/* <Typography sx={{ fontSize: '16px', color: '#606060', fontWeight: '500' }}>Organisation: HINDALCO</Typography> */}
      {/* </Box> */}

      {/* <Button sx={{ textTransform: 'none', fontWeight: 'bold', marginRight: 'Opx' }} variant="contained" color="primary" onClick={() => setOpenModal(true)} startIcon={<AddIcon />} >
          Add new entity
        </Button> */}

      {/* </Box> */}

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          marginLeft: "132px",
          marginRight: "2px",
          marginTop: "20px",
        }}
      >
        <TableContainer
          component={Paper}
          style={{
            boxShadow:
              "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
            marginBottom: "25px",
            // maxHeight: "calc(100vh - 400px)",
          }}
        >
          <Table size="small" sx={{ minWidth: 700 }} aria-label="simple table">
            <TableHead
              sx={{
                position: "sticky",
                top: 0,
                zIndex: "999",
                backgroundColor: "#EFF1F3",
              }}
            >
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Sequence Number
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Meta Key
                </TableCell>
                {/* <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Description</TableCell> */}
                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Alias
                </TableCell>
                {allowCreate==="yes" &&
                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Status
                </TableCell>
                }
                {/* {localStorage.getItem('userdata') &&  JSON.parse(localStorage.getItem('userdata')).superadmin==="yes" && */}
                {allowCreate==="yes" &&

                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Action
                </TableCell>
                }
                {/* } */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Loadingtable && (
                <TableCell colSpan={6} align="center" sx={{ width: "1000px" }}>
                  <CircularProgress />
                </TableCell>
              )}

              {!Loadingtable && (
                <>
                  {UserDetails.map((user, indexx) => (
                    <TableRow key={indexx}>
                      <TableCell>
                        <TextField
                          style={{ maxWidth: "50px" }}
                          type="text"
                          key={indexx}
                          value={orderNumbers[indexx]}
                          onChange={(e) => {
                            // Allow only numeric input
                            const numericValue = e.target.value.replace(
                              /[^0-9.]/g,
                              ""
                            );
                            const updatedOrderNumbers = [...orderNumbers]; // Create a copy of the array
                            updatedOrderNumbers[indexx] = numericValue; // Update the value at the specific index
                            setOrderNumbers(updatedOrderNumbers); // Update the state
                          }}
                        />
                      </TableCell>
                      <TableCell>{user.meta_key}</TableCell>
                      {/* <TableCell>{user.meta_description}</TableCell> */}
                      <TableCell align="left">
                        
                          {Object.keys(user.data_extraction_alias).map((keys,index)=>(
                            <div style={{display:"flex",flexDirection:"row",alignItems:"center"}} key={index}> 
                              <div style={{fontWeight:"600",minWidth:"70px"}}>
                              {keys}: 
                              </div>
                              <div >
                                {user.data_extraction_alias[keys]!=="" && user.data_extraction_alias[keys].split(", ").map((alias,indexx)=>(
                                 
                                  <Chip
                                  key={indexx}
                                  style={{ margin: "6px" }}
                                  label={alias.trim()} // trim to remove leading/trailing spaces
                                  sx={{
                                    color: "#141B2C",
                                    fontWeight: "bold",
                                    backgroundColor: "#E3E2E6",
                                  }}
                                  // onClick={handleClick}
                                  // disabled
                                />
                               
                                ))}
                              </div>
                            </div>
                          ))

                          }
                          {/* {user.data_extraction_alias
                            .split(",")
                            .map((alias, index) => (
                              <Chip
                                key={index}
                                style={{ margin: "6px" }}
                                label={alias.trim()} // trim to remove leading/trailing spaces
                                sx={{
                                  color: "#141B2C",
                                  fontWeight: "bold",
                                  backgroundColor: "#E3E2E6",
                                }}
                                // onClick={handleClick}
                                // disabled
                              />
                            ))} */}
                        
                        {
                          user.table.length != 0 && (
                            <div>
                              <Button
                                variant="outlined"
                                onClick={() => {
                                  setViewColumnDialog(true);
                                  setSelectedColumnIndex(indexx);
                                }}
                              >
                                VIEW COLUMN DETAILS
                              </Button>
                              <Dialog
                                open={viewColumnDialog}
                                onClose={() => {
                                  setViewColumnDialog(false);
                                }}
                                sx={{
                                  "& .MuiDialog-paper": {
                                    borderRadius: "16px",
                                    width: "100%",
                                    display: "flex",

                                    // alignItems:'flex-end',
                                    // flexDirection: 'column'
                                  },
                                }}
                              >
                                <DialogTitle>
                                  <Box sx={{ display: "flex" }} flexGrow={1}>
                                    <Typography variant="h6">
                                      Column Details
                                    </Typography>
                                    <Box position="absolute" left="90%">
                                      <IconButton>
                                        <CloseIcon
                                          onClick={() => {
                                            setViewColumnDialog(false);
                                          }}
                                        />
                                      </IconButton>
                                    </Box>
                                  </Box>
                                </DialogTitle>
                                <DialogContent>
                                  {UserDetails[selectedColumnIndex].table.map(
                                    (x, i) => (
                                      <div key={i}>
                                        <hr />
                                        <Box
                                          display="flex"
                                          flexGrow={1}
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                            marginBottom: "17px",
                                          }}
                                        >
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="flex-start"
                                            sx={{ width: "100%" }}
                                          >
                                            
                                            <Box
                                              fontWeight="bold"
                                              sx={{ flex: 1 }}
                                            >
                                              Column {i + 1}
                                              {/* Red mandatory star icon */}
                                              {/* <span style={{ color: "red" }}>*</span> */}
                                            </Box>

                                            <Box
                                              sx={{ flex: 4, marginLeft: "1%" }}
                                              style={{
                                                width: "50px",
                                                height: "50px",
                                              }}
                                            >
                                              {/* <IconButton
                          style={{ marginLeft: "400px" }}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon
                            onClick={() => {
                              handleDeleteColumn(i);
                            }}
                          />
                        </IconButton> */}
                                            </Box>
                                          </Box>
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="flex-start"
                                            sx={{ width: "100%" }}
                                          >
                                            <Box
                                              fontWeight="bold"
                                              
                                            >
                                              Column Name
                                              {/* Red mandatory star icon */}
                                              {/* <span style={{ color: "red" }}>*</span> */}
                                            </Box>
                                            <Box
                                              sx={{ flex: 4, marginLeft: "6%" }}
                                            >
                                              {x.column_name}
                                            </Box>
                                          </Box>
                                          <Box
                                            display="flex"
                                            flexDirection="row"
                                            alignItems="flex-start"
                                            sx={{
                                              width: "100%",
                                              marginTop: "5px",
                                            }}
                                          >
                                            <Box
                                              fontWeight="bold"
                                              sx={{
                                                flex: 1,
                                                marginTop: "25px",
                                                
                                              }}
                                            >
                                              Alias
                                              {/* Red mandatory star icon */}
                                              {/* <span style={{ color: "red" }}>*</span> */}
                                            </Box>
                                            <Box
                                              sx={{ flex: 4, marginLeft: "5%" }}
                                            >
                                              <p>
                                                {x.column_alias
                                                  .split(",")
                                                  .map((tag) => (
                                                    <Chip
                                                      color="primary"
                                                      key={tag}
                                                      label={tag}
                                                      // onDelete={() =>
                                                      //   handleColumnDeleteTag(
                                                      //     tag
                                                      //   )
                                                      // }
                                                      style={{
                                                        marginTop: "10px",
                                                      }}
                                                    />
                                                  ))}
                                              </p>
                                            </Box>
                                          </Box>
                                        </Box>
                                      </div>
                                    )
                                  )}
                                </DialogContent>
                              </Dialog>
                            </div>
                          )
                          // TODO add view column on a dialog box
                        }
                      </TableCell>
                      {user.allow_edit	==="yes" &&
                      <TableCell align="left">
                        <FormGroup>
                          <Box sx={{ display: "flex", gap: "40px" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              Data Extraction Status
                            </Typography>
                            <AntSwitch
                              checked={user.data_extraction_enable === "yes"}
                              onChange={handleSwitchChange(
                                "data_extraction_enable",
                                user.id,
                                user.data_extraction_enable
                              )}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Box>
                        </FormGroup>

                        {/* <FormGroup>
                          <Box sx={{ display: "flex", gap: "25px" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              Display in Column Status
                            </Typography>
                            <AntSwitch
                              checked={user.display_heading_enable === "yes"}
                              onChange={handleSwitchChange(
                                "display_heading_enable",
                                user.id,
                                user.data_extraction_enable
                              )}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Box>
                        </FormGroup>

                        <FormGroup>
                          <Box sx={{ display: "flex", gap: "55px" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              Search Filter Status
                            </Typography>
                            <AntSwitch
                              checked={user.search_filter_enable === "yes"}
                              onChange={handleSwitchChange(
                                "search_filter_enable",
                                user.id,
                                user.data_extraction_enable
                              )}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Box>
                        </FormGroup>

                        <FormGroup>
                          <Box sx={{ display: "flex", gap: "46px" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              Advance Filter Status
                            </Typography>
                            <AntSwitch
                              checked={user.advance_filter_enable === "yes"}
                              onChange={handleSwitchChange(
                                "advance_filter_enable",
                                user.id,
                                user.data_extraction_enable
                              )}
                              inputProps={{ "aria-label": "ant design" }}
                            />
                          </Box>
                        </FormGroup> */}
                      </TableCell>
                    }
                {user.allow_edit	==="yes" &&
                      <TableCell align="left">
                      
                        <Button
                          sx={{
                            color: "#005AC1",
                            fontWeight: "bold",
                            textTransform: "none",
                          }}
                          onClick={(event) => EditModal(event, user)}
                        >
                          <EditIcon/>
                        </Button>
                        <Button
                          sx={{
                            color: "#005AC1",
                            fontWeight: "bold",
                            textTransform: "none",
                          }}
                          onClick={(event) => {HandleDeleteEntity(user.id)}}
                        >
                          <DeleteIcon/>
                        </Button>
                      
                      </TableCell>  
}
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {UserDetails.length == 0 && (
          <div
            style={{
              color: "grey",
              fontSize: "13px",
              marginTop: "20px",
              textAlign: "center",
            }}
          >
            No record found
          </div>
        )}
        <Box sx={{ marginBottom: "50px" }}>
          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "-20px",
              marginBottom: "50px",
              backgroundColor: "#005AC1",
            }}
            variant="contained"
            onClick={() => handleSetOrder(UserDetails)}
            backgroundColor={buttonLoading && "#616161"}
            disabled={buttonLoading}
          >
            {buttonLoading && <CircularProgress size={16} />} Set Sequence
          </Button>
        </Box>
      </Box>
      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: "100%",
            minWidth:"650px",
            display: "flex",

            // alignItems:'flex-end',
            // flexDirection: 'column'
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex" }} flexGrow={1}>
            <Typography variant="h6">Add new document entity</Typography>
            <Box position="absolute" left="90%">
              <IconButton>
                <CloseIcon onClick={handleCloseModal} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* Your form for creating a new user goes here */}
          <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              sx={{ width: "100%", marginTop: "10px" }}
            >
              <Box fontWeight="bold" sx={{ flex: 1, marginTop: "15px" }}>
                Meta Key
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box sx={{ marginLeft: "1%" }}>
                <TextField
                  sx={{ width: "440px" }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  label="Enter Meta Key"
                  value={metaKey}
                  onChange={(e) => setMetaKey(e.target.value)}
                />
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box fontWeight="bold" sx={{ flex: 1 }}>
                Data Type
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box>
                <FormControl fullWidth sx={{ marginTop: "15px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Data type{" "}
                  </InputLabel>
                  <Select
                    sx={{ width: "440px", borderRadius: "10px" }}
                    margin="normal"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={selectedValue}
                    value={dataType}
                    label="Select Data"
                    onChange={(e) => setDataType(e.target.value)}
                  >
                    <MenuItem key={"float"} value={"float"}>
                      Number
                    </MenuItem>
                    <MenuItem key={"string"} value={"string"}>
                      Text
                    </MenuItem>         
                    <MenuItem key={"date"} value={"date"}>
                      Date
                    </MenuItem>
                    <MenuItem key={"table"} value={"table"}>
                      Table
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
          
           
           <Box>
            
          <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box fontWeight="bold" sx={{ flex: 1 }}>
               {isSecondLanguagePresent && docTypeDetailData.ext_language.split(",")[0]} alias
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  // label="Enter Alias Name"

                  placeholder="Enter Alias Name"
                  variant="outlined"
                  sx={{ width: "440px" }}
                  margin="normal"
                  value={inputValue}
                  onChange={(event)=>{handleInputChange(event,'first')}}
                  onKeyDown={(event)=>{handleKeyPress(event,'first')}}
                  helperText="Please hit enter to add the keyword after typing"
                  InputProps={{
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                    },
                    startAdornment: Array.isArray(tags)
                      ? tags.map((tag) => (
                          <Chip
                            color="primary"
                            key={tag}
                            label={tag}
                            onDelete={() => handleDeleteTag(tag,'first')}
                            style={{ marginTop: "10px" }}
                          />
                        ))
                      : null,
                  }}
                />
              </Box>
            </Box>
            
          </Box>
          {isSecondLanguagePresent==true &&
          <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box fontWeight="bold" sx={{ flex: 1 }}>
              {isSecondLanguagePresent && docTypeDetailData.ext_language.split(",")[1]} Alias
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  // label="Enter Alias Name"

                  placeholder="Enter Alias Name"
                  variant="outlined"
                  sx={{ width: "440px" }}
                  margin="normal"
                  value={inputSecondValue}
                  onChange={(event)=>{handleInputChange(event,'second')}}
                  onKeyDown={(event)=>{handleKeyPress(event,'second')}}
                  helperText="Please hit enter to add the keyword after typing"
                  InputProps={{
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                    },
                    startAdornment: Array.isArray(tags)
                      ? secondtags.map((tag) => (
                          <Chip
                            color="primary"
                            key={tag}
                            label={tag}
                            onDelete={() => handleDeleteTag(tag,'second')}
                            style={{ marginTop: "10px" }}
                          />
                        ))
                      : null,
                  }}
                />
              </Box>
            </Box>
            
          </Box>
          }
          </Box>
            
          {dataType === "table" && (
            <div>
              {columnData.map((x, i) => (
                <div key={i}>
                  <hr />
                  <Box
                    display="flex"
                    flexGrow={1}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "17px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      sx={{ width: "100%" }}
                    >
                      <Box fontWeight="bold" sx={{ flex: 1 }}>
                        Column {i + 1}
                        {/* Red mandatory star icon */}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>

                      <Box
                        sx={{ flex: 4, marginLeft: "1%" }}
                        style={{ width: "50px", height: "50px" }}
                      >
                        <IconButton
                          style={{ marginLeft: "400px" }}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon
                            onClick={() => {
                              handleDeleteColumn(i);
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      sx={{ width: "100%" }}
                    >
                      <Box fontWeight="bold" sx={{ flex: 1 }}>
                        Column Name
                        {/* Red mandatory star icon */}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>
                      <Box sx={{ flex: 4, marginLeft: "1%" }}>
                        <TextField
                          sx={{ width: "440px" }}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                          label="Enter Column Name"
                          value={x.column_name}
                          // onChange={(e) => setColumnNames(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      sx={{ width: "100%", marginTop: "10px" }}
                    >
                      <Box
                        fontWeight="bold"
                        sx={{ flex: 1, marginTop: "15px" }}
                      >
                        Alias
                        {/* Red mandatory star icon */}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>
                      <Box sx={{ flex: 4, marginLeft: "1%" }}>
                        <TextField
                          // label="Enter Alias Name"

                          // placeholder="Enter Alias Name"
                          variant="outlined"
                          sx={{ width: "440px" }}
                          margin="normal"
                          // value={inputColumnValue}
                          // onChange={(e)=>{setInputColumnValue(e.target.value)}}
                          // onKeyDown={handleKeyPressColumn}
                          // helperText="Please hit enter to add the keyword after typing"
                          InputProps={{
                            style: {
                              display: "flex",
                              flexWrap: "wrap",
                              borderRadius: "10px",
                            },
                            startAdornment: x.column_alias
                              .split(",")
                              .map((tag) => (
                                <Chip
                                  color="primary"
                                  key={tag}
                                  label={tag}
                                  onDelete={() => handleColumnDeleteTag(tag)}
                                  style={{ marginTop: "10px" }}
                                />
                              )),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              ))}
              <hr />
              <Box
                display="flex"
                flexGrow={1}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "17px",
                  marginTop: "20px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%" }}
                ></Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%" }}
                >
                  <Box fontWeight="bold" sx={{ flex: 1 }}>
                    New Column Name
                    {/* Red mandatory star icon */}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Box>
                  <Box sx={{ flex: 4, marginLeft: "1%" }}>
                    <TextField
                      sx={{ width: "440px" }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      label="Enter Column Name"
                      value={columnNames}
                      onChange={(e) => setColumnNames(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%", marginTop: "10px" }}
                >
                  <Box fontWeight="bold" sx={{ flex: 1, marginTop: "15px" }}>
                    New Alias
                    {/* Red mandatory star icon */}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Box>
                  <Box sx={{ flex: 4, marginLeft: "1%" }}>
                    <TextField
                      // label="Enter Alias Name"

                      placeholder="Enter Alias Name"
                      variant="outlined"
                      sx={{ width: "440px" }}
                      margin="normal"
                      value={inputColumnValue}
                      onChange={(e) => {
                        setInputColumnValue(e.target.value);
                      }}
                      onKeyDown={handleKeyPressColumn}
                      helperText="Please hit enter to add the keyword after typing"
                      InputProps={{
                        style: {
                          display: "flex",
                          flexWrap: "wrap",
                          borderRadius: "10px",
                        },
                        startAdornment: Array.isArray(columnAlias)
                          ? columnAlias.map((tag) => (
                              <Chip
                                color="primary"
                                key={tag}
                                label={tag}
                                onDelete={() => handleColumnDeleteTag(tag)}
                                style={{ marginTop: "10px" }}
                              />
                            ))
                          : null,
                      }}
                    />
                  </Box>
                </Box>
              </Box>

              <hr />
              <Box display="flex" style={{ float: "right" }} flexGrow={1}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%", marginTop: "10px" }}
                >
                  <Button variant="outlined" onClick={handleNewColumnClick}>
                    Add new column
                  </Button>
                </Box>
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions sx={{ marginBottom: "10px", gap: "14px" }}>
          <Button
            onClick={handleCloseModal}
            variant="outlined"
            sx={{ fontWeight: "600", textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={AddDocumentEntity}
            sx={{
              fontWeight: "600",
              textTransform: "none",
              marginRight: "14px",
            }}
            backgroundColor={buttonLoading && "#616161"}
            disabled={buttonLoading}
            // style={{}}
          >
            {buttonLoading && <CircularProgress size={16} />}Add Doc Entity
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openEditModal}
        onClose={handleCloseEditModal}
        sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            // width: "2000px",
            display: "flex",
            minWidth:"650px"

            // alignItems:'flex-end',
            // flexDirection: 'column'
          },
        }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex" }} flexGrow={1}>
            <Typography variant="h6">Edit document entity</Typography>
            <Box position="absolute" left="90%">
              <IconButton>
                <CloseIcon onClick={handleCloseEditModal} />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          {/* Your form for creating a new user goes here */}
          <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              
              
              sx={{ width: "100%", marginTop: "10px"}}
            >
              <Box fontWeight="bold" sx={{ flex: 1, marginTop: "15px" }}>
                Meta Key
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box sx={{ marginLeft: "1%" }}>
                <TextField
                  sx={{ width: "440px" }}
                  InputProps={{
                    style: {
                      borderRadius: "10px",
                    },
                  }}
                  label="Enter Meta Key"
                  value={tempMetaKey}
                  onChange={(e) => setTempMetaKey(e.target.value)}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box fontWeight="bold" sx={{ flex: 1 }}>
                Data Type
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box>
                {/* <TextField 
            label="Data Type"
            variant="outlined"
            sx={{ width: '440px' }}
            margin="normal"
            InputProps={{
              style: {
                borderRadius: "10px",
              }
            }}
            value={dataType}
            onChange={(e) => setDataType(e.target.value)}
          /> */}
                <FormControl fullWidth sx={{ marginTop: "15px" }}>
                  <InputLabel id="demo-simple-select-label">
                    Data type{" "}
                  </InputLabel>
                  <Select
                    sx={{ width: "440px", borderRadius: "10px" }}
                    margin="normal"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={selectedValue}
                    value={tempDataType}
                    label="Select Data"
                    onChange={(e) => setTempDataType(e.target.value)}
                  >
                   <MenuItem key={"float"} value={"float"}>
                      Number
                    </MenuItem>
                    <MenuItem key={"string"} value={"string"}>
                      Text
                    </MenuItem>         
                    <MenuItem key={"date"} value={"date"}>
                      Date
                    </MenuItem>
                    <MenuItem key={"table"} value={"table"}>
                      Table
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </Box>
         
          
          <Box>
            <Box display="flex" flexGrow={1}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Box fontWeight="bold" sx={{ flex: 1 }}>
              {isSecondLanguagePresent && docTypeDetailData.ext_language.split(",")[0]} Alias
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box style={{ display: "flex", flexWrap: "wrap" }}>
                <TextField
                  placeholder="Enter Alias Name"
                  helperText="Please hit enter to add the keyword after typing"
                  variant="outlined"
                  sx={{ width: "440px" }}
                  margin="normal"
                  value={tempInputValue}
                  onChange={(event)=>{handleTempInputChange(event,'first')}}
                  onKeyDown={(event)=>{handleTempKeyPress(event,'first')}}
                  InputProps={{
                    style: {
                      display: "flex",
                      flexWrap: "wrap",
                      borderRadius: "10px",
                    },
                    startAdornment: tempTags!=="" && tempTags.map((tag, index) => (
                     
                      <Chip
                        color="primary"
                        key={index}
                        label={tag}
                        onDelete={() => handleTempDeleteTag(tag,'first')}
                        style={{ marginTop: "10px" }}
                      />
                     
                    
                    )),
                    endAdornment: null,
                  }}
                />
              </Box>
            </Box>
          </Box>
          {isSecondLanguagePresent &&
          <Box display="flex" flexGrow={1}>
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Box fontWeight="bold" sx={{ flex: 1 }}>
            {isSecondLanguagePresent && docTypeDetailData.ext_language.split(",")[1]} Alias
              <span style={{ color: "red" }}>*</span>
            </Box>
            <Box style={{ display: "flex", flexWrap: "wrap" }}>
              <TextField
                placeholder="Enter Alias Name"
                helperText="Please hit enter to add the keyword after typing"
                variant="outlined"
                sx={{ width: "440px" }}
                margin="normal"
                value={tempSecondInputValue}
                onChange={(event)=>{handleTempInputChange(event,'second')}}
                onKeyDown={(event)=>{handleTempKeyPress(event,'second')}}
                InputProps={{
                  style: {
                    display: "flex",
                    flexWrap: "wrap",
                    borderRadius: "10px",
                  },
                  startAdornment: secondtempTags!=="" && secondtempTags.map((tag, index) => (
                 
                    <Chip
                      color="primary"
                      key={index}
                      label={tag}
                      onDelete={() => handleTempDeleteTag(tag,'second')}
                      style={{ marginTop: "10px" }}
                    />
                    
                  )),
                  endAdornment: null,
                }}
              />
            </Box>
          </Box>
        </Box>}
          </Box>
          
          {tempDataType === "table" && (
            <div>
              {columnData.map((x, i) => (
                <div key={i}>
                  <hr />
                  <Box
                    display="flex"
                    flexGrow={1}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "17px",
                    }}
                  >
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      sx={{ width: "100%" }}
                    >
                      <Box fontWeight="bold" sx={{ flex: 1 }}>
                        Column {i + 1}
                        {/* Red mandatory star icon */}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>

                      <Box
                        sx={{ flex: 4, marginLeft: "1%" }}
                        style={{ width: "50px", height: "50px" }}
                      >
                        <IconButton
                          style={{ marginLeft: "400px" }}
                          aria-label="delete"
                          size="small"
                        >
                          <DeleteIcon
                            onClick={() => {
                              handleDeleteColumn(i);
                            }}
                          />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      sx={{ width: "100%" }}
                    >
                      <Box fontWeight="bold" sx={{ flex: 1 }}>
                        Column Name
                        {/* Red mandatory star icon */}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>
                      <Box sx={{ flex: 4, marginLeft: "1%" }}>
                        <TextField
                          sx={{ width: "440px" }}
                          InputProps={{
                            style: {
                              borderRadius: "10px",
                            },
                          }}
                          label="Enter Column Name"
                          value={x.column_name}
                          // onChange={(e) => setMetaKey(e.target.value)}
                        />
                      </Box>
                    </Box>
                    <Box
                      display="flex"
                      flexDirection="row"
                      alignItems="flex-start"
                      sx={{ width: "100%", marginTop: "10px" }}
                    >
                      <Box
                        fontWeight="bold"
                        sx={{ flex: 1, marginTop: "15px" }}
                      >
                        Alias
                        {/* Red mandatory star icon */}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </Box>
                      <Box sx={{ flex: 4, marginLeft: "1%" }}>
                        <TextField
                          // label="Enter Alias Name"

                          // placeholder="Enter Alias Name"
                          variant="outlined"
                          sx={{ width: "440px" }}
                          margin="normal"
                          // value={inputColumnValue}
                          // onChange={(e)=>{setInputColumnValue(e.target.value)}}
                          // onKeyDown={handleKeyPressColumn}
                          // helperText="Please hit enter to add the keyword after typing"
                          InputProps={{
                            style: {
                              display: "flex",
                              flexWrap: "wrap",
                              borderRadius: "10px",
                            },
                            startAdornment: x.column_alias
                              .split(",")
                              .map((tag) => (
                                <Chip
                                  color="primary"
                                  key={tag}
                                  label={tag}
                                  onDelete={() => handleColumnDeleteTag(tag)}
                                  style={{ marginTop: "10px" }}
                                />
                              )),
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                </div>
              ))}

              <hr />
              <Box
                display="flex"
                flexGrow={1}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "17px",
                  marginTop: "20px",
                }}
              >
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%" }}
                ></Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%" }}
                >
                  <Box fontWeight="bold" sx={{ flex: 1 }}>
                    New Column Name
                    {/* Red mandatory star icon */}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Box>
                  <Box sx={{ flex: 4, marginLeft: "1%" }}>
                    <TextField
                      sx={{ width: "440px" }}
                      InputProps={{
                        style: {
                          borderRadius: "10px",
                        },
                      }}
                      label="Enter Column Name"
                      value={columnNames}
                      onChange={(e) => setColumnNames(e.target.value)}
                    />
                  </Box>
                </Box>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%", marginTop: "10px" }}
                >
                  <Box fontWeight="bold" sx={{ flex: 1, marginTop: "15px" }}>
                    New Alias
                    {/* Red mandatory star icon */}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </Box>
                  <Box sx={{ flex: 4, marginLeft: "1%" }}>
                    <TextField
                      // label="Enter Alias Name"

                      placeholder="Enter Alias Name"
                      variant="outlined"
                      sx={{ width: "440px" }}
                      margin="normal"
                      value={inputColumnValue}
                      onChange={(e) => {
                        setInputColumnValue(e.target.value);
                      }}
                      onKeyDown={handleKeyPressColumn}
                      helperText="Please hit enter to add the keyword after typing"
                      InputProps={{
                        style: {
                          display: "flex",
                          flexWrap: "wrap",
                          borderRadius: "10px",
                        },
                        startAdornment: Array.isArray(columnAlias)
                          ? columnAlias.map((tag) => (
                              <Chip
                                color="primary"
                                key={tag}
                                label={tag}
                                onDelete={() => handleColumnDeleteTag(tag)}
                                style={{ marginTop: "10px" }}
                              />
                            ))
                          : null,
                      }}
                    />
                  </Box>
                </Box>
              </Box>
              <Box display="flex" style={{ float: "right" }} flexGrow={1}>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="flex-start"
                  sx={{ width: "100%" }}
                >
                  <Button variant="outlined" onClick={handleNewColumnClick}>
                    Add new column
                  </Button>
                </Box>
              </Box>
            </div>
          )}
        </DialogContent>
        <DialogActions
          sx={{ marginBottom: "10px", gap: "14px", marginTop: "10px" }}
        >
          <Button
            onClick={handleCloseEditModal}
            variant="outlined"
            sx={{ fontWeight: "600", textTransform: "none" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={EditDocumentEntity}
            sx={{ fontWeight: "600", textTransform: "none" }}
            backgroundColor={buttonLoading && "#616161"}
            disabled={buttonLoading}
          >
            {buttonLoading && <CircularProgress size={16} />}Save
          </Button>
        </DialogActions>
      </Dialog>
      <Sidebar />
      <Snackbar
        mode="outlined"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center", // Center bottom
        }}
        open={snackBarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackBarOpen(false)}
        message={snackbarMessage}
        action={[
          <IconButton
            key="close"
            color="inherit"
            onClick={() => setSnackBarOpen(false)}
          >
            <CloseIcon />
          </IconButton>,
        ]}
      />
      </div>
    </Box>
  );
};

export default ManageDocEntity;
