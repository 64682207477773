import React, { useState, useEffect, useRef } from "react";
import { styled } from "@mui/system";
import {
  InitiateMicrosoftoLgin,
  completeMicrosoftSignup,
  getAllAccounts,
} from "../ApiHelper/Profile.js";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Card, CardContent, Button, CircularProgress } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Sidebar from "../Includes/Sidebar";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import { useLocation } from "react-router-dom";
export default function MiniDrawer() {
  const location = useLocation();
  const navigate = useNavigate(); // Use useNavigate
  const [loading, setLoading] = useState(false); // Add loading state

  const handleConnectOneDrive = async () => {
    setLoading(true);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await InitiateMicrosoftoLgin(userToken, user_id, userorg_id);

      if (res.code === "200") {
        console.log(res.url);

        setLoading(true);
        window.location.href = res.url;
      } else {
        setLoading(false);
        console.error("Error:", res.message);
        console.log(res.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  // Code parameter found in the URL, proceed with signup completion
  const completeSignup = async () => {
    setLoading(true);
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      try {
        const completeSignupRes = await completeMicrosoftSignup(
          userToken,
          user_id,
          userorg_id,
          code
        );

        if (completeSignupRes.code === "200") {
          const message = completeSignupRes.message;
          setLoading(true);
          navigate("/onedrive-account", {
            state: { showSnackbar: true, message },
          });
        } else {
          setLoading(false);
          console.error(
            "Error completing Microsoft signup:",
            completeSignupRes.message
          );
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  const hasEffectRun = useRef(false);
  const fetchData = async () => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllAccounts(userToken, user_id, userorg_id);

      if (res.code === "200") {
        console.log(res.data);
        const message = res.message;
        setLoading(false);
        if (res.data.length > 0) {
          // If data is available, navigate to another page

          setLoading(true);
          navigate("/onedrive-account");
        } else {
          setLoading(false);
        }
      } else {
        setLoading(false);
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  // useEffect(() => {
  //   let isCancelled = false;
  //   let hasFetchedData = false;

  //   const fetchDataAndCompleteSignup = async () => {
  //     if (!hasFetchedData) {
  //       try {
  //         await fetchData();
  //         setLoading(false);
  //         hasFetchedData = true;

  //         if (!isCancelled) {
  //           await completeSignup();
  //           setLoading(false);
  //         }
  //       } catch (error) {
  //         // Handle errors appropriately
  //         console.error("Error:", error);
  //       }
  //     }
  //   };

  //   fetchDataAndCompleteSignup();

  //   // Cleanup function to handle component unmount
  //   return () => {
  //     isCancelled = true;

  //   };
  // }, []); // Empty dependency array means it runs once when the component mounts

  useEffect(() => {
    if (!hasEffectRun.current) {
      fetchData();
      completeSignup();
      hasEffectRun.current = true;
    }
  }, [hasEffectRun.current]);
  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <Sidebar />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 4,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Card
          sx={{
            backgroundColor: "#EFEDF1",
            border: "1px dashed var(--m-3-sys-light-outline, #74777F)",
            borderRadius: "8px",
            padding: "80px 120px",
            boxShadow: "none",
          }}
        >
          <CardContent style={{ textAlign: "center" }}>
            <img
              alt=""
              src={window.constants.asset_path + "/onedrive.png"}
              style={{ width: "85.333px", height: "48px" }}
            />
            <Typography variant="h6" gutterBottom style={{ fontSize: "22px" }}>
              Get Started by connecting with OneDrive Account
            </Typography>

            <Typography variant="h6" gutterBottom style={{ fontSize: "16px" }}>
              Don’t know where to start from?{" "}
              <a href="#" style={{ textDecoration: "none" }}>
                Watch Demo Video
              </a>
            </Typography>

            <Button
              style={{ marginTop: "16px" }}
              variant="contained"
              color="primary"
              startIcon={<CloudQueueIcon />}
              onClick={handleConnectOneDrive}
              sx={{ textTransform: "none", fontWeight: "bold" }}
            >
              {!loading ? (
                "Connect to OneDrive"
              ) : (
                <>
                  <CircularProgress
                    size={24}
                    sx={{ marginRight: "8px", color: "white" }}
                  />
                  Please wait...
                </>
              )}
            </Button>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
}
