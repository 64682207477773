import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
// import { loginUser } from "./ApiHelper/Profile.js";
import { loginUser,setNewPassword } from "../ApiHelper/Profile";
// import 
import styles from "./Login.module.css";
import {
  Grid,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Link,
  Toolbar,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ErrorMessage, Field, Form, Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from 'react-router-dom';

const SetNewPassword = () => {
  const [password,setPassword]=useState("")
  const [confirmPassword,setConfirmPassword]=useState("")


  const [showSnackbar, setShowSnackbar] = useState(false);
  const location = useLocation();
  const navigate = useNavigate(); // Use useNa
  const urlParams = new URLSearchParams(location.search);
  const tokenFromUrl = urlParams.get('token');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [toastdisplay, settoastdisplay] = useState("no");
  const [toastmessage, settoastmessage] = useState("");
  const [toastcolor, settoastcolor] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [error, setError] = useState({
    password: "",
    Confirm_Password: "",
  });
  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {};

    if (!password) {
      errors.password = "Password is required.";
      isValid = false;
    } 
    // else if (password.length < 8 || password.length > 256) {
    //   errors.password = "Password must be between 8 and 256 characters.";
    //   isValid = false;
    // }

    if (!confirmPassword) {
      errors.Confirm_Password = "Confirm Password is required.";
      isValid = false;
    } else if (confirmPassword !== password) {
      errors.Confirm_Password = "Passwords do not match.";
      isValid = false;
    }

    setError(errors);
    return isValid;
  };

  useEffect(() => {
    // Disable scrolling for the entire page
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component is unmounted or on component cleanup
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const handleSubmit = async () => {


    if (!validateForm()) {
        settoastdisplay("yes");
        settoastmessage("ERROR");
        settoastcolor("error");
      setShowSnackbar(true)
      return;
    }
    setLoading(true);
    try {
      const res = await setNewPassword(password, tokenFromUrl);

      if (res.code === "200") {
        setLoading(false);

        localStorage.setItem("userdata", JSON.stringify(res.data));
        settoastdisplay("yes");
        settoastmessage(res.message);
        settoastcolor("success");
        setShowSnackbar(true)
        setSnackbarOpen(true);
        // navigate("/", { state: { showSnackbar: true } });
        setTimeout(() => {
            navigate('/');
        }, 1000);
      } else {
        settoastdisplay("yes");
        settoastmessage(res.message);
        settoastcolor("error");
        setSnackbarOpen(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e); // Call handleSubmit on Enter key press
    }
  };
 

  return (
    <Box component="main">

      {/* <div>
        <button onClick={downloadFile} >
          Download File
        </button>
      </div> */}

      <Grid className={styles.login}>
        <div>
          <div className={styles.bgIcon} alt="" />
          <div className={styles.frameParent}>
            <div className={styles.image4Wrapper}>
              <img
                alt=""
                style={{ width: "270px" }}
                src={window.constants.asset_path + "/image.png"}
              />
            </div>
            <div className={styles.groupParent}>
              <img
                className={styles.frameChild}
                alt=""
                src={window.constants.asset_path + "/group-1.svg"}
              />
              <div className={styles.loginParent}>
                <Typography variant="h4" className={styles.login1}>
                Reset your password
                </Typography>

                <Box>
                {/* <div className="user_login_forgot_password" style={{ maxWidth: '400px',fontSize:'15px', marginBottom: '10px' }}>
                  Strong password required. Enter 8-256 characters. Do not include common words or names. Combine uppercase letters, lowercase letters, numbers, and symbols.
                </div> */}
              </Box>
              </div>

            
                    <div className={styles.assetcomponentbuttonParent}>
                    

                    <Box>
                <TextField
                  placeholder="Password"
                  style={{width:"400px"}}

                  type={showPassword ? "text" : "password"}
                  label="Password"
                  size="small"
                  className="user_login_password"
                  error={!!error.password}
                  helperText={error.password}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  onKeyDown={handleKeyPress} // Handle Enter key press
                  value={password}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={togglePasswordVisibility}>
                        {showPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
              <Box>
                <TextField
                style={{width:"400px"}}
                  placeholder="Confirm Password"
                  type={showConfirmPassword ? "text" : "password"}
                  label="Confirm Password"
                  size="small"
                  className="user_login_password"
                  error={!!error.Confirm_Password}
                  helperText={error.Confirm_Password}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                  }}
                  onKeyDown={handleKeyPress} // Handle Enter key press
                  value={confirmPassword}
                  InputProps={{
                    endAdornment: (
                      <IconButton onClick={toggleConfirmPasswordVisibility}>
                        {showConfirmPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    ),
                  }}
                />
              </Box>
                      <Button
                        className={styles.button2}
                        type="submit"
                        variant="contained"
                        style={{ position: "relative" }}
                        fullWidth
                        disabled={loading}
                        onClick={handleSubmit}
                      >
                        {" "}
                        {loading && (
                          <CircularProgress
                            size={16}
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                            }}
                          />
                        )}
                        Submit
                      </Button>
                    </div>
                 
              
            </div>

            <div className={styles.deepdelveParent}>
              <Typography variant="h6" className={styles.deepdelve}>
                DeepDelveⓇ
              </Typography>
              <div className={styles.poweredByZeonaiContainer}>
                <span className={styles.poweredBy}>Powered by</span>
                <span className={styles.zeonaiLabs}> ZeonAI Labs</span>
              </div>
            </div>



          </div>
        </div>
      </Grid>
      <Snackbar
        mode="outlined"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center", // Center bottom
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: toastcolor === "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {toastmessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>



    </Box>
  );
};

export default SetNewPassword;
