import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import GraphChart from "./GraphChart.jsx";
import BarChart from "./BarChart.jsx";
import { Card, CardContent } from "@mui/material";
import RightSidebarPage from "../Includes/Rightbar";
import Sidebar from "../Includes/Sidebar";
import PieChart from "./PieChart.jsx";

export default function MiniDrawer() {
  return (
    <Box sx={{ display: "flex", backgroundColor: "#EFEDF1" }}>
      <Sidebar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <div style={{ display: "flex", flexGrow: 1, marginTop: "50px" }}>
          <div
            style={{
              flex: 1,
              marginRight: "20px",
              marginLeft: "50px",
              marginTop: "15px",
            }}
          >
            <Card style={{ marginBottom: "16px" }}>
              <CardContent style={{ padding: "16px" }}>
                <Typography variant="h6" gutterBottom>
                  Document Processing Trail
                </Typography>
                <BarChart />
              </CardContent>
            </Card>

            {/* Graph Chart Card */}
            <div style={{ flex: 1, marginTop: "15px", display: "flex" }}>
              {/* Graph Chart Card */}
              <Card style={{ flex: 1, marginBottom: "16px" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Page Processing Trail
                  </Typography>
                  <GraphChart />
                </CardContent>
              </Card>

              {/* Pie Chart Card */}
              <Card
                style={{ flex: 1, marginBottom: "16px", marginLeft: "20px" }}
              >
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    Invoice Catagory Distribution
                  </Typography>
                  <PieChart />
                </CardContent>
              </Card>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
}
