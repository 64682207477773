import styles from "./user.module.css";
import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect, useRef } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import AdminSidebar from '../Includes/AdminSidebar.jsx';
import Sidebar from "../../Includes/Sidebar.jsx";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Avatar from '@mui/material/Avatar';
import PhoneIcon from '@mui/icons-material/Phone';
import { Card, CardContent, Button, CircularProgress, DialogTitle, Menu, Snackbar, SnackbarContent } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { MenuItem, ListItemIcon, FormControl, InputLabel, Select, IconButton, Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import WorkOutlineIcon from '@mui/icons-material/WorkOutline';
import a5 from '../../../a.png'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import PersonIcon from '@mui/icons-material/Person';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import AddIcon from '@mui/icons-material/Add';
import './User.css';
import { getAllUser, fetchMoreUser, addUser, editUser, userMasterData, userChangeStatus } from "../AdminConsoleApiHelper/AdminApiHelper.js";
const AdminUser = () => {
  const [openmenuitem, SetOpenmenuitem] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const [Loadingtable, setLoadingtable] = useState(true)
  const [UserDetails, setUserdetails] = useState([])
  const [usertoken, setusertoken] = useState("");
  const [index, setIndex] = useState(0);
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  const [designationSearchResults, setDesignationSearchResults] = useState([]);
  const [roleSearchResults, setRoleSearchResults] = useState([]);
  const [departmentSearchResults, setDepartmentSearchResults] = useState([]);
  const [showStaticContent, setShowStaticContent] = useState(false);
  const [userconversation_id, setuserconversation_id] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [toastmessage, settoastmessage] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [total_rec, setTotal_rec] = useState('');

  const userData = localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const admin = userDataObj.superadmin;
      console.log('admin', admin)
  useEffect(() => {

    GetUserData()
  }, []);
  const GetUserData = async () => {
    setLoadingtable(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setIndex(0);
      const res = await getAllUser(deepuser_id, deeporg_id, userToken);

      // console.log(res)
      if (res.code === "200") {
        setTotal_rec(res.total_rec)
        setLoadingtable(false);
        // console.log(res.data)
        setUserdetails(res.data)
      } else {
        // Handle the error
        console.error("Error:",);
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(true);
    }
  };



  useEffect(() => {
    const fetchMasterData = async () => {
      setLoadingtable(true);
      try {
        const storedUserData = localStorage.getItem("userdata");

        if (!storedUserData) {
          console.error("User data not found in localStorage.");
          return;
        }

        const userDataObj = JSON.parse(storedUserData);
        console.log(userDataObj);
        if (!userDataObj || !userDataObj.token) {
          console.error(
            "User token not found in localStorage or component unmounted."
          );
          return;
        }

        const {
          token: userToken,
          user_id: deepuser_id,
          org_id: deeporg_id,
        } = userDataObj;

        setusertoken(userToken);
        setuser_id(deepuser_id);
        setuserorg_id(deeporg_id);
        setIndex(0);
        const requestData = await userMasterData(deepuser_id, deeporg_id, userToken);
        if (requestData.code == "200") {
          setuserconversation_id(requestData.conversation_id);
          // const designationResults = requestData.designation;
          // setDesignationSearchResults((prevResults) => [
          //     ...designationResults,
          //     ...prevResults,
          // ]);
          setDesignationSearchResults(requestData.designation)
          // const roleResults = requestData.role;
          // setRoleSearchResults((prevResults) => [
          //     ...roleResults,
          //     ...prevResults,
          // ]);
          setRoleSearchResults(requestData.role)
          // const departmentResults = requestData.department;
          // setDepartmentSearchResults((prevResults) => [
          //     ...departmentResults,
          //     ...prevResults,
          // ]);
          setDepartmentSearchResults(requestData.department)
          setLoadingtable(false)
          setShowStaticContent(false);
        } else {
          setShowStaticContent(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      } finally {
        // Make sure to set isLoading to false whether the fetch was successful or not
        setLoadingtable(false);
      }
    };

    fetchMasterData();

    return () => { };
  }, []);

  const handleAddUser = async () => {
    setButtonLoading(true)
    try {
      //   setdeleteLoading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await addUser(
        deepuser_id,
        deeporg_id,
        user_name,
        user_photo,
        user_gender,
        user_email,
        user_phone,
        user_empid,
        user_designation,
        user_role,
        user_dept,
        userToken
      );

      if (responseData.code === "200") {

        setButtonLoading(false)
        setUser_name('');
        setUser_phone('');
        setUser_email('');
        setUser_designation('');
        setUser_role('');
        setUser_dept('');
        setUser_empid('');
        setUser_gender('');
        setOpenModal(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
        GetUserData();
      } else {

        setButtonLoading(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      //   setdeleteLoading(false)
      setButtonLoading(false);
    }
  };

  const handleEditUser = async () => {
    setButtonLoading(true)
    try {
      //   setdeleteLoading(true)
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      const responseData = await editUser(
        deepuser_id,
        deeporg_id,
        tempProfileId,
        tempUserName,
        tempUserPhoto,
        tempUserGender,
        tempUserEmail,
        tempUserPhone,
        tempUserEmpid,
        tempUserDegn,
        tempUserRole,
        tempUserDept,
        userToken
      );

      if (responseData.code === "200") {

        setButtonLoading(false)
        setUser_name('');
        setUser_phone('');
        setUser_email('');
        setUser_designation('');
        setUser_role('');
        setUser_dept('');
        setUser_empid('');
        setUser_gender('');
        setEditUserModalOpen(false);
        closeEditUserModal()
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
        GetUserData();
      } else {

        setButtonLoading(false);
        setSnackbarOpen(true);
        settoastmessage(responseData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      //   setdeleteLoading(false)
      setButtonLoading(false);
    }
  };

  const StatusChange = async () => {
    setButtonLoading(true);
    try {
      const storedUserData = localStorage.getItem("userdata");

      if (!storedUserData) {
        console.error("User data not found in localStorage.");
        return;
      }

      const userDataObj = JSON.parse(storedUserData);
      console.log(userDataObj);
      if (!userDataObj || !userDataObj.token) {
        console.error(
          "User token not found in localStorage or component unmounted."
        );
        return;
      }

      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
      } = userDataObj;

      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      if (tempUserStatus == 'active') {
        setTempUserStatus('inactive')
      } else {
        setTempUserStatus('active')
      }
      const requestData = await userChangeStatus(deepuser_id, deeporg_id, tempProfileId, tempUserStatus, userToken);
      if (requestData.code == "200") {
        console.log('status change', requestData.message)
        setuserconversation_id(requestData.conversation_id);
        setSnackbarOpen(true);
        settoastmessage(requestData.message)
        setButtonLoading(false)
        closeChangeStatusModal()
        SetOpenmenuitem(false)
        setShowStaticContent(false);
        GetUserData();
      } else {

        setButtonLoading(false);
        setSnackbarOpen(true);
        settoastmessage(requestData.message)
      }
    } catch (error) {
      console.error("Error:", error);
      //   setdeleteLoading(false)
    }
  };

  const buttonStyles = {
    borderRadius: '8px',
    color: 'var(--Orange, #F2994A)',

    background: 'rgba(242, 153, 74, 0.16)',
    '&:hover': {
      background: 'rgba(242, 153, 74, 0.24)',
    },
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '700',
    lineheight: '20px',
  };
  const buttonactiveStyles = {
    borderRadius: '8px',
    // color: '#27AE60',
    backgroundColor: '#27AE6029', color: '#27AE60',
    // background: 'rgba(39, 174, 96, 0.16)',
    // '&:hover': {
    //   background: 'rgba(39, 174, 96, 0.16)',
    // },
    fontSize: '14px',
    textTransform: 'none',
    fontWeight: '700',
    lineheight: '20px',
  };
  const [editUserModalOpen, setEditUserModalOpen] = useState(false);
  const [viewDetailsModalOpen, setViewDetailsModalOpen] = useState(false);
  const [changeStatusModalOpen, setChangeStatusModalOpen] = useState(false)
  const openMenu = Boolean(openmenuitem);
  const openEditUserModal = () => {
    setEditUserModalOpen(true);
  };
  const openViewDetailsModal = () => {
    setViewDetailsModalOpen(true);
    SetOpenmenuitem(null);
  };
  const openChangeStatusModal = () => {
    setChangeStatusModalOpen(true);
    SetOpenmenuitem(null);
  };
  const closeEditUserModal = () => {
    setEditUserModalOpen(false);
    SetOpenmenuitem(null);
  };
  const closeViewDetailsModal = () => {
    setViewDetailsModalOpen(false);
    SetOpenmenuitem(null);
  };
  const closeChangeStatusModal = () => {
    setChangeStatusModalOpen(false);
    SetOpenmenuitem(null);
  };
  const [user_name, setUser_name] = useState('');
  const [user_gender, setUser_gender] = useState('male');
  const [user_email, setUser_email] = useState('');
  const [user_phone, setUser_phone] = useState('');
  const [user_empid, setUser_empid] = useState('');
  const [user_designation, setUser_designation] = useState('');
  const [user_role, setUser_role] = useState('');
  const [user_dept, setUser_dept] = useState('');
  const [user_photo, setUser_photo] = useState('');
  const [user_photo_view, setUser_photo_view] = useState('');

  const [tempUserName, setTempUserName] = useState('')
  const [tempUserGender, setTempUsergender] = useState('')
  const [tempUserStatus, setTempUserStatus] = useState('')
  const [tempUserPhoto, setTempUserPhoto] = useState('')
  const [tempUserPhoto_view, setTempUserPhoto_view] = useState('')
  const [tempUserEmail, setTempUserEmail] = useState('')
  const [tempUserPhone, setTempUserPhone] = useState('')
  const [tempUserEmpid, setTempUserEmpid] = useState('')
  const [tempUserDegn, setTempUserDegn] = useState('')
  const [tempUserRole, setTempUserRole] = useState('')
  const [tempUserDept, setTempUserDept] = useState('')
  const [tempUserDegn_view, setTempUserDegn_view] = useState('')
  const [tempUserRole_view, setTempUserRole_view] = useState('')
  const [tempUserDept_view, setTempUserDept_view] = useState('')
  const [tempProfileId, setTempProfileId] = useState('')
  const [tempIsSuperAdmin,setTempIsSuperAdmin]= useState('')

  const handleClose = () => {
    SetOpenmenuitem(null);
  };
  const handlemodalclose = () => {
    // Add your logic for creating a new user here
    console.log('Creating a new user...');
    setOpenModal(false);
  };
  const fileInputRef = useRef(null);

  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  // const handleFileChange = (event) => {
  //   const imgFile = event.target.files[0];
  //   if (imgFile) {
  //     // Make sure a file is selected
  //     setUser_photo(URL.createObjectURL(imgFile));
  //     console.log('imgfile', imgFile)
  //     console.log('tempuserphoto', user_photo)
  //   }

  // };
  const handleFileChangeAdd = (event) => {
    const imgFile = event.target.files[0];
    setUser_photo(imgFile);

    const reader = new FileReader();
    reader.onloadend = () => {

      setUser_photo_view(reader.result);
    };
    reader.readAsDataURL(imgFile);
  }
  const handleFileChangeEdit = (event) => {
    // Handle the selected file
    // setFileSelect(true);
    const imgFile = event.target.files[0];

    setTempUserPhoto(imgFile)
    const reader = new FileReader();
    reader.onloadend = () => {
      setTempUserPhoto_view(reader.result);

    };
    reader.readAsDataURL(imgFile);
  }
  const handleClickMenu = (event, user) => {
    SetOpenmenuitem(event.currentTarget);
    setTempUserName(user.name);
    setTempUsergender(user.gender);
    setTempIsSuperAdmin(user.superadmin)
    // setTempUserStatus(user.status);
    setTempUserPhoto_view(user.photo);
    setTempUserEmail(user.email);
    setTempUserPhone(user.phone);
    setTempUserEmpid(user.emp_id);
    setTempUserDegn(user.designation);
    setTempUserRole(user.role_id);
    setTempUserDept(user.department_id);
    setTempUserDegn_view(user.designation);
    setTempUserRole_view(user.role);
    setTempUserDept_view(user.department);
    setTempProfileId(user.user_id)
    if (user.status == 'active') {
      setTempUserStatus('inactive')
    } else {
      setTempUserStatus('active')
    }
  };
  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbarOpen(false);
  };

  const handleAddDesignation = (event) => {
    setUser_designation(event.target.value);
  };
  const handleChangeDesignation = (event) => {
    setTempUserDegn(event.target.value);
  };
  const handleAddRole = (event) => {
    setUser_role(event.target.value);
  };
  const handleChangeRole = (event) => {
    setTempUserRole(event.target.value);
  };
  const handleAddDepartment = (event) => {

    setUser_dept(event.target.value);
  };
  const handleChangeDepartment = (event) => {

    setTempUserDept(event.target.value);
  };
  return (
    <Box component="main" sx={{ display: 'flex', backgroundColor: "#EFEDF1", flexDirection: 'column', minHeight: '100vh' }}>
      <Box sx={{ textAlign: 'left', marginLeft: '150px', marginRight: '50px', marginTop: '100px', display: 'flex', justifyContent: 'space-between', }}>

        <Typography sx={{ fontSize: '16px', color: '#606060' }}>Create and manage your users</Typography>

        <Button
          sx={{ textTransform: 'none', fontWeight: 'bold' }}
          variant="contained"
          color="primary"
          startIcon={<PersonAddIcon />}
          onClick={() => setOpenModal(true)}
        >
          Create new user
        </Button>
      </Box>
      <Box sx={{ textAlign: 'left', marginLeft: '150px', fontSize: '13px', color: '#606060' }}>Total {total_rec ? total_rec : 0} users </Box>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', marginLeft: '132px', marginRight: '2px', marginTop: '5px' }}>
        <TableContainer component={Paper} style={{ boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)', height: '100%', marginBottom: '15px' , maxHeight: "calc(100vh - 220px)",}}>
        <Table  size="small" sx={{ minWidth: 700 }}aria-label="simple table">
                  <TableHead sx={{  position: 'sticky', top: 0, zIndex: '999',backgroundColor: '#EFF1F3', }}>
              <TableRow>
                {/* <TableCell sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>SL. No.</TableCell> */}
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Name</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Email</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Mobile</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Role</TableCell>
                <TableCell align="left" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Department	</TableCell>
                <TableCell align="center" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Status</TableCell>
                <TableCell align="center" sx={{ fontSize: '16px', fontWeight: '700', color: '#4A5568' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Loadingtable && (

                <TableCell colSpan={6} align="center" sx={{ width: '1000px' }}>
                  <CircularProgress />
                </TableCell>

              )}
              {!Loadingtable && (
                <>
                  {UserDetails.map((user, index) => (
                    <TableRow key={index}>
                      {/* <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell> */}
                      <TableCell >
                        <Box sx={{ display: 'flex' }}>
                          <Box > <img src={user.photo} style={{ width: '27px', height: '27px', borderRadius: '50px' }} /> </Box>
                          <Box sx={{ marginTop: '4px', marginLeft: '5px' }}>{user.name}</Box>
                        </Box>
                      </TableCell>
                      <TableCell align="left">{user.email}</TableCell>
                      <TableCell align="left">{user.phone ? user.phone : "--"}</TableCell>
                      <TableCell align="left">{user.role}</TableCell>
                      <TableCell align="left">{user.department}</TableCell>
                      <TableCell align="center">
                        {user.status === 'active' ?
                          <Button disabled style={{ color: '#27AE60', backgroundColor: '#27AE6029', borderBlockColor: '#27AE603D', borderRadius: '8px', width: '90px', fontWeight: 'bold', textTransform: 'none' }}>
                            Active
                          </Button>
                          :
                          <Button disabled style={{ color: '#F2994A', backgroundColor: '#F2994A29', borderBlockColor: '#F2994A3D', borderRadius: '8px', width: '90px', fontWeight: 'bold', textTransform: 'none' }}>
                            Inactive
                          </Button>
                        }
                      </TableCell>
                      <TableCell align="center">
                        <IconButton
                          aria-label="more"
                          aria-controls="three-dot-menu"
                          aria-haspopup="true"
                          onClick={(event) => handleClickMenu(event, user)}
                          sx={{
                            borderRadius: '50%', // Make the button round
                            backgroundColor: '#EFF1F3',
                            '&:hover': {
                              backgroundColor: '#EFF1F3', // Round hover effect
                            },
                          }}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu
          id="basic-menu"
          anchorEl={openmenuitem}
          open={openMenu}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >

          <Box>
            <MenuItem onClick={openViewDetailsModal}>
              <ListItemIcon >
                <VisibilityIcon />
              </ListItemIcon>
              View
            </MenuItem>
            {localStorage.getItem('userdata') &&  (JSON.parse(localStorage.getItem('userdata')).user_priviledge===0 || JSON.parse(localStorage.getItem('userdata')).user_priviledge===1) && 
            <MenuItem onClick={openEditUserModal}>
              <ListItemIcon>
                <BorderColorIcon />
              </ListItemIcon>
              Edit user
            </MenuItem>
            }
            <Dialog open={editUserModalOpen} onClose={closeEditUserModal}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px",
                  width: '530px'
                },
              }} >
              <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                <Box>
                  <strong>Edit user details</strong>

                </Box>
                <Box position='absolute' left='90%'>
                  <IconButton>
                    <CloseIcon onClick={closeEditUserModal} />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent >
                <Box sx={{ marginTop: '20px' }}>
                  <Box >
                    <strong>Personal details</strong>

                    <Box sx={{ display: 'flex' }}>
                      <Box>
                        <Box sx={{ width: '80%', marginTop: '15px' }}>
                          <strong>Change profile photo</strong>

                        </Box>
                        <Box sx={{ color: 'grey', width: '60%', fontSize: '13px' }}>
                          Customize profile photo by following this format PNG, JPG (max 800x800px)
                        </Box>
                      </Box>
                      <Box sx={{ marginLeft: '-25%' }}>
                        <img src={tempUserPhoto_view} style={{ height: '100px', width: '100px', marginTop: '8px', marginRight: '10px', borderRadius: '50px' }} />
                      </Box>
                      <Box sx={{ color: 'grey', marginTop: '20px', fontSize: '13px' }}>
                        <Button
                          onClick={handleButtonClick}
                          style={{ textTransform: 'none', color: 'grey' }}
                        >
                          Click here to upload image or Drag & Drop
                        </Button>
                        <input
                          type="file"
                          accept="image/*"
                          hidden
                          ref={fileInputRef}
                          onChange={handleFileChangeEdit}
                        />

                      </Box>
                    </Box>
                    <Divider />
                    <Box sx={{ margin: '15px 0px', fontSize: '14px' }}>
                      <Box display='flex' flexGrow={1} >
                        <Box fontWeight='bold'>
                          Full name <span style={{ color: 'red' }}>*</span>
                        </Box>
                        <Box sx={{ marginLeft: '13.5%' }}>
                          <TextField sx={{ width: '320px' }}
                            value={tempUserName}
                            onChange={(e) => setTempUserName(e.target.value)}
                            placeholder="Enter full name"
                            InputProps={{
                              startAdornment: (
                                <IconButton  >
                                  <PersonOutlineOutlinedIcon />
                                </IconButton>
                              ),
                            }} />
                        </Box>
                      </Box>
                      <Box>
                        <FormControl>
                          <Box sx={{ display: 'flex', marginTop: '10px' }}>
                            <Box>
                              <FormLabel fontWeight='bold'>Gender</FormLabel>
                            </Box>
                            <Box sx={{ marginLeft: '38%' }}>
                              <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                value={tempUserGender} // Set the value to the current state
                                onChange={(event) => setTempUsergender(event.target.value)}
                              >
                                <Box sx={{ display: 'flex' }}>
                                  <FormControlLabel value="Male" control={<Radio />} label="Male" />
                                  <FormControlLabel value="Female" control={<Radio />} label="Female" />
                                </Box>
                              </RadioGroup>
                            </Box>
                          </Box>
                        </FormControl>
                      </Box>


                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ margin: '15px 0px', fontSize: '14px' }} fontWeight='bold'>
                    Contact details
                    <Box sx={{ margin: '15px 0px' }}>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>Email<span style={{ color: 'red' }}>*</span> </Box>
                        <Box sx={{ marginLeft: '20.4%' }}>
                          <TextField sx={{ width: '320px' }}
                            value={tempUserEmail}
                            placeholder="Enter email address"
                            onChange={(e) => setTempUserEmail(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <IconButton  >
                                  <MailOutlineIcon />
                                </IconButton>
                              ),
                            }} />
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>Mobile</Box>
                        <Box sx={{ marginLeft: '20.3%' }}>
                          <TextField sx={{ width: '320px' }}
                            placeholder="Enter mobile number"
                            value={tempUserPhone}
                            onChange={(e) => setTempUserPhone(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <IconButton  >
                                  <PhoneIcon />
                                </IconButton>
                              ),
                            }} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box fontWeight='bold' sx={{ marginTop: '20px', fontSize: '14px' }}>
                    Employment details
                    <Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Employee id<span style={{ color: 'red' }}>*</span>
                        </Box>
                        <Box sx={{ marginLeft: '11%' }}>
                          <TextField sx={{ width: '320px' }}
                            placeholder="Enter employee id"
                            value={tempUserEmpid}
                            onChange={(e) => setTempUserEmpid(e.target.value)}
                            InputProps={{
                              startAdornment: (
                                <IconButton  >
                                  <WorkOutlineIcon />
                                </IconButton>
                              ),
                            }} />
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Designation<span style={{ color: 'red' }}>*</span>
                        </Box>
                        <Box sx={{ marginLeft: '11.2%' }}>
                          <FormControl sx={{ width: '320px' }}>
                            <InputLabel id="demo-simple-select-label">Choose Designaton</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tempUserDegn}
                              label="Choose Destination"
                              onChange={handleChangeDesignation}
                            >
                              {designationSearchResults.map((item, index) => (
                                <MenuItem
                                  value={item.desg_id}>{item.desg_name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Select role<span style={{ color: 'red' }}>*</span>
                        </Box>
                        <Box sx={{ marginLeft: '12.2%' }}>
                          <FormControl sx={{ width: '320px' }} >
                            <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tempUserRole}
                              label="Select Role"
                              onChange={handleChangeRole}
                            >
                              {roleSearchResults.map((item, index) => (
                                <MenuItem
                                  value={item.role_id}>{item.role_name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Select department<span style={{ color: 'red' }}>*</span>
                        </Box>
                        <Box sx={{ marginLeft: '2%' }}>
                          <FormControl sx={{ width: '320px' }}>
                            <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={tempUserDept}
                              label="Select Department"
                              onChange={handleChangeDepartment}
                            >
                              {departmentSearchResults.map((item, index) => (
                                <MenuItem
                                  value={item.dept_id} >{item.dept_name}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
              <DialogActions sx={{ marginBottom: '10px', marginRight: '25px' }}>
                <Button onClick={closeEditUserModal} variant="outlined" sx={{ textTransform: 'none' }}>
                  Cancel
                </Button>
                <Button variant="contained" sx={{ textTransform: 'none' }} onClick={handleEditUser} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
                  {buttonLoading && <CircularProgress size={16} />}Edit user
                </Button>
              </DialogActions>
            </Dialog>
            {localStorage.getItem('userdata') &&  (JSON.parse(localStorage.getItem('userdata')).user_priviledge===0 || JSON.parse(localStorage.getItem('userdata')).user_priviledge===1) && 
              <MenuItem onClick={openChangeStatusModal}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                Activate user
              </MenuItem>
            }
            {(tempUserStatus == 'inactive' && tempIsSuperAdmin == 'no' && admin == 'yes') ?
              <MenuItem onClick={openChangeStatusModal}>
                <ListItemIcon>
                  <PersonOffIcon />
                </ListItemIcon>
                Inactivate user
              </MenuItem>
            :null}
          
          </Box>


        </Menu>
        <Dialog open={openModal} onClose={() => setOpenModal(false)} className="desktop_view_modal_show" sx={{
          "& .MuiDialog-paper": {
            borderRadius: "16px",
            width: '100%',
            // display: 'flex',

            // alignItems: 'flex-end',
            // flexDirection: 'column'


          },
        }} >
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
              <Box>
                <Typography variant="h6">Create new user</Typography>
              </Box>

              <Box >
                <IconButton>
                  <CloseIcon onClick={handlemodalclose} />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>

          <DialogContent >
            <Box sx={{ marginTop: '20px' }}>
              <Box >
                <strong>Personal details</strong>

                <Box sx={{ display: 'flex' }}>
                  <Box>
                    <Box sx={{ width: '80%', marginTop: '15px' }}>
                      <strong>Change profile photo</strong>

                    </Box>
                    <Box sx={{ color: 'grey', width: '60%', fontSize: '13px' }}>
                      Customize profile photo by following this format PNG, JPG (max 800x800px)
                    </Box>
                  </Box>
                  <Box sx={{ marginLeft: '-25%' }}>
                    {user_photo_view ?
                    <img src={user_photo_view} style={{ height: '100px', width: '100px', marginTop: '8px', marginRight: '10px', borderRadius: '50px' }} />
                    :
                    <Avatar src="/broken-image.jpg" style={{ height: '100px', width: '100px', marginTop: '8px', marginBottom: '8px', marginRight: '10px', borderRadius: '50px' }}/>
                  }
                  </Box>
                  <Box sx={{ color: 'grey', marginTop: '20px', fontSize: '13px' }}>
                    <Button
                      onClick={handleButtonClick}
                      style={{ textTransform: 'none', color: 'grey' }}
                    >
                      Click here to upload image or Drag & Drop
                    </Button>
                    <input
                      type="file"
                      accept="image/*"
                      hidden
                      ref={fileInputRef}
                      onChange={handleFileChangeAdd}
                    />

                  </Box>
                </Box>
                <Divider />
                <Box sx={{ margin: '15px 0px', fontSize: '14px' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>
                      Full name <span style={{ color: 'red' }}>*</span>
                    </Box>
                    <Box sx={{ marginLeft: '13.5%' }}>
                      <TextField sx={{ width: '320px' }}
                        placeholder="Enter full name"
                        onChange={(e) => setUser_name(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <PersonOutlineOutlinedIcon />
                            </IconButton>
                          ),
                        }} />
                    </Box>
                  </Box>
                  <Box>
                    <FormControl>
                      <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>

                        <Box>
                          <FormLabel fontWeight='bold'>Gender</FormLabel>
                        </Box>
                        <Box sx={{ marginLeft: '68%' }}>
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            defaultValue="male"
                            name="radio-buttons-group"
                            // value={tempUserGender}
                            onChange={(e) => setUser_gender(e.target.value)}
                          >
                            <Box sx={{ display: 'flex' }}>
                              <FormControlLabel value="Male" control={<Radio />} label="Male" />
                              <FormControlLabel value="Female" control={<Radio />} label="Female" />
                            </Box>


                          </RadioGroup>
                        </Box>

                      </Box>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box sx={{ margin: '15px 0px', fontSize: '14px' }} fontWeight='bold'>
                Contact details
                <Box sx={{ margin: '15px 0px' }}>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>Email<span style={{ color: 'red' }}>*</span> </Box>
                    <Box sx={{ marginLeft: '20.4%' }}>
                      <TextField sx={{ width: '320px' }}
                        placeholder="Enter email address"
                        onChange={(e) => setUser_email(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <MailOutlineIcon />
                            </IconButton>
                          ),
                        }} />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>Mobile</Box>
                    <Box sx={{ marginLeft: '20.3%' }}>
                      <TextField sx={{ width: '320px' }}
                        placeholder="Enter mobile number"
                        onChange={(e) => setUser_phone(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <PhoneIcon />
                            </IconButton>
                          ),
                        }} />
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box fontWeight='bold' sx={{ marginTop: '20px', fontSize: '14px' }}>
                Employment details
                <Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>
                      Employee id
                    </Box>
                    <Box sx={{ marginLeft: '11%' }}>
                      <TextField sx={{ width: '320px' }}
                        onChange={(e) => setUser_empid(e.target.value)}
                        placeholder="Enter employee id"
                        InputProps={{
                          startAdornment: (
                            <IconButton  >
                              <WorkOutlineIcon />
                            </IconButton>
                          ),
                        }} />
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>
                      Designation<span style={{ color: 'red' }}>*</span>
                    </Box>
                    <Box sx={{ marginLeft: '11.2%' }}>
                      <FormControl sx={{ width: '320px' }}>
                        <InputLabel id="demo-simple-select-label">Choose Designaton</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={user_designation}
                          label="Choose Destination"
                          onChange={handleAddDesignation}
                        >
                          {designationSearchResults.map((item, index) => (
                            <MenuItem
                              value={item.desg_id}>{item.desg_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>
                      Select role<span style={{ color: 'red' }}>*</span>
                    </Box>
                    <Box sx={{ marginLeft: '12.2%' }}>
                      <FormControl sx={{ width: '320px' }} >
                        <InputLabel id="demo-simple-select-label">Select Role</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={user_role}
                          label="Select Role"
                          onChange={handleAddRole}
                        >
                          {roleSearchResults.map((item, index) => (
                            <MenuItem
                              key={index}
                              value={item.role_id}
                            >{item.role_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                  <Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1} sx={{ margin: '20px 0px 0px 0px' }}>
                    <Box fontWeight='bold'>
                      Select department<span style={{ color: 'red' }}>*</span>
                    </Box>
                    <Box sx={{ marginLeft: '2%' }}>
                      <FormControl sx={{ width: '320px' }}>
                        <InputLabel id="demo-simple-select-label">Select Department</InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={user_dept}
                          label="Select Department"
                          onChange={handleAddDepartment}
                        >
                          {departmentSearchResults.map((item, index) => (
                            <MenuItem

                              value={item.dept_id}
                            >{item.dept_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions sx={{ marginBottom: '10px', marginRight: '30px', gap: '14px', textTransform: 'none' }}>
            <Button variant="outlined" onClick={handlemodalclose} sx={{ fontWeight: '600', textTransform: 'none' }} >
              Cancel
            </Button>
            <Button variant="contained" sx={{ fontWeight: '600', textTransform: 'none' }} onClick={handleAddUser} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
              {buttonLoading && <CircularProgress size={16} />}Save user
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={changeStatusModalOpen} onClose={closeChangeStatusModal} >
          <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '32px', marginBottom: '0px' }}>Status Change Confirmation</DialogTitle>
          <DialogContent sx={{ width: '500px' }}>
            Are you sure you want to change your status?
          </DialogContent>
          <DialogActions>
            <Button onClick={closeChangeStatusModal} color="primary">
              No
            </Button>
            {/* <Button color="primary" onClick={handleDelete}>
                                        Apply
                                    </Button> */}
            <Button onClick={StatusChange} backgroundColor={buttonLoading && '#616161'} disabled={buttonLoading}>
              {buttonLoading && <CircularProgress size={16} />} Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={viewDetailsModalOpen} onClose={closeViewDetailsModal}
              sx={{
                "& .MuiDialog-paper": {
                  borderRadius: "20px",
                  width: '530px'
                },
              }} >
              <DialogTitle sx={{ paddingBottom: '0px', paddingTop: '20px', marginBottom: '0px', display: 'flex', }} flexGrow={1}>
                <Box>
                  <strong>User details</strong>

                </Box>
                <Box position='absolute' left='90%'>
                  <IconButton>
                    <CloseIcon onClick={closeViewDetailsModal} />
                  </IconButton>
                </Box>
              </DialogTitle>
              <DialogContent >
                <Box sx={{ marginTop: '20px' }}>
                  <Box >
                    <strong>Personal details</strong>

                   
                      
                      <Box sx={{ marginLeft: '37%' }}>
                        {tempUserPhoto_view ? 
                        <img src={tempUserPhoto_view} style={{ height: '100px', width: '100px', marginTop: '8px', marginRight: '10px', borderRadius: '50px' }} />
                          :
                          <Avatar src="/broken-image.jpg" style={{ height: '100px', width: '100px', marginTop: '8px', marginBottom: '8px', marginRight: '10px', borderRadius: '50px' }}/>
                        }
                        </Box>
                      
                    
                    <Divider />
                    <Box sx={{ margin: '15px 0px', fontSize: '14px' }}>
                      <Box display='flex' flexGrow={1} >
                        <Box fontWeight='bold'>
                          Full name :
                        </Box>
                        <Box sx={{ marginLeft: '32%' }}>
                        {tempUserName}
                        </Box>
                      </Box>
                          <Box sx={{ display: 'flex', marginTop: '10px' }}>
                            <Box fontWeight='bold'>
                            Gender
                            </Box>
                            <Box sx={{ marginLeft: '37.5%' }}>
                            {tempUserGender}
                            </Box>
                          </Box> 
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ margin: '15px 0px', fontSize: '14px' }} >
                    Contact details
                    <Box sx={{ margin: '15px 0px' }}>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>Email :</Box>
                        <Box sx={{ marginLeft: '38%' }}>
                        {tempUserEmail}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>Mobile :</Box>
                        <Box sx={{ marginLeft: '36.5%' }}>
                        {tempUserPhone}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                  <Box  sx={{ marginTop: '20px', fontSize: '14px' }}>
                    Employment details
                    <Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Employee id :
                        </Box>
                        <Box sx={{ marginLeft: '28%' }}>
                        {tempUserEmpid}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Designation :
                        </Box>
                        <Box sx={{ marginLeft: '28%' }}>
                        {tempUserDegn_view}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Select role :
                        </Box>
                        <Box sx={{ marginLeft: '30.5%' }}>
                        {tempUserRole_view}
                        </Box>
                      </Box>
                      <Box sx={{ display: 'flex', margin: '20px 0px' }}>
                        <Box fontWeight='bold'>
                          Select department :
                        </Box>
                        <Box sx={{ marginLeft: '19.6%' }}>
                        {tempUserDept_view}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </DialogContent>
             
            </Dialog>
        <Snackbar
          mode="outlined"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center', // Center bottom
          }}
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
        >
          <SnackbarContent
            style={{
              backgroundColor: 'black',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            message={
              <span style={{ display: 'flex', alignItems: 'center' }}>
                {toastmessage}
              </span>
            }
            action={[
              <IconButton key="close" color="inherit" onClick={handleSnackbarClose}>
                <CloseIcon />
              </IconButton>,
            ]}
          />
        </Snackbar>
        {/* <DialogContent  >
            
            <Box display='flex' flexGrow={1} >
              <Box display='flex' flexDirection="row" alignItems="flex-start" sx={{ width: '100%', }}>
                <Box fontWeight='bold' sx={{ flex: 1 }} >
                  Full Name
                  <span style={{ color: 'red' }}>*</span>
                </Box>
                <Box sx={{ flex: 4, marginLeft: '1%' }}>
                  <TextField
                    label="Name"
                    sx={{ width: '440px' }}
                    placeholder="Enter full name"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      startAdornment: (
                        <IconButton >
                          <PersonOutlineOutlinedIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexGrow={1} >
              <Box display='flex' flexDirection="row" alignItems="center" sx={{ width: '100%' }}>
                <Box fontWeight='bold' sx={{ flex: 1 }}>
                  Email
                  <span style={{ color: 'red' }}>*</span>
                </Box>
                <Box >
                  <TextField
                    label="Email"
                    variant="outlined"
                    placeholder="Enter  Email"
                    sx={{ width: '440px' }}
                    margin="normal"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      startAdornment: (
                        <IconButton  >
                          <MailOutlineIcon />
                        </IconButton>
                      ),
                    }}
                  />
                </Box>
              </Box>
            </Box>
            <Box display='flex' flexGrow={1} >
              <Box display='flex' flexDirection="row" alignItems="center" sx={{ width: '100%', }}>
                <Box fontWeight='bold' sx={{ flex: 1 }}>
                  Mobile
                  <span style={{ color: 'red' }}>*</span>
                </Box>
                <Box >
                  <TextField
                    label="Mobile"
                    variant="outlined"
                    placeholder="Enter  Mobile Number"
                    sx={{ width: '440px' }}
                    margin="normal"
                    InputProps={{
                      style: {
                        borderRadius: "10px",
                      },
                      startAdornment: (
                        <InputAdornment position="start">
                          <PhoneIcon />
                        </InputAdornment>
                      ),
                    }}
                    InputLabelProps={{
                      shrink: true,
                      sx: {
                        transform: 'translate(14px, -6px) scale(0.75)',
                        pointerEvents: 'none',
                      },
                    }}
                  />
                </Box>
              </Box>
            </Box>

            
          </DialogContent> */}
      </Box>
      <Sidebar />
    </Box>
  );
};

export default AdminUser;

// import React, { useRef, useState } from 'react';
// import {Box,Button} from '@mui/material'
// const AdminUser = () => {
//   const [user_photo, setUser_photo] = useState(null);
//   const fileInputRef = useRef(null);

//   const handleButtonClick = () => {
//     fileInputRef.current.click();
//   };

//   const handleFileChange = (event) => {
//     const imgFile = event.target.files[0];
//     if (imgFile) {
//       setUser_photo(imgFile);
//       console.log('imgFile:', imgFile);
//       console.log('user_photo:', user_photo);
//     }
//   };

//   return (
//     <div>
//       <Box sx={{ marginLeft: '-25%' ,color:'red',backgroundColor:'grey'}}>
//         <img src={user_photo} style={{ height: '100px', width: '100px', marginTop: '8px', marginRight: '10px', borderRadius: '50px' }} />
//       </Box>
//       <Box sx={{ color: 'grey', marginTop: '20px', fontSize: '13px' }}>
//         <Button onClick={handleButtonClick} style={{ textTransform: 'none', color: 'grey' }}>
//           Click here to upload image or Drag & Drop
//         </Button>
//         <input
//           type="file"
//           accept="image/*"
//           hidden
//           ref={fileInputRef}
//           onChange={handleFileChange}
//         />
//       </Box>
//     </div>
//   );
// };
// export default AdminUser;