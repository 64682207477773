// import AsyncStorage from '@react-native-async-storage/async-storage';
// Import AsyncStorage
/**
 * Updates user profile information including their full name and profile picture.
 *
 * @param {string} user_id - The ID of the user whose profile is to be updated.
 * @param {string} org_id - The organization ID related to the user's profile.
 * @param {string} full_name - The new full name to update in the user's profile.
 * @param {string} token - User authentication token for API authorization.
 * @param {object} image - The user's profile image to update (optional).
 * @returns {Promise<object>} - The API response after updating the profile.
 */
export const updateProfile = async (
  user_id,
  org_id,
  full_name,
  token,
  profilePhoto
) => {
  try {
    var myHeaders = new Headers();
    myHeaders.append("token", token);

    var formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", org_id.toString());
    formdata.append("full_name", full_name.toString());
    formdata.append("photo", profilePhoto);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(
      `${window.constants.api_url}/profile-update`,
      requestOptions
    );
    const result = await response.json();
    return result;
    console.log(result);
  } catch (error) {
    console.log("error", error);
  }
};

//Changes the user's password by sending a request to the  API endpoint.
export async function changePassword(
  user_id,
  old_password,
  new_password,
  token
) {
  try {
    const userData = {
      user_id,
      old_password,
      new_password,
    };

    const headers = {
      token: token,
      "Content-Type": "application/json",
    };

    const response = await fetch(`${window.constants.api_url}/changepassword`, {
      method: "POST",
      headers,
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("API request error:", error);
    throw error;
  }
}
/**
 * Logs a user in by sending a request to the  login API endpoint.
 *
 * @param {string} email - The user's email address for login.
 * @param {string} password - The user's password for login authentication.
 * @returns {Promise<object>} - The API response after the login attempt.
 */
export const loginUser = async (email, password) => {
  let BaseURL = `${window.constants.api_url}/login`;
  let userData = {
    email_id: email,
    password: password,
  };

  try {
    const response = await fetch(BaseURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (response.status === 200) {
      const res = await response.json();
      return res;
    } else {
      return { code: "error", message: "Login failed" };
    }
  } catch (error) {
    console.error("Error:", error);
    return { code: "error", message: "An error occurred" };
  }
};
export const forgotPassword = async (email) => {
  let BaseURL = `${window.constants.api_url}/forgot-password`;
  let userData = {
    email: email,
  };

  try {
    const response = await fetch(BaseURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (response) {
      const res = await response.json();
      return res;
    } else {
      return { code: "400", message: "Server Error" };
    }
  } catch (error) {
    console.error("Error:", error);
    return { code: "error", message: "An error occurred" };
  }
};
export const setNewPassword = async (password,reset_token) => {
  let BaseURL = `${window.constants.api_url}/setnew-password`;
  let userData = {
    password: password,
    reset_token,reset_token
  };

  try {
    const response = await fetch(BaseURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    if (response) {
      const res = await response.json();
      return res;
    } else {
      return { code: "400", message: "Server Error" };
    }
  } catch (error) {
    console.error("Error:", error);
    return { code: "error", message: "An error occurred" };
  }
};
// try {
//   const formData = new FormData();
//   console.log(user_id, org_id, full_name, token)
//   formData.append("user_id", user_id);
//   formData.append("org_id", org_id);
//   formData.append("full_name", full_name);
//   // formData.append("photo", profilePhoto);

//   console.log(formData)
//   const headers = {
//     'Accept': 'application/json',
//     'Content-Type': 'multipart/form-data',
//     token: token,
//   };

//   const requestOptions = {
//     method: "POST",
//     headers: headers,
//     body: formData,
//   }
//   console.log(formData.full_name)
//   const response = await fetch(`${window.constants.api_url}/profile-update`, requestOptions);
//   const data = await response.json();
//   console.log(data)
//   return data;

// } catch (error) {
//   console.error("API request error:", error);
//   throw error;
// }
// apiHelper.js

export const createSearchInvoiceRequestData = (
  selectedCategory,
  selectedOption,
  value,
  filters
) => {
  const {
    searchQuery = "",
    minValue = 0,
    maxValue = 0,
    fromDate = null,
    toDate = null,
  } = filters || {};

  return {
    category_id: selectedCategory,
    search_type: selectedOption,
    search_text: searchQuery || value,
    amount_range: `${minValue},${maxValue}`,
    date_from: fromDate ? fromDate.toISOString() : null,
    date_to: toDate ? toDate.toISOString() : null,
  };
};

export const searchInvoices = async (
  userToken,
  user_id,
  userorg_id,
  requestData
) => {
  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(
      `${window.constants.api_url}/web/searchinvoices`,
      {
        method: "POST",
        headers,
        body: JSON.stringify({
          user_id,
          org_id: userorg_id,
          ...requestData,
        }),
      }
    );

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const undoFavorite = async (
  userToken,
  user_id,
  userorg_id,
  directoryId
) => {
  const apiEndpoint = `${window.constants.api_url}/web/undofavourite`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    doc_id: directoryId.toString(),
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const fetchFavorites = async (userToken, user_id, userorg_id, index) => {
  const apiEndpoint = `${window.constants.api_url}/web/favourite`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    start_index: index,
    limit: 20,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const getAllCategories = async (userToken, user_id, userorg_id) => {
  const apiEndpoint = `${window.constants.api_url}/web/getallcategories`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const getAllFilterOptions = async (
  userToken,
  user_id,
  userorg_id,
  category_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/getallfilteroptions`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    category_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const getAllAutoCompleteSuggestions = async (
  userToken,
  user_id,
  userorg_id,
  selectedCategory,
  selectedOption,
  search_text
) => {
  const apiEndpoint = `${window.constants.api_url}/web/getallautocomplete`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    category_id: selectedCategory,
    filter_key: selectedOption,
    search_text,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const fetchRepositoryData = async (
  userToken,
  user_id,
  userorg_id,
  account_id,
  folder_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/repository`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    account_id,
    parent_directory: folder_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
// apiHelper.js

export const fetchRepositoryByDirectoryId = async (
  userToken,
  user_id,
  userorg_id,
  account_id,
  directoryId
) => {
  const apiEndpoint = `${window.constants.api_url}/web/repository`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    account_id,
    parent_directory: directoryId.toString(),
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const InitiateMicrosoftoLgin = async (
  userToken,
  user_id,
  userorg_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/initiatemicrosoftlogin`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const markAsFavorite = async (
  userToken,
  user_id,
  userorg_id,
  directoryId,
  docname,
  docsize,
  accountid,
  lastmodified,
  access_from_repository
) => {
  const apiEndpoint = `${window.constants.api_url}/web/markfavourite`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    doc_id: directoryId.toString(),
    doc_name: docname,
    doc_size: docsize,
    account_id: accountid,
    last_modified: lastmodified,
    access_from_repository: access_from_repository,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const completeMicrosoftSignup = async (
  userToken,
  user_id,
  org_id,
  code
) => {
  const apiEndpoint = `${window.constants.api_url}/web/completemicrosoftsignup`;

  const requestData = {
    user_id,
    org_id,
    code,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const getAllAccounts = async (userToken, user_id, org_id) => {
  const apiEndpoint = `${window.constants.api_url}/web/getallaccounts`; // Update this based on your server setup

  const requestData = {
    user_id,
    org_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const RepositorySetup = async (
  userToken,
  user_id,
  org_id,
  account_id,
  parent_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/repositorysetup`; // Update this based on your server setup

  const requestData = {
    user_id,
    org_id,
    account_id,
    parent_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const repositorySetupSubmit = async (
  userToken,
  user_id,
  org_id,
  default_folder,
  account_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/repositorysetupsubmit`; // Update this based on your server setup

  const requestData = {
    user_id,
    org_id,
    default_folder,
    account_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(requestData),
    });

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const documentPreview = async (
  userToken,
  user_id,
  userorg_id,
  account_id,
  doc_id,
  page_no
) => {
  const apiEndpoint = `${window.constants.api_url}/web/previewdocument`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    account_id,
    doc_id: doc_id.toString(),
    page_no,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const AssignCategoryDetails = async (
  userToken,
  user_id,
  userorg_id,
  account_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/getcategoryassignment`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    account_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const AssignmentCategory = async (
  userToken,
  user_id,
  userorg_id,
  account_id,
  item_id,
  cat_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/assigntocategory`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    account_id,
    item_id,
    cat_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
export const UnAssignmentCategory = async (
  userToken,
  user_id,
  userorg_id,
  account_id,
  item_id
) => {
  const apiEndpoint = `${window.constants.api_url}/web/unassigncategory`;

  const responsedata = {
    user_id,
    org_id: userorg_id,
    account_id,
    item_id,
  };

  const headers = {
    token: userToken,
    "Content-Type": "application/json",
  };

  try {
    const response = await fetch(apiEndpoint, {
      method: "POST",
      headers,
      body: JSON.stringify(responsedata),
    });

    const res = await response.json();
    return res;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
