import styles from "./SarchInvoices.module.css";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import {
  createSearchInvoiceRequestData,
  searchInvoices,
  getAllCategories,
  getAllFilterOptions,
  getAllAutoCompleteSuggestions,
  documentPreview,
} from "../ApiHelper/Profile.js";
import {
  UpdateinvoicEextraction,
  invoiceExtractionDetails,
} from "../AdminConsole/AdminConsoleApiHelper/AdminApiHelper.js";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import React, { useState, useEffect, useRef } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useTheme } from "@mui/material/styles";
import Sidebar from "../Includes/Sidebar";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import Chip from "@mui/material/Chip";

import Checkbox from "@mui/material/Checkbox";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";
import Iframe from "react-iframe";
import { Card, CardContent, Button } from "@mui/material";
import Table from "@mui/material/Table";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Box,
  CardActions,
} from "@mui/material";
import { TextField, InputAdornment } from "@mui/material";
import Divider from "@mui/material/Divider";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Link from "@mui/material/Link";
import { blue } from "@mui/material/colors";
import Popover from "@mui/material/Popover";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import {
  BeatLoader,
  RingLoader,
  MoonLoader,
  BarLoader,
  FadeLoader,
  ScaleLoader,
} from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DataTable from "../AdminConsole/InvoiceExtraction/dataTable.jsx";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const SearchInvoices = () => {
  const [starredRows, setStarredRows] = useState([]);
  const [catagories, setcatagories] = useState();
  const [advancefilter, setadvancefilter] = useState();
  const [catagoryid, setcatagoryid] = useState();
  const theme = useTheme();
  const [detailsResults, setdetailsResults] = useState([]);
  const [selectedOption, setSelectedOption] = React.useState("");
  const [loading, setLoading] = useState(true);
  const [minValue, setMinValue] = useState([]);
  const [accountid, setaccountid] = useState("");
  const [maxValue, setMaxValue] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [Selectedkey, setSelectedkey] = useState(null);
  const [Tableheader, setTableheader] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [options, setOptions] = useState([]);
  const [totalrecord, settotalrecord] = useState("");
  const [tableBodyData, setTableBodyData] = useState([]);
  const [Loadingtable, setLoadingtable] = useState(true);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [isDropdownSelected, setIsDropdownSelected] = useState(false);
  const [Catagoryname, SetCatagoryname] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [viewDtailsPage, setViewDetailsPage] = useState(false);
  const [doclink, setdoclink] = useState("");
  const [editedField, setEditedField] = useState(null);
  const [orderAmtIconChange, setOrderAmtIconChange] = useState(false);
  const [orderAmtValueChange, setOrderAmtValueChange] =
    useState("order amount");
  const [popoverData, setPopoverData] = useState([]);
  const [loadingItems, setLoadingItems] = useState({});
  const [totalsearch, settotalsearch] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [getItemid, setgetItemid] = useState("");
  // const [pageno, setpageno] = useState("");
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [loadingMore, setLoadingMore] = useState(false);
  const tableContainerRef = useRef(null);
  const [Tabvalue, setTabValue] = React.useState(0);
  const [tableData, seveTableData] = useState([]);
  const [pageno, setpageno] = useState(1);
  const [docid, setdocid] = useState("");
  const [itemid, setitemid] = useState("");
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [currentLanguage, setCurrentLanguage] = useState("English");

  const handleOrderAmtChange = (e, outerIndex, innerIndex) => {
    const updatedValue = e.target.value;

    // Assuming detailsResults is a state
    const updatedDetails = [...detailsResults];
    updatedDetails[outerIndex].entity[innerIndex].meta_value = updatedValue;
    setOrderAmtIconChange(true);
    setEditedField({ outerIndex, innerIndex });
    setdetailsResults(updatedDetails);
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedOption(value);
    setIsDropdownSelected(Boolean(value));
    setSearchQuery("");
  };
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const [selectedColumnIndex, setSelectedColumnIndex] = useState(null);
  const [totalPageNo, setTotalPageNo] = useState(1);
  const handlePopoverOpen = (event, additionalData) => {
    setPopoverAnchor(event.currentTarget);

    setPopoverData(additionalData);
  };

  const handlePopoverClose = () => {
    setPopoverAnchor(null);
    setSelectedColumnIndex(null);
  };
  const handleDelete = (metaId) => {
    // Create a copy of the detailsResults array
    const updatedDetailsResults = detailsResults.map((user) => ({
      ...user,
      entity: user.entity.map((entityItem) => ({
        ...entityItem,
        meta_value: entityItem.meta_id === metaId ? "" : entityItem.meta_value,
      })),
    }));

    // Update the state with the modified detailsResults array
    setdetailsResults(updatedDetailsResults);
  };
  const handleCategoryClick = async (catagory_id, category_name) => {
    clearFilterssdata();
    try {
      setSearchQuery("");
      setLoadingtable(true);

      setSelectedCategory(catagory_id);
      SetCatagoryname(category_name);

      Getdropdownoption(catagory_id);

      // Make the API call and wait for the response
      const tableDataResponse = await fetchTableData(catagory_id);

      // Handle the API response
      handleTableDataResponse(tableDataResponse);
      // console.log(tableDataResponse);

      // Set loading to false after the API call is complete
      setLoadingtable(false);
    } catch (error) {
      // Handle errors if any
      console.error("Error:", error);
      setLoadingtable(false); // Ensure loading is set to false in case of an error
    }
  };

  const scrollContainerRef = React.useRef(null);

  const handleScroll = (scrollDirection) => {
    const scrollContainer = scrollContainerRef.current;
    if (scrollContainer) {
      const scrollAmount = scrollDirection === "left" ? -150 : 150;
      scrollContainer.scrollBy({ left: scrollAmount, behavior: "smooth" });
    }
  };
  const handleStarClick = async (event, directoryId) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    // Assuming file and index are part of your state or props
    // If not, you need to adjust this part accordingly
    const updatedFiles = [...tableBodyData];
    const index = updatedFiles.findIndex((item) =>
      item.data[0].includes(directoryId.toString())
    );
    // const isAlreadyChecked = updatedFiles[index].data[0].includes('yes');
    const stddata = updatedFiles[index].data[0].split(",");
    console.log(stddata);
    const isAlreadyChecked = stddata[4] === "yes";
    // console.log(isAlreadyChecked)
    // Prepare data for API call
    const apiEndpoint = isAlreadyChecked
      ? `${window.constants.api_url}/web/undofavourite`
      : `${window.constants.api_url}/web/markfavourite`;

    const responsedata = {
      user_id: user_id,
      org_id: userorg_id,
      doc_id: directoryId.toString(),

      access_from_repository: "no", // Convert directoryId to string
    };

    const headers = {
      token: userToken,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers,
        body: JSON.stringify(responsedata),
      });

      const res = await response.json();
      if (res.code === "200") {
        console.log(res.message);

        // Update the UI to toggle the checkbox state
        const dataParts = updatedFiles[index].data[0].split(",");

        // Update the specific part of the array
        dataParts[4] = isAlreadyChecked ? "no" : "yes";

        // Join the array back into a string
        updatedFiles[index].data[0] = dataParts.join(",");

        // Update other parts if needed
        // updatedFiles[index].data[2] = 'your_updated_value_for_dataParts_2';

        setTableBodyData(updatedFiles);

        // Stop the event propagation to prevent the table row from being double-clicked
        event.stopPropagation();
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleLanguageClick = async (language) => {
    setCurrentLanguage(language);
    // setAnchorEl(null);
    setgetItemid(itemid);
    setLoadingItems((prevState) => ({
      ...prevState,
      [itemid]: true,
    }));
    //  console.log(itemid);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        pageno,
        language
      );

      if (responseData.code === "200") {
        setLoadingItems((prevState) => ({
          ...prevState,
          [itemid]: false,
        }));

        // #TODO
        const accountid = responseData.account_id;
        // handleRowClick(accountid, itemid);
        setViewDetailsPage(true);
        setitemid(accountid);
        setdetailsResults(responseData.data);
        setTotalPageNo(responseData.data[0].total_pages);
        // console.log("TOTAL PAGE NO.",responseData.data[0].total_pages)
        // setdetailsResults(responseData.data[0].extraction_data.summary);
        seveTableData(responseData.data[0].extraction_data.table);
        // console.log(responseData.data[0].extraction_data[0].key);
        for (const [key, value] of Object.entries(
          responseData.data[0].extraction_data.summary[0]
        )) {
          if (value.length > 0) {
            setSelectedkey(key);
          }
        }
        // for(let i=0;i<responseData.data[0].extraction_data.summary[0],keys();i++){
        //   if(responseData.data[0].extraction_data.summary[i].length>0){
        //   }
        // }
        //  setSelectedkey(extractionResults.extraction_data[0])
        // console.log("tapan:", responseData.data[0].extraction_data);

        // handleRowClick(accountid, itemid);
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const GetCatagories = async () => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllCategories(userToken, user_id, userorg_id);

      if (res.code === "200") {
        setcatagories(res.data);

        setLoading(false);
        setadvancefilter(res.adv_filter);
        if (res.data.length > 0) {
          SetCatagoryname(res.data[0].name);
          const SelectedCategoryid = res.data[0].id;
          setSelectedCategory(SelectedCategoryid);
          setcatagoryid(SelectedCategoryid);
          Getdropdownoption(SelectedCategoryid);

          const tableDataResponse = await fetchTableData(SelectedCategoryid);
          handleTableDataResponse(tableDataResponse);
        }
      } else {
        // Handle the error
        console.error("Error:", res.message);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(true);
    }
  };
  useEffect(() => {
    GetCatagories();
  }, []);
  const Getdropdownoption = async (selectedCategoryid) => {
    setLoadingOptions(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllFilterOptions(
        userToken,
        user_id,
        userorg_id,
        selectedCategoryid
      );

      if (res.code === "200") {
        setLoadingOptions(false);
        setadvancefilter(res.adv_filter);
        setOptions(res.data);
        // setStarredRows(Array(res.data.length).fill(true));
      } else {
        // Handle the error
        console.error("Error:", res.message);
        setLoadingOptions(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingOptions(true);
    }
  };

  const handleInputChange = async (event, value) => {
    if (value == null) {
      return;
    }

    setSearchQuery(value);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllAutoCompleteSuggestions(
        userToken,
        user_id,
        userorg_id,
        selectedCategory,
        selectedOption,
        value
      );

      if (res.code === "200") {
        const filteredSuggestions = res.data;
        setSearchResults(filteredSuggestions);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const fetchTableData = async (selectedCategoryid) => {
    setLoadingtable(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    const responsedata = {
      user_id: user_id,
      org_id: userorg_id,
      category_id: selectedCategoryid,
      search_type: "",
      search_text: "",
      amount_range: "",
      date_from: "",
      date_to: "",
      start_index: start,
      limit: limit,
    };

    const headers = {
      token: userToken,
      "Content-Type": "application/json",
    };

    const result = await fetch(
      `${window.constants.api_url}/web/searchinvoices`,
      {
        method: "POST",
        headers,
        body: JSON.stringify(responsedata),
      }
    );
    // console.log(result);
    return result;
  };

  const handleTableDataResponse = async (response) => {
    try {
      const res = await response.json();
      if (res.code == "200") {
        setTableBodyData(res.table_body);
        setTableheader(res.table_heading);
        console.log(res.table_heading);
        settotalrecord(res.total_records);
        setLoadingtable(false);
        setaccountid(res.account_id);

        if (res.table_body.length == 0) {
          //  console.log(res.table_body.length)
          setHasMoreData(false);
        } else {
          console.log(res.table_body.length);
          setHasMoreData(true);
        }
      } else {
        console.error("Error fetching table data:", res);
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(true);
    }
  };

  const HandelSuggestClick = async (value, filters) => {
    setLoadingtable(true);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    const {
      searchQuery = "",
      minValue = [],
      maxValue = [],
      fromDate = [],
      toDate = [],
    } = filters || {};

    const advFilter = [];

    // Iterate through the advance filter array
    advancefilter.forEach((filter, index) => {
      if (filter.type === "float") {
        // Add filter for amount range if either minValue or maxValue exists and not 0 or ''
        if (
          (minValue[index] || minValue[index] === 0) &&
          (maxValue[index] || maxValue[index] === 0)
        ) {
          const amountFilterValue = `${minValue[index] || 0},${
            maxValue[index] || 0
          }`;
          advFilter.push({
            key: filter.key,
            value: amountFilterValue,
            type: "amount_range",
          });
        }
      } else if (filter.type === "date") {
        // Add filter for date range if both fromDate and toDate exist
        if (fromDate[index] && toDate[index]) {
          const dateFilterValue = `${fromDate[index].toISOString()},${toDate[
            index
          ].toISOString()}`;
          advFilter.push({
            key: filter.key,
            value: dateFilterValue,
            type: "date_range",
          });
        } else {
          // If only one of fromDate or toDate is selected, use that for filtering
          if (fromDate[index]) {
            advFilter.push({
              key: filter.key,
              value: fromDate[index].toISOString(),
              type: "date_range",
            });
          } else if (toDate[index]) {
            advFilter.push({
              key: filter.key,
              value: toDate[index].toISOString(),
              type: "date_range",
            });
          }
        }
      }
      // Handle additional logic for other filter types if needed
    });

    // Construct the request data
    const requestData = {
      user_id: user_id,
      org_id: userorg_id,
      category_id: selectedCategory,
      search_type: selectedOption,
      search_text: searchQuery || value,
      adv_filter: advFilter,
      start_index: 0,
      limit: 10,
    };

    const headers = {
      token: userToken,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        `${window.constants.api_url}/web/searchinvoices`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      const res = await response.json();
      if (res.code == "200") {
        setaccountid(res.account_id);
        setTableBodyData(res.table_body);
        setTableheader(res.table_heading);
        settotalrecord(res.total_records);
        setLoadingtable(false);
        setHasMoreData(res.table_body.length > 0);
      } else {
        // Handle the error
        console.error("Error:", res.message);
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(false);
    }
  };

  const clearFilterssdata = async () => {
    // Clear all filter fields
    setMinValue(minValue.map(() => ""));
    setMaxValue(maxValue.map(() => ""));
    setFromDate("");
    setToDate("");
    setSelectedOption("");
    setSearchQuery("");
  };

  const clearFilters = async () => {
    // Clear all filter fields
    setMinValue(minValue.map(() => ""));
    setMaxValue(maxValue.map(() => ""));
    setFromDate("");
    setToDate("");
    setSelectedOption("");
    setSearchQuery("");
    //fetchTableData(selectedCategory);
    const tableDataResponse = await fetchTableData(selectedCategory);

    // Handle the API response
    handleTableDataResponse(tableDataResponse);
    // setSelectedCategory(null);
    // GetCatagories();
  };

  const handleMinValueChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setMinValue((prevMinValue) => {
      const updatedMinValue = [...prevMinValue]; // Create a copy of the state array
      updatedMinValue[index] = value; // Update the value at the specified index
      return updatedMinValue; // Return the updated state
    });
  };
  const handleDataPaginationByPageNo = async (page_no) => {
    // setdoclink("")
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    setpageno(page_no);

    handleRowClick(accountid, getItemid, page_no);

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        page_no,
        currentLanguage
      );

      if (responseData.code === "200") {
        setLoadingItems((prevState) => ({
          ...prevState,
          [itemid]: false,
        }));

        // #TODO
        const accountid = responseData.account_id;
        setdetailsResults(responseData.data);
        // setdetailsResults(responseData.data[0].extraction_data.summary);
        seveTableData(responseData.data[0].extraction_data.table);
        // console.log(responseData.data[0].extraction_data[0].key);
        //  setSelectedkey(extractionResults.extraction_data[0])
        // console.log("tapan:", responseData.data[0].extraction_data);
        setViewDetailsPage(true);
        setaccountid(accountid);
        if (responseData.data[0].total_pages) {
          setTotalPageNo(responseData.data[0].total_pages);
        }
        for (const [key, value] of Object.entries(
          responseData.data[0].extraction_data.summary[0]
        )) {
          if (value.length > 0) {
            setSelectedkey(key);
          }
        }
        // setSelectedkey(Object.keys(responseData.data[0].extraction_data.summary[0])[0]);
        // handleRowClick(accountid, itemid);
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleMaxValueChange = (e, index) => {
    const value = e.target.value.replace(/[^0-9.]/g, "");
    setMaxValue((prevMaxValue) => {
      const updatedMaxValue = [...prevMaxValue]; // Create a copy of the state array
      updatedMaxValue[index] = value; // Update the value at the specified index
      return updatedMaxValue; // Return the updated state
    });
  };

  const handleViewDetails = async (docid, itemid, language) => {
    setdocid(docid);
    setitemid(itemid);
    setpageno(1);
    setgetItemid(itemid);
    console.log(itemid);
    setLoadingItems((prevState) => ({
      ...prevState,
      [itemid]: true,
    }));
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await invoiceExtractionDetails(
        user_id,
        userorg_id,
        docid,
        userToken,
        pageno,
        language
      );

      if (responseData.code === "200") {
        setLoadingItems((prevState) => ({
          ...prevState,
          [itemid]: false,
        }));

        const accountid = responseData.account_id;
        handleRowClick(accountid, itemid, pageno);

        setdetailsResults(responseData.data);
        setTotalPageNo(responseData.data[0].total_pages);
        // console.log("TOTAL PAGE NO>>",responseData.data[0].total_pages)

        seveTableData(responseData.data[0].extraction_data.table);
        setViewDetailsPage(true);
        for (const [key, value] of Object.entries(
          responseData.data[0].extraction_data.summary[0]
        )) {
          if (value.length > 0) {
            setSelectedkey(key);
          }
        }
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlebacknavigation = () => {
    setTabValue(0)
    setpageno(1);
    console.log("handlebacknavigation function called");
    window.scrollTo(0, 0);
    setViewDetailsPage(false);
  };
  const handleSave = async (docid, meta_id, meta_key, meta_value) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const responseData = await UpdateinvoicEextraction(
        user_id,
        userorg_id,
        docid,
        meta_id,
        meta_key,
        meta_value,
        userToken
      );

      if (responseData.code === "200") {
        setOrderAmtIconChange(false);

        console.log(responseData.data);
        // setdetailsResults(responseData.data);
        //    setViewDetailsPage(true)
      } else {
        // setLoading(false);
        console.error("Error:", responseData.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDataPagination = async (direction) => {
    // setdoclink("")
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;
    // console.log("DIREcTIONSONDOSkfn")
    if (direction === "left") {
      if (pageno == 1) {
        return;
      }
      setpageno(pageno - 1);
      handleRowClick(accountid, getItemid, pageno - 1);

      try {
        const responseData = await invoiceExtractionDetails(
          user_id,
          userorg_id,
          docid,
          userToken,
          pageno - 1,
          currentLanguage
        );

        if (responseData.code === "200") {
          setLoadingItems((prevState) => ({
            ...prevState,
            [itemid]: false,
          }));

          const accountid = responseData.account_id;
          setdetailsResults(responseData.data);
          // setTotalPageNo(responseData.data[0].total_pages)

          seveTableData(responseData.data[0].extraction_data.table);
          setViewDetailsPage(true);
          if (responseData.data[0].total_pages) {
            setTotalPageNo(responseData.data[0].total_pages);
          }
          for (const [key, value] of Object.entries(
            responseData.data[0].extraction_data.summary[0]
          )) {
            if (value.length > 0) {
              setSelectedkey(key);
            }
          }

          // handleRowClick(accountid, itemid);
        } else {
          // setLoading(false);
          console.error("Error:", responseData.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
    if (direction === "right") {
      if (pageno == totalPageNo) {
        return;
      }
      setpageno(pageno + 1);
      handleRowClick(accountid, getItemid, pageno + 1);
      try {
        const responseData = await invoiceExtractionDetails(
          user_id,
          userorg_id,
          docid,
          userToken,
          pageno + 1,
          currentLanguage
        );
        // console.log("__-__----------_-")
        console.log(responseData);

        if (responseData.code === "200") {
          setLoadingItems((prevState) => ({
            ...prevState,
            [itemid]: false,
          }));

          const accountid = responseData.account_id;
          setdetailsResults(responseData.data);
          // setTotalPageNo(responseData.data[0].total_pages)

          seveTableData(responseData.data[0].extraction_data.table);
          setViewDetailsPage(true);
          // console.log("TABLE--",responseData.data[0].extraction_data.table)
          if (responseData.data[0].total_pages) {
            setTotalPageNo(responseData.data[0].total_pages);
          }
          for (const [key, value] of Object.entries(
            responseData.data[0].extraction_data.summary[0]
          )) {
            if (value.length > 0) {
              setSelectedkey(key);
            }
          }

          // handleRowClick(accountid, itemid,pageno+1);
        } else {
          // setLoading(false);
          console.error("Error:", responseData.message);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };
  const handleRowClick = async (accountid, itemid, page_no) => {
    // Navigate to the desired location when a row is clicked
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await documentPreview(
        userToken,
        user_id,
        userorg_id,
        accountid,
        itemid,
        page_no
      );

      if (res.code === "200") {
        setLoading(false);
        setdoclink(res.doc_link);
        // window.scrollTo(0, 0);
        // window.open(res.doc_link, '_blank');
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleKeyClick = (language) => {
    // setSelectedkey(key);
    setCurrentLanguage(language);
    handleViewDetails(docid, itemid, language);
    // Add any additional logic you need when a key is clicked
  };
  const handleInput = (page_no) => {
    setpageno(page_no);
    setpageno(page_no);
    //handleRowClick(accountid, editdoc_id);
    handleRowClick(accountid, getItemid, page_no);
  };

  const handleLoadMore = async () => {
    setLoadingMore(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    const advFilter = [];

    if (fromDate instanceof Date && toDate instanceof Date) {
      const dateFilterValue = `${fromDate.toISOString()},${toDate.toISOString()}`;
      advFilter.push({
        key: "date",
        value: dateFilterValue,
        type: "date_range",
      });
    } else {
      if (fromDate instanceof Date) {
        advFilter.push({
          key: "date",
          value: fromDate.toISOString(),
          type: "date_range",
        });
      } else if (toDate instanceof Date) {
        advFilter.push({
          key: "date",
          value: toDate.toISOString(),
          type: "date_range",
        });
      }
    }

    if (minValue || maxValue) {
      const amountFilterValue = `${minValue},${maxValue}`;
      advFilter.push({
        key: "amount",
        value: amountFilterValue,
        type: "amount_range",
      });
    }

    let responsedata = {
      user_id: user_id,
      org_id: userorg_id,
      category_id: selectedCategory,
      search_type: selectedOption,
      search_text: searchQuery,
      adv_filter: advFilter,
      start_index: tableBodyData.length, // Start from the next index
      limit: 10, // Limit to 10 records per page
    };
    // console.log(responsedata);
    const headers = {
      token: userToken,
      "Content-Type": "application/json",
    };

    try {
      const response = await fetch(
        `${window.constants.api_url}/web/searchinvoices`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(responsedata),
        }
      );

      const res = await response.json();

      if (res.code == "200") {
        setaccountid(res.account_id);
        setTableBodyData((prevData) => [...prevData, ...res.table_body]); // Append new data to existing data
        setTableheader(res.table_heading);
        settotalrecord(res.total_records);
        setLoadingMore(false);
        if (res.table_body.length === 0) {
          setHasMoreData(false);
        } else {
          setHasMoreData(true);
          // console.log("hasMoreData:", hasMoreData);
        }
      } else {
        console.error("Error:");
        setLoadingtable(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoadingtable(false);
    }
  };

  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    // Check if tableContainerRef.current is not null before adding event listener
    if (tableContainer) {
      const handleScroll = () => {
        // Check if the user has scrolled to the bottom of the table container
        if (
          tableContainer.scrollTop + tableContainer.clientHeight >=
          tableContainer.scrollHeight
        ) {
          // Trigger the load more function when scrolled to the bottom
          handleLoadMore();
        }
      };

      // Add scroll event listener to the table container
      tableContainer.addEventListener("scroll", handleScroll);

      return () => {
        // Remove scroll event listener on component unmount
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [handleLoadMore, tableContainerRef]); // Ensure this effect runs only once, similar to componentDidMount

  const formatDateString = (dateString) => {
    const [day, month, year] = dateString.split("-");
    const ordinalDay = getOrdinalSuffix(parseInt(day));
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    return `${ordinalDay} ${months[parseInt(month) - 1]} ${year}`;
  };

  const getOrdinalSuffix = (day) => {
    const lastDigit = day % 10;
    const secondLastDigit = Math.floor(day / 10) % 10;

    if (secondLastDigit === 1) {
      return `${day}th`;
    }

    if (lastDigit === 1) {
      return `${day}st`;
    }

    if (lastDigit === 2) {
      return `${day}nd`;
    }

    if (lastDigit === 3) {
      return `${day}rd`;
    }

    return `${day}th`;
  };

  return (
    <Box
      component="main"
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <div className={styles.sarchInvoices}>
        {viewDtailsPage ? (
          <Box
            sx={{
              textAlign: "left",
              marginLeft: "110px",
              paddingLeft: "20px",
              //marginTop: '100px',
              clear: "both",
              paddingRight: "20px",
              height: "calc(100vh - 20px)",
              overflowY: "hidden", // Change overflowY to hidden
              position: "relative", // Add position relative to allow absolute positioning of child elements
            }}
          >
            {/* Rest of your code */}

            <Box sx={{ marginTop: "100px" }}>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ color: "#005AC1", marginTop: "2px" }}>
                  {" "}
                  <ArrowBackIcon
                    sx={{ fontSize: 20, cursor: "pointer" }}
                    onClick={handlebacknavigation}
                  />
                </Box>
                <Box sx={{ marginTop: "2px" }}>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      color: "#005AC1",
                      fontWeight: "500",
                      cursor: "pointer",
                    }}
                    onClick={handlebacknavigation}
                  >
                    Go back
                  </Typography>
                </Box>
              </Box>
            </Box>
            {detailsResults && (
              <Box sx={{ display: "flex" }}>
                {detailsResults.map((user, index) => (
                  <Box
                    sx={{
                      marginRight: "20px",
                      padding: "15px",
                      // width: "100%",
                      width: "40vw",
                      minWidth: "600px",
                      borderRadius: "8px",
                      backgroundColor: "white",
                    }}
                  >
                    <Box
                      sx={{
                        padding: "12px",
                        borderRadius: "8px",
                        marginBottom: "8px",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                          <Tabs
                            value={Tabvalue}
                            onChange={handleTabChange}
                            aria-label="basic tabs example"
                          >
                            <Tab label="Summary" {...a11yProps(0)} />
                            <Tab label="Tables" {...a11yProps(1)} />
                            {/* <Tab label="Tab 3" {...a11yProps(2)} />
                            <Tab label="Tab 4" {...a11yProps(2)} /> */}
                          </Tabs>
                        </Box>
                        <CustomTabPanel value={Tabvalue} index={0}>
                          <Box>
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "13px" }}
                            >
                              {user.doc_name}
                            </Typography>
                          </Box>

                          <Box key={index} sx={{ marginTop: "8px" }}>
                            {user.processing_date && (
                              <Typography sx={{ fontSize: "12px" }}>
                                Processing Date: {user.processing_date}
                              </Typography>
                            )}
                            {user.confidence && (
                              <Typography sx={{ fontSize: "12px" }}>
                                Confidence: {user.confidence}
                              </Typography>
                            )}
                            {/* {user.category_name && (
                            <Typography sx={{ fontSize: "12px" }}>
                              Category Name: {user.category_name}
                            </Typography>
                          )} */}
                            {Object.keys(
                              user.extraction_data.summary[0]
                            )[0] && (
                              <Typography sx={{ fontSize: "12px" }}>
                                Doc Type:{" "}
                                {
                                  Object.keys(
                                    user.extraction_data.summary[0]
                                  )[0]
                                }
                              </Typography>
                            )}
                            {user.extracted_page_no && (
                              <Typography sx={{ fontSize: "12px" }}>
                                Extracted Pages:{" "}
                                {user.extracted_page_no
                                  .split(",")
                                  .map((pages, idx) => (
                                    <Chip
                                      style={{
                                        margin: "3px",
                                        background:
                                          pages == pageno
                                            ? "lightblue"
                                            : "none",
                                      }}
                                      label={pages}
                                      variant="outlined"
                                      onClick={() =>
                                        handleDataPaginationByPageNo(pages)
                                      }
                                    />
                                  ))}
                              </Typography>
                            )}
                          </Box>
                          {user.extraction_data.summary.length == 0 && (
                            <p>No data extracted from this page</p>
                          )}
                          <Box>
                            {user.extraction_data.summary.map(
                              (entityItem, entityIndex) => (
                                <Box
                                  key={entityIndex}
                                  sx={{
                                    width: "100%",
                                    marginLeft: "10px",
                                    marginTop: entityIndex > 0 ? "10px" : 0,
                                    marginBottom: "10px",
                                  }}
                                >
                                  <div
                                    style={{
                                      height: "calc(100vh - 200px)",
                                      overflowY: "auto",
                                      padding: "10px",
                                      paddingBottom: "20px",
                                    }}
                                  >
                                    <div className={styles.categories}>
                                      <Box
                                        style={{
                                          display: "flex",
                                          overflowX: "auto",
                                          overflow: "hidden",
                                          marginBottom: "30px",
                                          marginTop: "10px",
                                          marginLeft: "-15px",
                                        }}
                                      >
                                        {/* Render your categories here */}
                                      </Box>
                                      {user.extraction_data.summary.map(
                                        (entityItem, entityIndex) =>
                                          user.extracted_language
                                            .split(",")
                                            .map((key, tabIndex) => (
                                              // <></>
                                              <Box
                                                key={tabIndex}
                                                className={`${
                                                  styles.inputChip
                                                } ${
                                                  currentLanguage === key
                                                    ? styles.selectedCategory
                                                    : ""
                                                }`}
                                                onClick={() =>
                                                  handleLanguageClick(key)
                                                }
                                              >
                                                {/* {extractionResults.key.length>0 && */}
                                                {key}
                                                {/* } */}
                                              </Box>
                                            ))
                                      )}
                                    </div>

                                    <div>
                                      {user.extraction_data.summary.map(
                                        (entityItem, entityIndex) =>
                                          Object.keys(entityItem).map(
                                            (key) =>
                                              Selectedkey === key && (
                                                <div key={entityIndex}>
                                                  {entityItem[key].map(
                                                    (data, dataIndex) => (
                                                      <List
                                                        key={dataIndex}
                                                        sx={{
                                                          width: "100%",
                                                          bgcolor:
                                                            "background.paper",
                                                        }}
                                                        style={{
                                                          margin: "0px",
                                                          padding: "0px",
                                                        }}
                                                      >
                                                        {Object.keys(data).map(
                                                          (dataKey) => (
                                                            <ListItem
                                                              sx={{
                                                                flex: 1,
                                                                flexDirection:
                                                                  "row" /*it was column*/,
                                                                maring: "0px",
                                                              }}
                                                              style={{
                                                                margin: "0px",
                                                                padding: "0px",
                                                              }}
                                                              key={dataKey}
                                                            >
                                                              <ListItemText
                                                                sx={{
                                                                  minWidth: 80,
                                                                  maxWidth: 170,
                                                                }}
                                                                primary={
                                                                  dataKey
                                                                }
                                                              />
                                                              <ListItemText
                                                                onClick={() =>
                                                                  handleInput(
                                                                    data[
                                                                      dataKey
                                                                    ].page_no
                                                                  )
                                                                }
                                                                noWrap
                                                                sx={{
                                                                  justifyContent:
                                                                    "start",
                                                                  // minWidth: 3,
                                                                  // maxWidth:130,
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                              >
                                                                {
                                                                  data[dataKey]
                                                                    .value
                                                                }
                                                              </ListItemText>
                                                            </ListItem>
                                                          )
                                                        )}
                                                        {/* {dataIndex <
                                                entityItem[key].length - 1 && (
                                                <hr
                                                  style={{
                                                    margin: "10px 1px",
                                                    border: "0.5px solid #ccc",
                                                  }}
                                                />
                                              )} */}
                                                      </List>
                                                      //         <Typography key={dataIndex} sx={{ fontWeight: 'bold', fontSize: '13px' }}>
                                                      //           {Object.keys(data).map((dataKey) => (
                                                      //             <div key={dataKey}>
                                                      //               <span>{dataKey} - <span style={{fontWeight: 'normal',}}>{data[dataKey].value}</span></span>

                                                      //             </div>
                                                      //           ))}
                                                      //           {dataIndex < entityItem[key].length - 1 && (
                                                      //   <hr style={{ margin: '10px 1px', border: '0.5px solid #ccc' }} />
                                                      // )}
                                                      //         </Typography>
                                                    )
                                                  )}
                                                </div>
                                              )
                                          )
                                      )}
                                    </div>
                                  </div>
                                </Box>
                              )
                            )}
                          </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={Tabvalue} index={1}>
                          {tableData.length > 0 && tableData[0]!==null && (
                            <DataTable data={tableData[0]["value"]} />
                          )}
                        </CustomTabPanel>
                      </Box>
                    </Box>
                  </Box>
                ))}
                <Box
                  sx={{
                    width: "200vw",
                    position: "relative",
                    overflowY: "hidden",
                  }}
                >
                  {loading ? (
                    // Circular progress loader and "Please wait..." text
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(255, 255, 255, 0.8)", // Add a semi-transparent background
                      }}
                    >
                      <CircularProgress />
                      <p>Please wait...</p>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        height: "550px",
                        alignItems: "center",
                        justifyContent: "center",
                        position: "reletive",
                        top: "30px",
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "rgba(255, 255, 255, 0.8)", // Add a semi-transparent background
                      }}
                    >
                      <div style={{ width: "100%", height: "100%" }}>
                        <Iframe
                          position="absolute"
                          src={doclink}
                          width="100%"
                          id="myId"
                          className="myClassname"
                          height="100%"
                          styles={{ height: "550px" }}
                        />
                        <div
                          // style={{
                          //   position: "fixed",
                          //   zIndex: "3000",
                          //   bottom: "10vh",
                          //   right: "15vw",
                          //   background: "white",
                          //   padding: "10px",
                          //   borderRadius: "10px",
                          // }}
                        >
                          {/* <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          <ChevronLeftIcon
                            onClick={() => handleDataPagination("left")}
                          />
                          <span>
                            {" "}
                            Page {pageno} / {totalPageNo}
                          </span>
                          <ChevronRightIcon
                            onClick={() => handleDataPagination("right")}
                          />
                        </div> */}
                        </div>
                      </div>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        ) : (
          <div className={styles.frameParent}>
            <div className={styles.frameGroup}>
              {/* <div className={styles.categories}></div> */}
              <div className={styles.frameContainer}>
                <div className={styles.powerSplCat1Parent}>
                  <Box
                    style={{
                      display: "flex",
                      overflowX: "auto",
                      overflow: "hidden",
                      // marginTop: "19px",
                    }}
                  >
                    {loading ? (
                      <CircularProgress
                        sx={{ width: "100%", borderRadius: "28px" }}
                      />
                    ) : (
                      catagories.map((category) => (
                        <Box
                          key={category.id}
                          className={`${styles.inputChip} ${
                            selectedCategory === category.id
                              ? styles.selectedCategory
                              : ""
                          }`}
                          onClick={() =>
                            handleCategoryClick(category.id, category.name)
                          }
                        >
                          {category.name}
                        </Box>
                      ))
                    )}
                  </Box>

                  <Typography
                    className={styles.resultsFound}
                    sx={{
                      fontSize: "12px",
                      marginTop: "10px",
                      marginLeft: "10px",
                      color: "#74777F",
                    }}
                  >
                    {totalrecord} results found
                  </Typography>
                </div>

                <Box sx={{ marginRight: "20px" }}>
                  <TextField
                    select
                    id="dropdown"
                    size="small"
                    value={selectedOption}
                    label="Select Option"
                    onChange={handleChange}
                    fullWidth
                    sx={{
                      width: "150px",
                    }}
                    InputProps={{ sx: { borderRadius: "28px" } }}
                  >
                    <MenuItem value="" disabled={loadingOptions}>
                      {loadingOptions ? "Loading Options..." : ""}
                    </MenuItem>
                    {options.map((option) => (
                      <MenuItem key={option.id} value={option.key}>
                        {option.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>

                <Box>
                  <Autocomplete
                    size="small"
                    options={searchResults}
                    freeSolo
                    inputValue={searchQuery}
                    getOptionLabel={(option) => option}
                    getOptionSelected={(option, value) => option === value} // Return the ohandleInputChangeption itself, assuming options are strings
                    onChange={(event, selectedOption) => {
                      // Call your API with the selected option
                      if (selectedOption) {
                        HandelSuggestClick(selectedOption);
                      }
                    }}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        value={searchQuery}
                        placeholder="Enter keywords for search"
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            e.preventDefault(); // Prevent default "Enter" behavior
                            e.stopPropagation();
                            HandelSuggestClick(searchQuery);
                          }
                        }}
                        InputProps={{
                          ...params.InputProps,
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon
                                sx={{
                                  cursor: "pointer",
                                }}
                                onClick={() => HandelSuggestClick(searchQuery)}
                              />
                            </InputAdornment>
                          ),
                        }}
                        sx={{
                          "& .MuiInputBase-root": {
                            borderRadius: "28px !important",
                            backgroundColor: "#DBD9DD",
                            alignItems: "center",
                            width: "260px",
                          },
                        }}
                      />
                    )}
                    disabled={!isDropdownSelected}
                    clearOnBlur={false}
                  />
                </Box>
              </div>
              <Box
                sx={{ overflowY: "auto", width: "100%", marginBottom: "30px" }}
              >
                {/* <ScaleLoader disableShrink color="#2F80ED" /> */}
                <TableContainer
                  ref={tableContainerRef}
                  component={Paper}
                  sx={{
                    boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                    marginTop: "20px",
                    maxHeight: "calc(100vh - 200px)",
                  }}
                >
                  <Table
                    sx={{ minWidth: 700 }}
                    size="small"
                    aria-label="simple table"
                  >
                    <TableHead
                      sx={{
                        position: "sticky",
                        top: 0,
                        zIndex: "999",
                        backgroundColor: "#EFF1F3",
                      }}
                    >
                      <TableRow>
                        {Tableheader.map((category) => (
                          <TableCell align="left">{category}</TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Loadingtable && (
                        <TableCell
                          colSpan={6}
                          align="center"
                          sx={{ width: "1000px" }}
                        >
                          <CircularProgress />
                        </TableCell>
                      )}
                      {!Loadingtable && tableBodyData.length === 0 && (
                        <TableRow>
                          <TableCell
                            colSpan={Tableheader.length}
                            align="center"
                          >
                            No record found
                          </TableCell>
                        </TableRow>
                      )}
                      {!Loadingtable && (
                        <>
                          {tableBodyData.map((rowData, rowIndex) => {
                            // Splitting the string into an array
                            const dataParts = rowData.data[0].split(",");
                            console.log(dataParts);
                            return (
                              <TableRow key={rowIndex}>
                                <TableCell component="th" scope="row">
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Checkbox
                                      icon={<StarBorder />}
                                      checkedIcon={<Star />}
                                      checked={dataParts[4] === "yes"}
                                      onClick={(event) =>
                                        handleStarClick(event, dataParts[1])
                                      }
                                      sx={{
                                        color:
                                          dataParts[4] === "yes"
                                            ? "grey"
                                            : "inherit",
                                      }}
                                    />
                                    {/* Using the first part as the id */}
                                    <Link
                                      to="#"
                                      title={dataParts[2]}
                                      underline="always"
                                      style={{
                                        textDecoration: "none",
                                        color: "inherit",
                                        cursor: "pointer",
                                      }}
                                    >
                                      <span
                                        style={{
                                          whiteSpace: "nowrap",
                                          textDecoration: "underline",
                                        }}
                                        onClick={() =>
                                          handleViewDetails(
                                            dataParts[0],
                                            dataParts[1],
                                            currentLanguage
                                          )
                                        }
                                      >
                                        {loadingItems[dataParts[1]] ? (
                                          <CircularProgress size={24} /> // Show loading animation when loadingItems state is true for the specific row
                                        ) : (
                                          dataParts[3]
                                        )}
                                      </span>
                                    </Link>
                                  </div>
                                </TableCell>
                                {/* Rendering other columns */}
                                {rowData.data
                                  .slice(1)
                                  .map((cellData, columnIndex) => {
                                    return (
                                      <TableCell key={columnIndex} align="left">
                                        {Array.isArray(cellData) ? (
                                          <>
                                            {cellData[0].includes("-") ? (
                                              <React.Fragment>
                                                {formatDateString(cellData[0])}
                                                {cellData.length > 1 && (
                                                  <IconButton
                                                    size="small"
                                                    style={{
                                                      borderRadius: "100px",
                                                      padding: "0px 4px",
                                                      fontSize: "14px",
                                                      marginLeft: "4px",
                                                      color: "white",
                                                      backgroundColor:
                                                        blue[500],
                                                    }}
                                                    onClick={(event) =>
                                                      handlePopoverOpen(
                                                        event,
                                                        cellData.slice(1)
                                                      )
                                                    }
                                                  >
                                                    {` +${cellData.length - 1}`}
                                                  </IconButton>
                                                )}
                                              </React.Fragment>
                                            ) : (
                                              <>
                                                {cellData[0]}
                                                {cellData.length > 1 && (
                                                  <IconButton
                                                    size="small"
                                                    style={{
                                                      borderRadius: "100px",
                                                      padding: "0px 4px",
                                                      fontSize: "14px",
                                                      marginLeft: "4px",
                                                      color: "white",
                                                      backgroundColor:
                                                        blue[500],
                                                    }}
                                                    onClick={(event) =>
                                                      handlePopoverOpen(
                                                        event,
                                                        cellData.slice(1)
                                                      )
                                                    }
                                                  >
                                                    {` +${cellData.length - 1}`}
                                                  </IconButton>
                                                )}
                                              </>
                                            )}
                                          </>
                                        ) : (
                                          cellData
                                        )}
                                      </TableCell>
                                    );
                                  })}
                              </TableRow>
                            );
                          })}
                        </>
                      )}
                    </TableBody>
                  </Table>
                  {/* {hasMoreData && totalrecord > tableBodyData.length && (
   <Button  onClick={handleLoadMore}  sx={{fontWeight:600}} disabled={loadingMore}>
   {loadingMore ? (
     <React.Fragment>
       <CircularProgress size={24} color="inherit" />
       &nbsp; Loading
     </React.Fragment>
   ) : (
     'Load More'
   )}
 </Button>
  )} */}

                  {hasMoreData && totalrecord > tableBodyData.length && (
                    <InfiniteScroll
                      dataLength={tableBodyData.length}
                      next={handleLoadMore}
                      hasMore={hasMoreData}
                      loader={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "50px",
                          }}
                        >
                          <BeatLoader disableShrink color="#2F80ED" />
                        </div>
                      }
                      endMessage={
                        <div style={{ textAlign: "center", marginTop: "10px" }}>
                          {!loadingMore && !hasMoreData && (
                            <div>No More Records</div>
                          )}
                          <IconButton
                            onClick={() => window.scrollTo(0, 0)}
                            style={{ color: "#2F80ED" }}
                            size="medium"
                          >
                            <KeyboardArrowUpIcon />
                          </IconButton>
                        </div>
                      }
                    />
                  )}
                </TableContainer>
              </Box>
            </div>

            <div style={{ height: "auto", marginBottom: "50px" }}>
              <Card
                style={{
                  boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
                  marginTop: "20px",
                  marginLeft: "20px",
                  marginRight: "10px",
                }}
              >
                <CardActions
                  style={{ position: "sticky", backgroundColor: "white" }}
                >
                  <Typography
                    variant="h6"
                    sx={{ fontSize: "22px", textAlign: "left" }}
                    gutterBottom
                  >
                    Filters
                  </Typography>
                </CardActions>
                <CardContent
                  sx={{ height: "calc(100vh - 230px)", overflowY: "auto" }}
                >
                  {advancefilter &&
                    advancefilter.map((filter, index) => (
                      <React.Fragment key={index}>
                        <Typography
                          variant="h6"
                          sx={{
                            fontSize: "14px",
                            fontWeight: "bold",
                            textAlign: "left",
                          }}
                          gutterBottom
                        >
                          {filter.key}
                        </Typography>

                        {filter.type === "float" && (
                          <React.Fragment>
                            <TextField
                              type="text"
                              variant="outlined"
                              onChange={(e) => handleMinValueChange(e, index)} // Pass index to identify which TextField's value is being changed
                              placeholder="Min"
                              label="Min"
                              value={minValue[index]}
                              size="small"
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 40,
                                },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                            <Typography
                              variant="h6"
                              sx={{ fontSize: "11px", marginY: "8px" }}
                              gutterBottom
                            >
                              To
                            </Typography>
                            <TextField
                              key={index}
                              type="text"
                              variant="outlined"
                              value={maxValue[index]}
                              onChange={(e) => handleMaxValueChange(e, index)} // Pass index to identify which TextField's value is being changed
                              placeholder="Max"
                              label="Max"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              size="small"
                              sx={{
                                "& .MuiInputBase-root": {
                                  height: 40,
                                },
                              }}
                            />
                            <hr
                              style={{
                                margin: "20px 0",
                                border: "none",
                                borderBottom: "1px solid #ccc",
                              }}
                            />
                          </React.Fragment>
                        )}

                        {filter.type === "date" && (
                          <React.Fragment>
                            <Box sx={{ marginBottom: "10px" }}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoItem components={["DatePicker"]}>
                                  <DatePicker
                                    value={fromDate[index] || null} // Use null if fromDate[index] is falsy
                                    onChange={(date) =>
                                      setFromDate((prevDates) => {
                                        const newDates = [...prevDates];
                                        newDates[index] = date;
                                        return newDates;
                                      })
                                    }
                                    sx={{
                                      "& .MuiInputBase-root": {
                                        height: 30,
                                      },
                                    }}
                                    format="DD/MM/YYYY"
                                    clearable
                                    renderInput={(params) => (
                                      <TextField {...params} />
                                    )}
                                  />
                                </DemoItem>
                              </LocalizationProvider>
                            </Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DemoItem components={["DatePicker"]}>
                                <DatePicker
                                  value={toDate[index] || null} // Use null if toDate[index] is falsy
                                  onChange={(date) =>
                                    setToDate((prevDates) => {
                                      const newDates = [...prevDates];
                                      newDates[index] = date;
                                      return newDates;
                                    })
                                  }
                                  minDate={fromDate[index]}
                                  sx={{
                                    "& .MuiInputBase-root": {
                                      height: 30,
                                    },
                                  }}
                                  format="DD/MM/YYYY"
                                  clearable
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="To"
                                      placeholder={toDate[index] ? "" : "To"}
                                    />
                                  )}
                                />
                              </DemoItem>
                            </LocalizationProvider>
                            <hr
                              style={{
                                margin: "20px 0",
                                border: "none",
                                borderBottom: "1px solid #ccc",
                              }}
                            />
                          </React.Fragment>
                        )}

                        {/* Additional logic for other filter types */}
                      </React.Fragment>
                    ))}
                </CardContent>
                {/* Additional Cards */}
                <CardActions
                  style={{
                    position: "sticky",
                    bottom: "0",
                    backgroundColor: "white",
                  }}
                >
                  <Box
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Button
                      variant="contained"
                      sx={{
                        fontSize: "12px",
                        whiteSpace: "nowrap",
                        textTransform: "none",
                        fontWeight: "bold",
                      }}
                      onClick={() =>
                        HandelSuggestClick(searchQuery, {
                          selectedCategory,
                          selectedOption,
                          minValue,
                          maxValue,
                          fromDate,
                          toDate,
                        })
                      }
                    >
                      Apply Filter
                    </Button>

                    <Button variant="outlined" onClick={clearFilters}>
                      <Typography
                        sx={{
                          fontSize: "12px",
                          whiteSpace: "nowrap",
                          textTransform: "none",
                          fontWeight: "bold",
                        }}
                      >
                        {" "}
                        Clear Filter
                      </Typography>
                    </Button>
                  </Box>
                </CardActions>
              </Card>
            </div>
          </div>
        )}
        <Sidebar />
      </div>

      <Popover
        open={Boolean(popoverAnchor)}
        anchorEl={popoverAnchor}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography style={{ padding: 10 }}>
          {popoverData.map((data, idx) => (
            <li key={idx}>{data}</li>
          ))}
        </Typography>
      </Popover>
    </Box>
  );
};

export default SearchInvoices;
