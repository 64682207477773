import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAllAccounts,
  InitiateMicrosoftoLgin,
  AssignCategoryDetails,
  AssignmentCategory,
  UnAssignmentCategory,
} from "../ApiHelper/Profile.js";
import { styled } from "@mui/system";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

import DialogContentText from "@mui/material/DialogContentText";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  Card,
  CardContent,
  CardActions,
  IconButton,
  AppBar,
  Toolbar,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile"; // Import file icon
import Sidebar from "../Includes/Sidebar";
import MoreVertIcon from "@mui/icons-material/MoreVert"; // Import menu icon
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import {
  Modal,
  Select,
  MenuItem,
  InputLabel,
  CircularProgress,
} from "@mui/material";

export default function OnedriveAccount() {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const [selectedCategory, setSelectedCategory] = useState("");
  const [loadingAssign, setLoadingAssign] = useState({});
  const [loadingUnassign, setLoadingUnassign] = useState({});
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [confirmationItemId, setConfirmationItemId] = useState("");
  const [assignname, setassignname] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchresult, Setsearchresult] = useState([]);
  const [secondSnackbarOpen, setSecondSnackbarOpen] = useState(false);
  const showSnackbar = state?.showSnackbar || false;
  const message = state?.message || "";
  const [loading, setLoading] = useState(false); // Add loading state
  const [categoryData, setCategoryData] = useState([]);
  const [masterData, setMasterData] = useState([]);
  const [useraccount_id, setuseraccount_id] = useState("");
  const [assignmessage, setassignmessage] = useState("");
  const StyledAppBar = styled(AppBar)({
    background: "white",
    color: "black",
  });
  const StyledTypography = styled(Typography)({
    color: "var(--Dark-grey, #606060)",

    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px", // 150%
  });
  const StyledheadTypography = styled(Typography)({
    color: "var(--Dark-grey, #606060)",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px", // 150%
  });
  const StyledTy = styled(Typography)({
    fontWeight: "bold",
    color: "var(--m-3-sys-light-on-surface, #1B1B1F)",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px", // 142.857%
    textAlign: "left",
    marginTop: "10px",
  });
  const StyledButton = styled(Button)({
    color: "var(--m-3-sys-light-on-primary, #FFF)",
    textAlign: "center",

    fontSize: "14px",
    padding: "12px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: "20px", // 142.857%
    borderRadius: "4px",
    backgroundColor: "var(--m-3-sys-light-primary, #005AC1)",
  });
  const Styledgraphy = styled(Typography)({
    color: "var(--m-3-sys-light-on-surface, #1B1B1F)",
    fontWeight: "bold",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px", // 150%
    textAlign: "left",
  });
  const StyledTitleTypography = styled(Typography)({
    color: "var(--m-3-sys-light-secondary, #575E71)",

    fontSize: "12px",
    fontStyle: "normal",

    lineHeight: "20px", // 142.857%
    fontStyle: "italic",
  });
  useEffect(() => {
    if (showSnackbar) {
      setSecondSnackbarOpen(true);
    }
  }, [showSnackbar]);
  const repositoryConnect = (account_id, email) => {
    navigate("/default-repository", {
      state: { account_id, email },
    });
  };
  const repositoryConnectcomp = (account_id, email, folder_id) => {
    navigate("/repositoryconnected", {
      state: { account_id, email, folder_id },
    });
  };
  const isFetchDataCalled = useRef(false);
  const FetchData = async () => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await getAllAccounts(userToken, user_id, userorg_id);

      if (res.code === "200") {
        Setsearchresult(res.data);

        //  const ids = res.data.map(account => account.account_id);
        //  setuseraccount_id(ids);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleAssignCategory = async (itemid, valueid) => {
    setLoadingAssign((prevLoadingAssign) => ({
      ...prevLoadingAssign,
      [itemid]: true,
    }));
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      console.log(useraccount_id);
      const res = await AssignmentCategory(
        userToken,
        user_id,
        userorg_id,
        useraccount_id,
        itemid,
        valueid
      );

      if (res.code === "200") {
        console.log(res.message); // This contains the category assignments
        setLoadingAssign((prevLoadingAssign) => ({
          ...prevLoadingAssign,
          [itemid]: false,
        }));
        setSecondSnackbarOpen(true);
        setassignmessage(res.message);
        // setIsModalOpen(false);
        setSelectedCategories({});
        fetchCatagoryModal(useraccount_id);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // console.log('Selected Value:', valueid);
    // console.log(itemid);
  };
  const handleunAssignCategory = async (itemid) => {
    setLoadingUnassign((prevLoadingUnassign) => ({
      ...prevLoadingUnassign,
      [itemid]: true,
    }));

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      console.log(useraccount_id);
      const res = await UnAssignmentCategory(
        userToken,
        user_id,
        userorg_id,
        useraccount_id,
        itemid
      );

      if (res.code === "200") {
        setLoadingUnassign((prevLoadingUnassign) => ({
          ...prevLoadingUnassign,
          [itemid]: false,
        }));

        setSecondSnackbarOpen(true);
        setassignmessage(res.message);
        // setIsModalOpen(false);
        setSelectedCategories({});
        fetchCatagoryModal(useraccount_id);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    // console.log('Selected Value:', valueid);
    // console.log(itemid);
  };

  useEffect(() => {
    if (!isFetchDataCalled.current) {
      FetchData();

      isFetchDataCalled.current = true;
    }
  }, [isFetchDataCalled.current]);
  const handleConnectOneDrive = async () => {
    setLoading(true);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await InitiateMicrosoftoLgin(userToken, user_id, userorg_id);

      if (res.code === "200") {
        console.log(res.url);

        window.location.href = res.url;

        setLoading(false);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchCatagoryData = async (account_id) => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await AssignCategoryDetails(
        userToken,
        user_id,
        userorg_id,
        account_id
      );

      if (res.code === "200") {
        console.log(res.item); // This contains the category assignments
        console.log(res.master); // This contains the master data for the modal dropdown
        setCategoryData(res.item);
        setMasterData(res.master);
        setLoading(false);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const fetchCatagoryModal = async (account_id) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await AssignCategoryDetails(
        userToken,
        user_id,
        userorg_id,
        account_id
      );

      if (res.code === "200") {
        console.log(res.item); // This contains the category assignments
        console.log(res.master); // This contains the master data for the modal dropdown
        setCategoryData(res.item);
        setMasterData(res.master);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const [selectedCategories, setSelectedCategories] = useState({});

  const handleSelectChange = (event, itemId) => {
    setSelectedCategories((prevSelected) => ({
      ...prevSelected,
      [itemId]: event.target.value,
    }));
  };
  const handleConfirmationOpen = (itemId, confirmassignname) => {
    setConfirmationDialogOpen(true);
    setassignname(confirmassignname);
    setConfirmationItemId(itemId);
  };

  const handleConfirmationClose = () => {
    setConfirmationDialogOpen(false);
  };

  const handleConfirmationOk = () => {
    handleunAssignCategory(confirmationItemId);
    setConfirmationDialogOpen(false);
  };
  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#EFEDF1", minHeight: "100vh" }}
    >
      <Sidebar />
      <Box
        component="main"
        sx={{
          minHeight: "100vh",
          flexGrow: 1,
          p: 3,
          position: "relative", // Ensure relative positioning
        }}
      >
        <Box
          sx={{
            marginTop: "70px",
            marginLeft: "70px",
            fontSize: "20px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "space-between",
          }}
        >
          <StyledheadTypography variant="h6" component="div">
            Sync your documents from OneDrive accounts.
          </StyledheadTypography>

          {/* <StyledButton
            variant="contained"
            onClick={handleConnectOneDrive}
            sx={{ textTransform: "none" }}
            startIcon={<CloudQueueIcon />}
          >
            Connect OneDrive Account
          </StyledButton> */}
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            p: 4,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "24px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* First Card */}
            {searchresult.map((account) => (
              <Card
                key={account.account_id}
                sx={{
                  borderRadius: "12px",
                  backgroundColor: "#FAF9FD",
                  boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                  padding: "20px",
                  textAlign: "center",
                  marginLeft: "40px",
                  gap: "16px",
                  position: "relative",
                  width: "380px",
                  height: "220px",
                  border: "1px solid #E0E0E0",
                }}
              >
                <StyledTitleTypography
                  variant="h6"
                  style={{ textAlign: "right" }}
                >
                  {account.date}
                </StyledTitleTypography>
                <FolderOpenOutlinedIcon
                  style={{
                    position: "absolute",
                    color: "blue",
                    left: "10px",
                    width: "40px",
                    height: "64px",
                  }}
                />
                <CardContent>
                  <Box style={{ marginLeft: "20px", right: "10px" }}>
                    <StyledTy variant="h6">{account.name}</StyledTy>
                  </Box>
                  <Box style={{ marginLeft: "18px", textAlign: "left" }}>
                    <StyledTitleTypography
                      variant="h6"
                      style={{ marginTop: "5px", textAlign: "left" }}
                    >
                      Account connected with
                    </StyledTitleTypography>
                    <StyledTypography variant="h6">
                      {account.email}
                    </StyledTypography>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{ display: "flex"}}
                >
                  {account.is_setup === "yes" ? (
                    <>
                      <Button
                        sx={{
                          textDecoration: "underline",
                          textTransform: "none",
                        }}
                        size="large"
                        onClick={() =>
                          repositoryConnectcomp(
                            account.account_id,
                            account.email,
                            account.folder_id
                          )
                        }
                      >
                        Go to repository{" "}
                      </Button>
                      {/* <Button
                        sx={{
                          textDecoration: "underline",
                          textTransform: "none",
                        }}
                        size="large"
                        onClick={() => {
                          // Open the modal and set the category state
                          setSelectedCategory("");
                          setIsModalOpen(true);
                          setuseraccount_id(account.account_id);
                          fetchCatagoryData(account.account_id);
                        }}
                      >
                        Assign to catagory
                      </Button> */}
                    </>
                  ) : (
                    <React.Fragment>
                      <StyledTypography variant="h6">
                        OneDrive source folder set up not done yet
                      </StyledTypography>
                      <Button
                        size="small"
                        sx={{ textDecoration: "underline" }}
                        onClick={() =>
                          repositoryConnect(account.account_id, account.email)
                        }
                      >
                        Set Now
                      </Button>
                    </React.Fragment>
                  )}
                </CardActions>
              </Card>
            ))}
          </div>
        </Box>
      </Box>
      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="none"
      >
        <DialogTitle >Assign to document type</DialogTitle>
        <TableContainer  >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell align="start">OneDrive Folder</TableCell>
                <TableCell align="center"> Document Type</TableCell>
                <TableCell align="start">Assignments</TableCell>
              </TableRow>
            </TableHead>
            {/* <TableBody>
          {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))}
        </TableBody> */}
         {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                marginLeft:"300px"
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            categoryData.map((category) => (
              
                <TableRow
              key={category.item_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                <TableCell >
                <Typography
                  sx={{
                    marginRight: 2,
                    flex: "0 0 120px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {category.item_name}
                </Typography>
                </TableCell>
                <TableCell>
                {category.assign_name && (
                  <Typography
                    sx={{
                      marginLeft: "40px",
                      flex: "0 0 120px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {category.assign_name}
                  </Typography>
                )}
                </TableCell>
                <TableCell>

                {category.assign_name ? (
                  // <Button
                  //   sx={{ marginLeft: 'auto', textTransform: 'none', fontWeight: 'bold' }}
                  //   onClick={() => handleConfirmationOpen(category.item_id,category.assign_name)}
                  //   variant="outlined"
                  //   color="error"
                  //   disabled={loadingUnassign[category.item_id]}
                  // >
                  //   {loadingUnassign[category.item_id] ? 'Unassigning...' : 'Unassign'}
                  // </Button>

                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                      textTransform: "none",
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={{ marginRight: "4px", color: "green" }}
                    />{" "}
                    Assigned
                  </Typography>
                 ) : (
                  <React.Fragment>
                    <Select
                      sx={{ width: 200, marginRight: 2, height: "30px" }}
                      value={selectedCategories[category.item_id] || "0"}
                      onChange={(e) => handleSelectChange(e, category.item_id)}
                    >
                      <MenuItem value="0" selected>
                        <em>None</em>
                      </MenuItem>
                      {masterData.map((item) => (
                        <MenuItem key={item.cat_id} value={item.cat_id}>
                          {item.cat_name}
                        </MenuItem>
                      ))}
                    </Select>

                    <Button
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        marginLeft: "auto",
                      }}
                      onClick={() =>
                        handleAssignCategory(
                          category.item_id,
                          selectedCategories[category.item_id]
                        )
                      }
                      variant="contained"
                      color="primary"
                      disabled={loadingAssign[category.item_id]}
                    >
                      {loadingAssign[category.item_id]
                        ? "Assigning..."
                        : "Assign"}
                    </Button>
                  </React.Fragment>
                )}
                </TableCell>
                </TableRow>
            ))
          )}
          </Table>
        </TableContainer>
       

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          >
            <Button
              sx={{ textTransform: "none", fontWeight: "bold" }}
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button>
          </Box>

      </Dialog>
      {/* <Modal onClose={() => setIsModalOpen(false)}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 500,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" sx={{ marginBottom: "20px" }}>
            Assign to document type
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
            {" "}
            <Typography
              sx={{
                marginRight: 2,
                flex: "0 0 120px",
                whiteSpace: "nowrap",
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              OneDrive Folder{" "}
            </Typography>
            <Typography
              sx={{
                marginRight: 2,
                flex: "0 0 120px",
                whiteSpace: "nowrap",
                fontSize: "12px",
                fontStyle: "italic",
              }}
            >
              Document Type{" "}
            </Typography>
          </Box>
          <hr />
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            categoryData.map((category) => (
              <Box
                key={category.item_id}
                sx={{ display: "flex", alignItems: "center", marginBottom: 2 }}
              >
                <Typography
                  sx={{
                    marginRight: 2,
                    flex: "0 0 120px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {category.item_name}
                </Typography>

                {category.assign_name && (
                  <Typography
                    sx={{
                      marginLeft: "40px",
                      flex: "0 0 120px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {category.assign_name}
                  </Typography>
                )}

                {category.assign_name ? (
                  // <Button
                  //   sx={{ marginLeft: 'auto', textTransform: 'none', fontWeight: 'bold' }}
                  //   onClick={() => handleConfirmationOpen(category.item_id,category.assign_name)}
                  //   variant="outlined"
                  //   color="error"
                  //   disabled={loadingUnassign[category.item_id]}
                  // >
                  //   {loadingUnassign[category.item_id] ? 'Unassigning...' : 'Unassign'}
                  // </Button>

                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "auto",
                      textTransform: "none",
                    }}
                  >
                    <CheckCircleOutlineIcon
                      sx={{ marginRight: "4px", color: "green" }}
                    />{" "}
                    Assigned
                  </Typography>
                ) : (
                  <React.Fragment>
                    <Select
                      sx={{ width: 200, marginRight: 2, height: "30px" }}
                      value={selectedCategories[category.item_id] || "0"}
                      onChange={(e) => handleSelectChange(e, category.item_id)}
                    >
                      <MenuItem value="0" selected>
                        <em>None</em>
                      </MenuItem>
                      {masterData.map((item) => (
                        <MenuItem key={item.cat_id} value={item.cat_id}>
                          {item.cat_name}
                        </MenuItem>
                      ))}
                    </Select>

                    <Button
                      sx={{
                        textTransform: "none",
                        fontWeight: "bold",
                        marginLeft: "auto",
                      }}
                      onClick={() =>
                        handleAssignCategory(
                          category.item_id,
                          selectedCategories[category.item_id]
                        )
                      }
                      variant="contained"
                      color="primary"
                      disabled={loadingAssign[category.item_id]}
                    >
                      {loadingAssign[category.item_id]
                        ? "Assigning..."
                        : "Assign"}
                    </Button>
                  </React.Fragment>
                )}
              </Box>
            ))
          )}

          <Box
            sx={{ display: "flex", justifyContent: "flex-end", marginTop: 2 }}
          >
            <Button
              sx={{ textTransform: "none", fontWeight: "bold" }}
              onClick={() => setIsModalOpen(false)}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal> */}

      <Dialog open={confirmationDialogOpen} onClose={handleConfirmationClose}>
        <DialogTitle>Are you sure?</DialogTitle>
        <DialogContent>
          <Typography>
            This action will delete all the documents and their coresponding
            extraction data related to <b>{assignname}</b>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleConfirmationClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmationOk} color="error">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={secondSnackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSecondSnackbarOpen(false)}
      >
        <SnackbarContent
          style={{
            backgroundColor: "info",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {message}
              {assignmessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={() => setSecondSnackbarOpen(false)}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </Box>
  );
}
