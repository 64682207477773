import CloseIcon from '@mui/icons-material/Close';
import React, { useState, useEffect } from 'react';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import Typography from '@mui/material/Typography';
import SearchIcon from '@mui/icons-material/Search';
import AdminSidebar from '../Includes/AdminSidebar.jsx';

import { Card, CardContent, Button ,Paper} from '@mui/material';

import AddIcon from '@mui/icons-material/Add';


import { MenuItem, FormControl, InputLabel, Select, IconButton, Box, Dialog, DialogActions, DialogContent } from '@mui/material';
import { TextField, InputAdornment } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { getAllDocumentType,getAllDoccategory } from '../AdminConsoleApiHelper/AdminApiHelper.js';
import Sidebar from '../../Includes/Sidebar.jsx';

const SearchDocType = () => {
  const navigate = useNavigate(); //
  const [openModal, setOpenModal] = useState(true);
  const [selectedValue, setSelectedValue] = useState(null);
  const [selectedName, setSelectedName] = useState(null);

  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [loadingDropdown, setLoadingDropdown] = useState(true);

  const handleCreateUser = () => {
    setOpenModal(false);
    navigate(`/admin-console/document-type`, {
      state: { selectedName, selectedValue },
    });
    console.log("name", selectedName);
  };

  const handleDropdownChange = (event) => {
    // setSelectedValue(event.target.value);
    const selectedOptionId = event.target.value;
    const selectedOption = dropdownOptions.find(
      (option) => option.id === selectedOptionId
    );
    if (selectedOption) {
      setSelectedValue(selectedOptionId);
      setSelectedName(selectedOption.name);
    }
  };
  const GetDocEntity = async () => {
    try {
      setLoadingDropdown(true);
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      const userToken = userDataObj.token;
      const user_id = userDataObj.user_id;
      const userorg_id = userDataObj.org_id;

      const res = await getAllDoccategory(user_id, userorg_id, userToken);
      if (res.code === "200") {
        console.log(res.data);
        setDropdownOptions(res.data);
        setLoadingDropdown(false);
      } else {
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoadingDropdown(false);
    }
  };
  useEffect(() => {
    GetDocEntity();
  }, []);
  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        backgroundColor: "#EFEDF1",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          marginLeft: "150px",
          marginRight: "30px",
          marginTop: "20px",
        }}
      >
        {/* <Dialog open={openModal} onClose={() => setOpenModal(false)} sx={{ "& .MuiDialog-paper": { borderRadius: "16px", width: '100%', display: 'flex', alignItems: 'flex-end', flexDirection: 'column' } }}> */}
        {/* <DialogContent> */}
        <Paper
          sx={{
            borderRadius: "16px",
            backgroundColor: "white",
            marginTop: "20vh",
            marginLeft: "20%",
            padding: "25px 25px",
            width: "fit-content",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              marginBottom: "30px",
            }}
          >
            <Typography variant="h6">Search Category type</Typography>
            {/* <Box position='absolute' left='90%'>
                <IconButton onClick={() => setOpenModal(true)}>
                  <CloseIcon />
                </IconButton>
              </Box> */}
          </Box>

          <Box display="flex" flexGrow={1}>
            <Box display="flex">
              <Box
                fontWeight="bold"
                sx={{ marginRight: "10px", marginTop: "10px" }}
              >
                Category type
                {/* Red mandatory star icon */}
                <span style={{ color: "red" }}>*</span>
              </Box>
              <Box>
                <TextField
                  select
                  sx={{ width: "400px", marginRight: "15px" }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedValue}
                  label="Select"
                  onChange={handleDropdownChange}
                  size="small"
                >
                  {loadingDropdown ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    dropdownOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.name}
                      </MenuItem>
                    ))
                  )}
                </TextField>
                {/* <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Select </InputLabel>
                  <Select
                    sx={{ width: "400px", marginRight: "15px" }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedValue}
                    label="Select"
                    onChange={handleDropdownChange}
                  >
                    {loadingDropdown ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : (
                      dropdownOptions.map((option) => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.name}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl> */}
              </Box>
            </Box>
          </Box>

          {/* </DialogContent> */}
          {/* <DialogActions sx={{ marginBottom: '10px', marginTop: '5px', marginRight: '30px', gap: '14px' }}> */}
          <Box
            sx={{
              marginTop: "15px",
              marginRight: "15px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {selectedValue ? (
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={handleCreateUser}
                sx={{ textTransform: "none", fontWeight: "bold" }}
              >
                Search
              </Button>
            ) : (
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                sx={{ textTransform: "none", fontWeight: "bold" }}
                disabled
              >
                Search
              </Button>
            )}
          </Box>
        </Paper>
        {/* </DialogActions> */}
        {/* </Dialog> */}

        {/* / Render table or other data when modal is closed */}
      </Box>
      <Sidebar />
    </Box>
  );
};

export default SearchDocType;
