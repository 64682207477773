import React, { useState, useEffect } from "react";
import Snackbar from "@mui/material/Snackbar";
import SnackbarContent from "@mui/material/SnackbarContent";
import { loginUser } from "./ApiHelper/Profile.js";
import styles from "./Login.module.css";
import {
  Grid,
  Box,
  Paper,
  TextField,
  Button,
  Typography,
  Link,
  Toolbar,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { ErrorMessage, Field, Form, Formik } from "formik";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";

const Login = () => {
  const initialValues = {
    email_id: "",
    password: "",
  };

  const [showSnackbar, setShowSnackbar] = useState(false);

  const navigate = useNavigate(); // Use useNa
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [toastdisplay, settoastdisplay] = useState("no");
  const [toastmessage, settoastmessage] = useState("");
  const [toastcolor, settoastcolor] = useState("info");
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarOpen(false);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    // Disable scrolling for the entire page
    document.body.style.overflow = "hidden";

    // Re-enable scrolling when the component is unmounted or on component cleanup
    return () => {
      document.body.style.overflow = "visible";
    };
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    console.log("123");
    setSubmitting(false);
    setLoading(true);
    try {
      const res = await loginUser(values.email_id, values.password);

      if (res.code === "200") {
        setLoading(false);

        localStorage.setItem("userdata", JSON.stringify(res.data));
        setSnackbarOpen(true);
        navigate("/dashboard", { state: { showSnackbar: true } });
      } else {
        settoastdisplay("yes");
        settoastmessage(res.message);
        settoastcolor("error");
        setSnackbarOpen(true);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const downloadFile = () => {

    /*const myHeaders = new Headers();
    myHeaders.append("token", "RFJ6cGtGQUtWQVBGZzFRa2JHLzA5QzhsdWFLWGZ5RTNCbzZBOGVYZktJV2FVaTBXSmF4cmdhNmlSWFVlZTQ3cUZSUlg5LzBVSlVGRXJ5OHFFY05DcklaQXlRSWp4NGM5RU9ZckVtL1pwY0NJVmxqcVd0bVBoMFdoMUFkd0pwVStybFEwS0xReURmQTdmdTZFb1NPQ1FkWmdoWThXVHg4K2Q0RWk5eXN3ai9RPQ==");
    //myHeaders.append("Content-Type", "application/json");

    const raw = JSON.stringify({
      user_id: "43",
      org_id: "2",
      account_id: "5",
      file_name: "driver.pdf",
      item_id: "01JKBLCDNB5YCNRAYS4BDZMHVEUYVE4K35"
    });

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow"
    };*/

    const requestOptions = {
      method: "POST",
      headers: {
        //"Content-Type": "application/json",
        "token": "RFJ6cGtGQUtWQVBGZzFRa2JHLzA5QzhsdWFLWGZ5RTNCbzZBOGVYZktJV2FVaTBXSmF4cmdhNmlSWFVlZTQ3cUZSUlg5LzBVSlVGRXJ5OHFFY05DcklaQXlRSWp4NGM5RU9ZckVtL1pwY0NLdC9oUDJQUmFCb2dnK1BLMkZKdXhLelBNWURKRUI3SzN0MFpja25VSG92djdjV01aM1haQ1hCVEptcWNEbW1FPQ=="
      },
      body: JSON.stringify({
        user_id: "43",
        org_id: "2",
        account_id: "2",
        file_name: "driver.pdf",
        item_id: "01JKBLCDNB5YCNRAYS4BDZMHVEUYVE4K35"
      }),
      redirect: "follow"
    };

    fetch("http://localhost/documentai-lumen-api-v1/web/downloaditemfromrepository", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  return (
    <Box component="main">

      {/* <div>
        <button onClick={downloadFile} >
          Download File
        </button>
      </div> */}

      <Grid className={styles.login}>
        <div>
          <div className={styles.bgIcon} alt="" />
          <div className={styles.frameParent}>
            <div className={styles.image4Wrapper}>
              <img
                alt=""
                style={{ width: "270px" }}
                src={window.constants.asset_path + "/image.png"}
              />
            </div>
            <div className={styles.groupParent}>
              <img
                className={styles.frameChild}
                alt=""
                src={window.constants.asset_path + "/group-1.svg"}
              />
              <div className={styles.loginParent}>
                <Typography variant="h4" className={styles.login1}>
                  Login
                </Typography>

                <Typography className={styles.pleaseEnterYour}>
                  Please enter your account credentials for login
                </Typography>
              </div>

              <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ values }) => (
                  <Form>
                    <div className={styles.assetcomponentbuttonParent}>
                      <Field
                        as={TextField}
                        label="Email"
                        variant="outlined"
                        name="email_id"
                        autoComplete="off"
                        InputProps={{
                          backgroundColor: "transparent",
                          startAdornment: (
                            <InputAdornment position="start">
                              <PersonIcon />
                            </InputAdornment>
                          ),
                          style: {
                            boxShadow: "0 0 0 30px #307ECC inset !important",
                          },
                        }}
                        placeholder="Email"
                        fullWidth
                      />

                      <div className={styles.assetcomponentbuttonGroup}>
                        <Field
                          as={TextField}
                          label="Password"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <LockOpenIcon />
                              </InputAdornment>
                            ),
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility}>
                                  {showPassword ? (
                                    <VisibilityIcon />
                                  ) : (
                                    <VisibilityOffIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          placeholder="Password"
                          fullWidth
                        />
                      </div>
                      {/* <a href="#" sx className={styles.forgotPassword}>Forgot Password?</a> */}

                      <Button
                        className={styles.button2}
                        type="submit"
                        variant="contained"
                        style={{ position: "relative" }}
                        fullWidth
                        disabled={loading}
                      >
                        {" "}
                        {loading && (
                          <CircularProgress
                            size={16}
                            style={{
                              position: "absolute",
                              top: "30%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              color: "white",
                            }}
                          />
                        )}
                        Login
                      </Button>
                      <a href='/forgot-password'>Forgot Password?</a>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>

            <div className={styles.deepdelveParent}>
              <Typography variant="h6" className={styles.deepdelve}>
                DeepDelveⓇ
              </Typography>
              <div className={styles.poweredByZeonaiContainer}>
                <span className={styles.poweredBy}>Powered by</span>
                <span className={styles.zeonaiLabs}> ZeonAI Labs</span>
              </div>
            </div>



          </div>
        </div>
      </Grid>
      <Snackbar
        mode="outlined"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center", // Center bottom
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{
            backgroundColor: toastcolor === "black",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              {toastmessage}
            </span>
          }
          action={[
            <IconButton
              key="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>



    </Box>
  );
};

export default Login;
