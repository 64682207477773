import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect, useRef } from "react";
import {
  RepositorySetup,
  fetchRepositoryByDirectoryId,
  markAsFavorite,
  undoFavorite,
  repositorySetupSubmit,
} from "../ApiHelper/Profile.js";
import Typography from "@mui/material/Typography";

import Sidebar from "../Includes/Sidebar";

import { Button } from "@mui/material";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import Checkbox from "@mui/material/Checkbox";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { TextField, Breadcrumbs } from "@mui/material";
import { Link } from "react-router-dom";
import { styled } from "@mui/system";
import HomeIcon from "@mui/icons-material/Home";
import ArrowBack from "@mui/icons-material/ArrowBack";

import Radio from "@mui/material/Radio";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { useLocation } from "react-router-dom";
const DefaultRepository = () => {
  const location = useLocation();
  const [starredRows, setStarredRows] = useState([]);
  const [directorry, setdirectory] = useState([]);
  const [file, setfile] = useState([]);
  const [navigation, setnavigation] = useState();
  const [useremail, setuseremail] = useState();
  const [useraccountid, setuseraccountid] = useState();
  const [loading, setLoading] = useState(true); // Add loading state
  const [gobackid, setgobackid] = useState([]);
  const [gobackstatus, setgobackstatus] = useState(true);
  const navigate = useNavigate(); // Use useNavigate
  const [history, setHistory] = useState([]);
  const [previousGobackId, setPreviousGobackId] = useState(null);
  const [showGoBackButton, setShowGoBackButton] = useState(false);
  const [selectedValues, setSelectedValues] = React.useState({});
  const [isRadioButtonSelected, setIsRadioButtonSelected] = useState(false);

  const StyledheadTypography = styled(Typography)({
    color: "var(--Dark-grey, #606060)",

    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px", // 150%
  });
  const handleRadioChange = (id) => {
    setSelectedValues(id === selectedValues ? null : id);
    setIsRadioButtonSelected(true);
  };

  const hasEffectRun = useRef(false);

  const fetchDepartment = async () => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;
    const Parent_id = 0;

    const storedAccountId = localStorage.getItem("account_id");
    const storedEmail = localStorage.getItem("email");

    const account_id = location.state?.account_id || storedAccountId;
    const email = location.state?.email || storedEmail;

    localStorage.setItem("account_id", account_id);
    localStorage.setItem("email", email);
    setuseraccountid(account_id);
    setuseremail(email);
    try {
      const res = await RepositorySetup(
        userToken,
        user_id,
        userorg_id,
        account_id,
        Parent_id
      );

      if (res.code === "200") {
        console.log(res);
        setLoading(false);
        setdirectory(res.data);
        setgobackid(res.goback_id);
        setShowGoBackButton(false);
        setnavigation(res.current_path);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const HandleSetupSubmit = async () => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await repositorySetupSubmit(
        userToken,
        user_id,
        userorg_id,
        selectedValues,
        useraccountid
      );

      if (res.code === "200") {
        console.log(res);
        navigate("/onedrive-account");
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const HandleSetupSubmitclcik = async () => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await repositorySetupSubmit(
        userToken,
        user_id,
        userorg_id,
        0,
        useraccountid
      );

      if (res.code === "200") {
        console.log(res);
        navigate("/onedrive-account");
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    if (!hasEffectRun.current) {
      fetchDepartment();
      hasEffectRun.current = true;
    }
  }, [location.state]);

  const handleDirectoryDoubleClick = async (account_id, directoryId) => {
    setLoading(true);

    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await RepositorySetup(
        userToken,
        user_id,
        userorg_id,
        account_id,
        directoryId
      );

      if (res.code === "200") {
        setdirectory(res.data);
        setgobackid(res.goback_id);

        setHistory((prevHistory) => [...prevHistory, res.goback_id]);

        setnavigation(res.current_path);
        setLoading(false);
      } else {
        setdirectory(res.data);

        setLoading(false);
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const yourCallbackFunction = (gobackid) => {
    // Your custom logic with gobackid
    console.log("Go Back ID:", gobackid);
  };
  const handleGoBack = () => {
    const previousHistory = [...history];

    previousHistory.pop();
    const previousGobackId = previousHistory[previousHistory.length - 1];

    if (previousHistory.length === 1) {
      // If there's no previous history, hide the Go Back button
      setShowGoBackButton(false);
    } else {
      setShowGoBackButton(true);
    }

    yourCallbackFunction(previousGobackId);
    fetchDepartmentdata(previousGobackId);

    setHistory(previousHistory);
  };
  const fetchDepartmentdata = async (gobackid) => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    const storedAccountId = localStorage.getItem("account_id");
    const storedEmail = localStorage.getItem("email");

    const account_id = location.state?.account_id || storedAccountId;
    const email = location.state?.email || storedEmail;

    localStorage.setItem("account_id", account_id);
    localStorage.setItem("email", email);
    setuseraccountid(account_id);
    setuseremail(email);
    try {
      const res = await RepositorySetup(
        userToken,
        user_id,
        userorg_id,
        account_id,
        gobackid
      );

      if (res.code === "200") {
        console.log(res);
        setLoading(false);
        setdirectory(res.data);
        setgobackid(res.goback_id);

        console.log(res.goback_id);
        setnavigation(res.current_path);
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    // Check if the current path is the root path and update showGoBackButton accordingly
    const isRootPath = history.length === 0;
    setShowGoBackButton(!isRootPath);
  }, [history]);

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        backgroundColor: "#EFEDF1",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      {/* Show loader while loading */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "space-between",
        }}
      >
        <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            textAlign: "left",
            marginLeft: "150px",
            marginRight: "50px",
            marginTop: "100px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            style={{
              textDecoration: "none",
              display: "flex",
              alignItems: "center",
              color: "inherit",
            }}
          >
            <HomeIcon fontSize="small" />

            {navigation}
          </Box>
        </Breadcrumbs>
        <StyledheadTypography
          variant="h6"
          component="div"
          sx={{
            marginTop: "100px",
            marginRight: "30px",
            fontSize: "14px",
            fontStyle: "italic",
          }}
        >
          Viewing account of {useremail}
        </StyledheadTypography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: "auto",
          marginLeft: "150px",
          marginRight: "30px",
          marginTop: "20px",
          marginBottom: "30px",
        }}
      >
        <Box
          sx={{
            marginBottom: "10px",

            alignContent: "right",
            zIndex: 1, // Ensure it's above other elements
          }}
        >
          {showGoBackButton && (
            <Button
              variant="contained"
              sx={{
                textTransform: "none",
                fontWeight: "bold",
                backgroundColor: "#005AC1",
              }}
              onClick={handleGoBack}
              startIcon={<ArrowBack />}
            >
              Go Back
            </Button>
          )}
        </Box>
        <TableContainer
          component={Paper}
          style={{
            boxShadow:
              "0px 1px 3px 0px rgba(0, 0, 0, 0.30), 0px 4px 8px 3px rgba(0, 0, 0, 0.15)",
          }}
        >
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: "#EFF1F3" }}>
              <TableRow>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  {" "}
                  Name
                </TableCell>
                <TableCell
                  align="left"
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  {" "}
                  Size
                </TableCell>
                <TableCell
                  sx={{ fontSize: "16px", fontWeight: "700", color: "#4A5568" }}
                >
                  Last modified
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading && (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              )}
              {!loading && directorry.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No data found
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {directorry.map((entry, index) => (
                    <TableRow
                      onDoubleClick={() =>
                        handleDirectoryDoubleClick(useraccountid, entry.id)
                      }
                      sx={{
                        cursor: "pointer",
                        "&:hover": { backgroundColor: "#E5E7EB" },
                      }}
                    >
                      <TableCell align="left">
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Radio
                            checked={entry.id === selectedValues}
                            onChange={() => handleRadioChange(entry.id)}
                            value={entry.id}
                            name="radio-buttons"
                          />
                          <FolderIcon
                            sx={{ color: "yellow", marginRight: "20px" }}
                          />{" "}
                          {entry.name}
                        </Box>
                      </TableCell>
                      <TableCell align="left">{entry.size}</TableCell>
                      <TableCell>{entry.last_modified}</TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ marginBottom: "50px" }}>
          {" "}
          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "20px",
              marginBottom: "50px",
              backgroundColor: "#005AC1",
            }}
            variant="contained"
            disabled={!isRadioButtonSelected}
            onClick={HandleSetupSubmit}
          >
            Select an option to set as the source
          </Button>
          <Button
            sx={{
              textTransform: "none",
              fontWeight: "bold",
              marginTop: "20px",
              marginLeft: "20px",
              marginBottom: "50px",
            }}
            variant="outlined"
            onClick={HandleSetupSubmitclcik}
          >
            Click here to set root as the source
          </Button>
        </Box>
      </Box>

      <Sidebar />
    </Box>
  );
};

export default DefaultRepository;
