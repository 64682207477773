export const CreateCategory = async (
    userToken,
    user_id,
    org_id,
    name,
    photo,
    ext_language,
    ext_model
  ) => {
    console.log(ext_language,ext_model,"SFSDKJNFKJSNKJD")
    const apiEndpoint = `${window.constants.api_url}/admin-console/addcategory`;   
    const formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", org_id.toString());
    formdata.append("name", name.toString());
    formdata.append("photo", photo);
    formdata.append("ext_language", ext_language);
    formdata.append("ext_model", ext_model);

    // const responsedata = {
    //     user_id,
    //     org_id,
    //     name,
    //   };


    // formdata.append("photo", fileInput.files[0], "user_profile.jpg");
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers:myHeaders,
        body:  formdata,
        redirect: "follow"
      });
  
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };
  export const editCategory = async (
    userToken,
    user_id,
    org_id,
    id,
    photo,
    ext_language,
    ext_model
  ) => {
    console.log(ext_language,ext_model,"SFSDKJNFKJSNKJD")
    const apiEndpoint = `${window.constants.api_url}/admin-console/editcategory`;   
    const formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", org_id.toString());
    formdata.append("id", id.toString());
    formdata.append("photo", photo);
    formdata.append("ext_language", ext_language);
    formdata.append("ext_model", ext_model);


    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers:myHeaders,
        body:  formdata,
        redirect: "follow"
      });
  
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

export const DeleteCategory = async (
    userToken,
    user_id,
    org_id,
    cat_id
  ) => {
    const apiEndpoint = `${window.constants.api_url}/admin-console/deletecategory`;   
  
    const responsedata = {
        user_id,
        org_id,
        cat_id,
      };


    // formdata.append("photo", fileInput.files[0], "user_profile.jpg");
    const headers = {
        token: userToken,
        "Content-Type": "application/json",
      };
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers:headers,
        body:  JSON.stringify(responsedata),
      });
  
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  export const CreateDocType = async (
    userToken,
    user_id,
    org_id,
    cat_id,
    name,
    photo,
    desc,
    alias,
  ) => {
    // console.log( desc,
    //   alias,
    //   language)
    const apiEndpoint = `${window.constants.api_url}/admin-console/adddocumenttypes`;   
    const formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", org_id.toString());
    formdata.append("cat_id", cat_id.toString());
    formdata.append("name", name.toString());
    formdata.append("photo", photo);
    formdata.append("doctype_desc", desc);
    formdata.append("alias", alias);

    // const responsedata = {
    //     user_id,
    //     org_id,
    //     name,
    //   };


    // formdata.append("photo", fileInput.files[0], "user_profile.jpg");
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers:myHeaders,
        body:  formdata,
        redirect: "follow"
      });
  
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  export const EditDocType = async (
    userToken,
    user_id,
    org_id,
    doctype_id,
    name,
    photo,
    desc,
    alias,
    language
  ) => {
    // console.log( desc,
    //   alias,
    //   language)
    const apiEndpoint = `${window.constants.api_url}/admin-console/editdocumenttype`;   
    const formdata = new FormData();
    formdata.append("user_id", user_id.toString());
    formdata.append("org_id", org_id.toString());
    formdata.append("doctype_id", doctype_id.toString());
    formdata.append("name", name.toString());
    formdata.append("photo", photo);
    formdata.append("doctype_desc", desc);
    formdata.append("doctype_alias", alias);
    formdata.append("ext_language", language);

    // const responsedata = {
    //     user_id,
    //     org_id,
    //     name,
    //   };


    // formdata.append("photo", fileInput.files[0], "user_profile.jpg");
    var myHeaders = new Headers();
    myHeaders.append("token", userToken);
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers:myHeaders,
        body:  formdata,
        redirect: "follow"
      });
  
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

export const DeleteDocType = async (
    userToken,
    user_id,
    org_id,
    cat_id,
    document_type_id
  ) => {
    const apiEndpoint = `${window.constants.api_url}/admin-console/deletedocumenttypes`;   
  
    const responsedata = {
        user_id,
        org_id,
        cat_id,
        document_type_id
      };


    // formdata.append("photo", fileInput.files[0], "user_profile.jpg");
    const headers = {
        token: userToken,
        "Content-Type": "application/json",
      };
    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        headers:headers,
        body:  JSON.stringify(responsedata),
      });
  
      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };