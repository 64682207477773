// Sidebar.js
import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Link, Navigate, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import PersonIcon from "@mui/icons-material/Person";
import styles from "./Admin.module.css";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import ListItemButton from "@mui/material/ListItemButton";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Switch from "@mui/material/Switch";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import { usePath } from "../../Includes/PathContext";
import { useAppContext } from "../../Includes/AppContext";
import LockIcon from "@mui/icons-material/Lock";
import FormControlLabel from "@mui/material/FormControlLabel";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from "@mui/material";
import BallotIcon from "@mui/icons-material/Ballot";
import { NavLink } from "react-router-dom";

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import FavoriteIcon from "@mui/icons-material/Favorite";


const drawerWidth = 130;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  background: "white", // Set the background color to white
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const AdminSidebar = () => {
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [fullName, setFullName] = useState("");
  const [usertoken, setusertoken] = useState("");
  const [user_id, setuser_id] = useState("");
  const [userorg_id, setuserorg_id] = useState("");
  const [rolename, setrolename] = useState("");
  const [pic, setPic] = useState("");
  const handleLogout = () => {
    setLogoutDialogOpen(true);
  };
  const Navigation = () => {
    navigate("/change-password");
  };
  const updateUserDataFromLocalStorage = () => {
    const storedUserData = localStorage.getItem("userdata");
    console.log(storedUserData);
    if (storedUserData) {
      const userDataObj = JSON.parse(storedUserData);
      const {
        token: userToken,
        user_id: deepuser_id,
        org_id: deeporg_id,
        full_name: user_full_name,
        picture: picture,
        role_name: role_name,
      } = userDataObj;

      setPic(picture);
      setusertoken(userToken);
      setuser_id(deepuser_id);
      setuserorg_id(deeporg_id);
      setFullName(user_full_name);
      setrolename(role_name);
    }
  };
  useEffect(() => {
    updateUserDataFromLocalStorage();
  }, []);
  const confirmLogout = () => {
    // Implement your logout logic here
    // For example, redirect to the login page or clear user session
    console.log("Logout confirmed");
    handleClose();
    // navigate('/'); // Change '/login' to your actual login page path
    localStorage.removeItem("userdata");
    window.location.replace(`${window.constants.base_path}`);
  };

  const cancelLogout = () => {
    setLogoutDialogOpen(false);
  };

  const navigate = useNavigate(); // U
  const { searchText } = useAppContext();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const { currentPath, setCurrentPath } = usePath();
  // const currentPath = window.location.hash.substr(1);

  useEffect(() => {
    // Set the current path when the component mounts
    setCurrentPath(window.location.pathname);
  }, [setCurrentPath]);
  const reloadText = (currentPath) => {

    // console.log(currentPath)
    if (currentPath === '/documentai-zeonai/admin-console/user')
      return ('Manage User');
    else if (currentPath === '/documentai-zeonai/admin-console/document-type')
      return ('Document Type')
      else if (currentPath === '/documentai-zeonai/admin-console/searchdocument-type')
      return ('Document Type')

    
    else if (currentPath === '/documentai-zeonai/admin-console/document-entity')
      return ('Document Entity')
    else if (currentPath === '/documentai-zeonai/admin-console/document-category')
      return ('Document Category')
    else if (currentPath === '/documentai-zeonai/admin-console/invoice-extraction')
      return('Manage Extraction')
    else
      return ('Document Entity')
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = () => {
    // Introduce a 1-second delay using setTimeout
    setTimeout(() => {
      setIsAdmin((prevIsAdmin) => !prevIsAdmin);
      navigate("/dashboard");
    }, 300); // 1000 milliseconds = 1 second
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const location = useLocation();

  return (
    <>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar>
          {/* Replace the following image with your account icon */}
          <img
            alt=""
            src={window.constants.asset_path + "/image.png"}
            style={{ width: "160px" }}
          />

          <Typography
            variant="h6"
            color="#003776"
            noWrap
            component="div"
            sx={{ marginLeft: "30px" }}
          >
            {searchText ? searchText : reloadText(currentPath)}
          </Typography>
          <div style={{ flexGrow: 1 }} />

          <NavLink
            to={`/dashboard`}
            style={{
              color: "#2F80ED",
              display: "flex",
              textDecoration: "none",
            }}
          >
            <ArrowBackIcon />
            <Typography>Go to User</Typography>
          </NavLink>
          {/* <IconButton color="inherit"> */}
          {/* Replace the following line with your notification icon */}
          {/* <NotificationsIcon style={{ color: 'grey' }}/> */}
          {/* </IconButton> */}
          <IconButton onClick={handleClick} color="inherit">
            {/* Replace the following line with your account icon */}
            <AccountCircleIcon style={{ color: "grey" }} />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <ListItem onClick={handleClose}>
              {/* <ListItemIcon>
              <PersonIcon />
            </ListItemIcon> */}
              {fullName}
            </ListItem>
            <ListItem onClick={handleSwitchChange}>
              {/* <ListItemIcon>
            <ViewQuiltIcon />
            </ListItemIcon> */}
              {rolename}
            </ListItem>
            <Divider />
            <MenuItem onClick={Navigation}>
              <ListItemIcon>
                <LockIcon />
              </ListItemIcon>
              Change Password
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <ListItemIcon>
                <PowerSettingsNewIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          "& .MuiDrawer-paper": { width: drawerWidth },
        }}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronRightIcon />
            ) : (
              <ChevronLeftIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />

        <List>
          <div style={{ marginRight: "20px", marginTop: "17px" }}>
            <ListItem
              component={Link}
              to="/admin-console/user"
              className={`${styles.menuitem1} ${
                location.pathname === "/admin-console/user" ? styles.active : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/admin-console/user"
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px",
                marginLeft: "10px",
                marginBottom: "16px",
                whiteSpace: "normal",
              }}
            >
              <ListItemIcon>
                <PersonIcon
                  style={{ alignItems: "center", marginLeft: "15px" }}
                  color={
                    location.pathname === "/admin-console/user"
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Manage User"
                style={{
                  color:
                    location.pathname === "/admin-console/user"
                      ? "blue"
                      : "inherit",
                }}
              />
            </ListItem>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/searchdocument-type"
              className={`${styles.menuitem1} ${
                location.pathname === "/admin-console/searchdocument-type" ||
                location.pathname.startsWith("/admin-console/document-type")
                  ? styles.active
                  : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/admin-console/searchdocument-type" ||
                  location.pathname.startsWith("/admin-console/document-type")
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px",
                marginLeft: "10px",
                marginBottom: "16px",
                whiteSpace: "normal",
              }}
            >
              <ListItemIcon>
                <InsertDriveFileIcon
                  style={{ alignItems: "center", marginLeft: "15px" }}
                  color={
                    location.pathname === "/admin-console/document-type"
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Document Type"
                style={{
                  color:
                    location.pathname ===
                      "/admin-console/searchdocument-type" ||
                    location.pathname.startsWith("/admin-console/document-type")
                      ? "blue"
                      : "inherit",
                }}
              />
            </ListItem>
          </div>
          {/* <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/document-entity"
              className={`${styles.menuitem1} ${
                location.pathname === "/admin-console/document-entity" ||
                location.pathname.startsWith("/admin-console/managedoc-entity")
                  ? styles.active
                  : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/admin-console/document-entity" ||
                  location.pathname.startsWith(
                    "/admin-console/managedoc-entity"
                  )
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px",
                marginLeft: "10px",
                marginBottom: "16px",
                whiteSpace: "normal",
              }}
            >
              <ListItemIcon>
                {/* Replace with your icon for Document Entity */}
                {/* <BusinessCenterIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={(location.pathname === '/admin-console/document-entity' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'primary' : 'inherit'} />
              </ListItemIcon>
              <ListItemText primary="Document Entity" style={{
                color: (location.pathname === '/admin-console/document-entity' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'blue' : 'inherit',
              }} />
            </ListItem> */}

          {/* </div> */}
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/document-category"
              className={`${styles.menuitem1} ${(location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? styles.active : ''}`}
              // className={`${styles.menuitem1} ${(location.pathname === '/admin-console/document-category') ? styles.active : ''}`}

              style={{
                textAlign: 'center',
                backgroundColor: (location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'rgba(25, 118, 210, 0.2)' : 'inherit',
                // backgroundColor: (location.pathname === '/admin-console/document-category') ? 'rgba(25, 118, 210, 0.2)' : 'inherit',

                padding: '8px',
                marginLeft: '10px',
                marginBottom: '16px',
                whiteSpace: 'normal',
              }}
            >
              <ListItemIcon>
                {/* Replace with your icon for Document Entity */}
                <InsertDriveFileIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={(location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'primary' : 'inherit'} />
                {/* <InsertDriveFileIcon style={{ alignItems: 'center', marginLeft: '15px' }} color={(location.pathname === '/admin-console/document-category') ? 'primary' : 'inherit'} /> */}

              </ListItemIcon>
              <ListItemText primary="Document Management" style={{
                color: (location.pathname === '/admin-console/document-category' || location.pathname.startsWith('/admin-console/managedoc-entity')) ? 'blue' : 'inherit',
                // color: (location.pathname === '/admin-console/document-category') ? 'blue' : 'inherit',

              }} />

            </ListItem>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/admin-console/invoice-extraction"
              className={`${styles.menuitem1} ${
                location.pathname === "/admin-console/invoice-extraction" ||
                location.pathname.startsWith(
                  "/admin-console/invoice-extraction"
                )
                  ? styles.active
                  : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/admin-console/invoice-extraction" ||
                  location.pathname.startsWith(
                    "/admin-console/invoice-extraction"
                  )
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px",
                marginLeft: "10px",
                marginBottom: "16px",
                whiteSpace: "normal",
              }}
            >
              <ListItemIcon>
                {/* Replace with your icon for Document Entity */}
                <BallotIcon
                  style={{ alignItems: "center", marginLeft: "15px" }}
                  color={
                    location.pathname === "/admin-console/invoice-extraction" ||
                    location.pathname.startsWith(
                      "/admin-console/invoice-extraction"
                    )
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Manage Extraction"
                style={{
                  color:
                    location.pathname === "/admin-console/invoice-extraction" ||
                    location.pathname.startsWith(
                      "/admin-console/invoice-extraction"
                    )
                      ? "blue"
                      : "inherit",
                }}
              />
            </ListItem>
          </div>
          <div style={{ marginRight: "20px" }}>
            <ListItem
              component={Link}
              to="/favourite-list"
              className={`${styles.menuitem1} ${
                location.pathname === "/favourite-list" ? styles.active : ""
              }`}
              style={{
                textAlign: "center",
                backgroundColor:
                  location.pathname === "/favourite-list"
                    ? "rgba(25, 118, 210, 0.2)"
                    : "inherit",
                padding: "8px", // Adjust the padding as needed
                marginLeft: "10px",
                marginBottom: "16px",
              }}
            >
              <ListItemIcon>
                <FavoriteIcon
                  style={{ alignItems: "center", marginLeft: "10px" }}
                  color={
                    location.pathname === "/favourite-list"
                      ? "primary"
                      : "inherit"
                  }
                />
              </ListItemIcon>
              <ListItemText
                primary="Favourite"
                style={{
                  color:
                    location.pathname === "/favourite-list"
                      ? "blue"
                      : "inherit",
                }}
              />
            </ListItem>
          </div>
          
          {/* <div style={{ marginRight: "20px" }}>
          <ListItem
            onClick={handleLogout}
            className={styles.menuitem1}
            style={{
              textAlign: 'center',
              padding: '8px',
              marginLeft: '10px',
              marginBottom: '16px',
              whiteSpace: 'normal',
            }}
          >
            <ListItemIcon>
              <PowerSettingsNewIcon style={{ alignItems: 'center', marginLeft: '15px' }} />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
        </div> */}
        </List>
        <Dialog open={logoutDialogOpen} onClose={cancelLogout}>
          <DialogTitle>Are you sure you want to Logout?</DialogTitle>
          <DialogActions>
            <Button onClick={cancelLogout} color="primary">
              Cancel
            </Button>
            <Button onClick={confirmLogout} color="primary">
              Logout
            </Button>
          </DialogActions>
        </Dialog>
      </Drawer>
    </>
  );
};

export default AdminSidebar;
