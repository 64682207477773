import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect, useRef } from "react";

import styles from "./fav.module.css";
import Typography from "@mui/material/Typography";
import {
  undoFavorite,
  fetchFavorites,
  documentPreview,
} from "../ApiHelper/Profile.js";
import Sidebar from "../Includes/Sidebar";

import { Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FolderIcon from "@mui/icons-material/Folder";
import Checkbox from "@mui/material/Checkbox";
import StarBorder from "@mui/icons-material/StarBorder";
import Star from "@mui/icons-material/Star";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  IconButton,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from "@mui/material";
import { TextField } from "@mui/material";
import {
  BeatLoader,
  RingLoader,
  MoonLoader,
  BarLoader,
  FadeLoader,
  ScaleLoader,
} from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import { useNavigate } from "react-router-dom"; // Import useNavigate
const FavouriteList = () => {
  const [starredRows, setStarredRows] = useState([]);
  const [file, setfile] = useState([]);
  const [directorry, setdirectory] = useState();
  const [navigation, setnavigation] = useState();
  const [clickedRowIndex, setClickedRowIndex] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate(); // Use useNavigate
  // const handleRowClick = () => {
  //   // Navigate to the desired location when a row is clicked
  //  console.log
  //  ('tapan')
  // };
  const [viewDtailsPage, setViewDetailsPage] = useState(false);
  const [doclink, setdoclink] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [menuVisibilities, setMenuVisibilities] = useState({});
  const [index, setIndex] = useState(0);
  const [totalrecord, settotalrecord] = useState("");
  const buttonStyles = {
    borderRadius: "8px",
    color: "var(--Orange, #F2994A)",

    background: "rgba(242, 153, 74, 0.16)",
    "&:hover": {
      background: "rgba(242, 153, 74, 0.24)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };
  const buttonactiveStyles = {
    borderRadius: "8px",
    color: "#27AE60",

    background: "rgba(39, 174, 96, 0.16)",
    "&:hover": {
      background: "rgba(39, 174, 96, 0.16)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };

  const buttonredStyles = {
    borderRadius: "8px",
    color: "#EB5757",

    background: "rgba(235, 87, 87, 0.12)",
    "&:hover": {
      background: "rgba(235, 87, 87, 0.12)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };

  const buttonnewStyles = {
    borderRadius: "8px",
    color: "#2F80ED",

    background: "rgba(47, 128, 237, 0.12)",
    "&:hover": {
      background: "rgba(47, 128, 237, 0.12)",
    },
    fontSize: "14px",
    textTransform: "none",
    fontWeight: "700",
    lineheight: "20px",
  };

  const handleClosemodal = () => {
    // Add your logic for creating a new user here
    console.log("Creating a new user...");
    setOpenModal(false);
  };
  const handleStarClick = async (index, event, directoryId) => {
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await undoFavorite(
        userToken,
        user_id,
        userorg_id,
        directoryId
      );

      if (res.code === "200") {
        console.log(res.message);

        event.stopPropagation();

        // Call fetchFavourite to update the UI with the latest data
        await fetchFavourite();
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    fetchFavourite();
  }, []);
  const fetchFavourite = async () => {
    setLoading(true);
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;
    // "start_index":10;
    // "limit":'10';
    try {
      const res = await fetchFavorites(userToken, user_id, userorg_id, 0, 10);

      if (res.code === "200") {
        settotalrecord(res.total_docs);
        setfile(res.data);
        console.log(res);
        setLoading(false);
        setStarredRows(Array(res.data.length).fill(true));
        if (res.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleRowClick = async (docid, account_id, rowIndex) => {
    // Navigate to the desired location when a row is clicked
    setLoading(true);
    setClickedRowIndex(rowIndex); // Set the clicked row index
    const userData = await localStorage.getItem("userdata");
    const userDataObj = JSON.parse(userData);
    const userToken = userDataObj.token;
    const user_id = userDataObj.user_id;
    const userorg_id = userDataObj.org_id;

    try {
      const res = await documentPreview(
        userToken,
        user_id,
        userorg_id,
        account_id,
        docid
      );

      if (res.code === "200") {
        // setLoading(false);
        setLoading(false);
        window.open(res.doc_link, "_blank");
        // setViewDetailsPage(true)
        // window.scrollTo(0, 0)
      } else {
        // Handle the error
        console.error("Error:", res.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handleLoadMore = async () => {
    setLoading(false);
    setLoadingMore(true);
    try {
      const userData = await localStorage.getItem("userdata");
      const userDataObj = JSON.parse(userData);
      if (!userDataObj || !userDataObj.token) {
        console.error("User token not found in AsyncStorage.");
        return;
      }
      const userToken = userDataObj.token;
      const deepuser_id = userDataObj.user_id;
      const deeporg_id = userDataObj.org_id;
      setIndex(file.length + 10);
      const data = await fetchFavorites(
        userToken,
        deepuser_id,
        deeporg_id,
        file.length
      );

      if (data.code === "200") {
        setfile((prevResults) => [...prevResults, ...data.data]);
        setStarredRows((prevStarredRows) => [
          ...prevStarredRows,
          ...Array(data.data.length).fill(true),
        ]);
        settotalrecord(data.total_docs);
        //  console.log(data.data.length > 0)
        setLoadingMore(false);
        // setHasMore(data.data.length > 0);
        if (data.data.length === 0) {
          setHasMore(false);
        } else {
          setHasMore(true);
        }
        const initialMenuVisibilities = {};
        data.data.forEach((item, index) => {
          initialMenuVisibilities[index] = false;
        });
        setMenuVisibilities(initialMenuVisibilities);
      } else {
        console.error("Error:", data.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const handlebacknavigation = () => {
    setViewDetailsPage(false);
  };
  const tableContainerRef = useRef(null);
  useEffect(() => {
    const tableContainer = tableContainerRef.current;

    if (tableContainer) {
      const handleScroll = () => {
        // Calculate the scroll position relative to the bottom of the container
        const scrollPosition =
          tableContainer.scrollHeight -
          tableContainer.scrollTop -
          tableContainer.clientHeight;

        // If the user has scrolled to the bottom and there's more data to load, trigger loading more
        if (
          !loadingMore &&
          scrollPosition < 100 &&
          hasMoreData &&
          file.length < totalrecord
        ) {
          handleLoadMore();
        }
      };

      // Add a scroll listener to the table container
      tableContainer.addEventListener("scroll", handleScroll);

      return () => {
        // Remove the scroll listener when the component unmounts
        tableContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, [
    loadingMore,
    hasMoreData,
    file.length,
    totalrecord,
    handleLoadMore,
    tableContainerRef,
  ]);

  return (
    <Box
      component="main"
      sx={{
        display: "flex",
        backgroundColor: "#EFEDF1",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <>
        <Box
          sx={{
            textAlign: "left",
            marginLeft: "150px",
            marginRight: "50px",
            marginTop: "100px",
            display: "flex",
            justifyContent: "space-between",
          }}
        ></Box>
        <Box
          sx={{
            flexGrow: 1,
            overflowY: "auto",
            marginLeft: "132px",
            marginRight: "2px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              marginLeft: "12px",
              marginY: "12px",
              color: "#74777F",
            }}
          >
            {totalrecord} {""} results found
          </Typography>
          <TableContainer
            ref={tableContainerRef}
            component={Paper}
            style={{
              boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)",
              // Adjust the height as needed
              maxHeight: "calc(100vh - 145px)",
              // overflowY: 'auto',
              //marginBottom: "10px",
              overflowY: "auto", // Enable vertical scrolling
            }}
          >
            <Table
              size="small"
              sx={{ minWidth: 650 }}
              aria-label="simple table"
            >
              <TableHead
                sx={{
                  zIndex: "999",
                  backgroundColor: "#EFF1F3",
                  position: "sticky",
                  top: "0",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#4A5568",
                    }}
                  >
                    {" "}
                    Name
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#4A5568",
                    }}
                  >
                    {" "}
                    Size
                  </TableCell>
                  <TableCell
                    align="left"
                    sx={{
                      fontSize: "16px",
                      fontWeight: "700",
                      color: "#4A5568",
                    }}
                  >
                    Last modified
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {loading && (
                  <TableRow>
                    <TableCell colSpan={4} align="center">
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                )}
                {!loading && file.length == 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">
                      No data found
                    </TableCell>
                  </TableRow>
                ) : (
                  <>
                    {file.map((item, index) => (
                      <TableRow
                        sx={{
                          cursor: "pointer",
                          "&:hover": { backgroundColor: "#E5E7EB" },
                        }}
                      >
                        <TableCell
                          align="left"
                          onDoubleClick={() =>
                            handleRowClick(item.doc_id, item.account_id)
                          }
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {" "}
                            <Checkbox
                              icon={<StarBorder />}
                              checkedIcon={<Star />}
                              checked={starredRows[index]}
                              onClick={(event) =>
                                handleStarClick(index, event, item.doc_id)
                              }
                              sx={{
                                color: starredRows[index] ? "inherit" : "grey",
                              }}
                            />
                            <PictureAsPdfIcon
                              sx={{ color: "red", marginRight: "20px" }}
                            />{" "}
                            {item.name}
                          </Box>
                        </TableCell>
                        <TableCell align="left">{item.size}</TableCell>
                        <TableCell align="left">{item.last_modified}</TableCell>
                      </TableRow>
                    ))}
                    {/* Add more rows as needed */}
                  </>
                )}
              </TableBody>
            </Table>
            {hasMoreData && totalrecord > file.length && (
              <InfiniteScroll
                dataLength={file.length}
                next={handleLoadMore}
                hasMore={hasMoreData}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "50px",
                    }}
                  >
                    <BeatLoader disableShrink color="#2F80ED" />
                  </div>
                }
                endMessage={
                  <div style={{ textAlign: "center", marginTop: "10px" }}>
                    {/* {!loadingMore && !hasMoreData && (
               <div>No More Records</div>
             )} */}

                    {file.length == 0 ? (
                      <div
                        style={{
                          color: "grey",
                          fontSize: "13px",
                          marginTop: "20px",
                        }}
                      >
                        No record found
                      </div>
                    ) : (
                      <>
                        <div>No More Records</div>
                      </>
                    )}

                    <IconButton
                      onClick={() => window.scrollTo(0, 0)}
                      style={{ color: "#2F80ED" }}
                      size="medium"
                    >
                      <KeyboardArrowUpIcon />
                    </IconButton>
                  </div>
                }
              />
            )}
          </TableContainer>
          <div style={{ marginBottom: "0px" }}></div>
        </Box>
      </>

      <Sidebar />
    </Box>
  );
};

export default FavouriteList;
