import React from "react";
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

const data = [
  { name: "Service", value: 400 },
  { name: "Material", value: 300 },
  { name: "Coal", value: 300 },
  { name: "Cash & Bank", value: 300 },
  { name: "Misc", value: 300 },
];

const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#00fefe", "#12FFAA"];

const PieChartComponent = () => {
  const formatLabelContent = (entry) => {
    const percentage = (
      (entry.value / data.reduce((acc, entry) => acc + entry.value, 0)) *
      100
    ).toFixed(2);
    return `${entry.name} (${percentage}%)`;
  };

  return (
    <PieChart width={600} height={400}>
      <Pie
        dataKey="value"
        isAnimationActive={false}
        data={data}
        cx="50%"
        cy="50%"
        outerRadius={80}
        fill="#8884d8"
        label={({
          cx,
          cy,
          midAngle,
          innerRadius,
          outerRadius,
          index,
          payload,
        }) => {
          const RADIAN = Math.PI / 180;
          const radius = 25 + innerRadius + (outerRadius - innerRadius);
          const x = cx + radius * Math.cos(-midAngle * RADIAN);
          const y = cy + radius * Math.sin(-midAngle * RADIAN);

          return (
            <text
              x={x}
              y={y}
              fill="#000"
              fontSize={14}
              textAnchor={x > cx ? "start" : "end"}
              dominantBaseline="central"
            >
              {formatLabelContent(payload)}
            </text>
          );
        }}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>
  );
};

export default PieChartComponent;
